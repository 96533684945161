import React, { useEffect, useMemo } from "react";
import { Absolute, Fixed, Flex, Relative, Static } from "../../atoms/Layout";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";

import styled from "styled-components";
import Button from "../../atoms/Button";
import Separator from "../../atoms/Separator";
import Tag from "../../atoms/Tag";
import Icon from "../../atoms/Icon";
import {
  BoardContentQuiz,
  BoardContentQuizResult,
  BoardContentSummaryGroup,
  DailyQuiz,
} from "../../../models/Model.Board";
import Skeleton from "react-loading-skeleton";
import ReactHtmlParser from "react-html-parser";
import { LogLevel, log } from "../../../utils/LogUtil";
import { getSummaryHtml } from "../BoardSummary";
import { PremiumType } from "../../../models/Model.User.Premium";
import DailyQuizHistory from "./History";
import { forEach } from "lodash";
import { SHADOW } from "../../design/effects";
import ToggleButton from "../../atoms/ToggleButton/ToggleButton";
import Body from "../../cell/Body";

export interface DailyQuizReportProps {
  premium: PremiumType;
  dailyQuizList: DailyQuiz[];
  dailyQuiz: DailyQuiz;
  onLoadHistory: (options: { startDate: string; endDate: string }) => void;
  onClickDailyQuiz: (dailyQuiz: DailyQuiz) => void;
  onQuizReference: (quiz: BoardContentQuiz) => void;
  onQuizLike: (quiz: BoardContentQuiz) => void;
  onQuizDislike: (quiz: BoardContentQuiz) => void;
  onClickPremium: () => void;
  onKakaoShare?: () => void;
  onFinish: () => void;
  historyStartDays: number;
  setHistoryStartDays: (days: number) => void;
}

const infos = [
  {
    score: 0,
    image: "/images/quiz/quiz-result-0.png",
    title: "괜찮아요!",
    description: "배우고 성장하는 과정이 중요해요!",
  },
  {
    score: 1,
    image: "/images/quiz/quiz-result-1.png",
    title: "잘하고 있어요!",
    description: "한 걸음 더 나아갔네요!",
  },
  {
    score: 2,
    image: "/images/quiz/quiz-result-2.png",
    title: "훌륭해요!",
    description: "계속해서 발전하고 있어요!",
  },
  {
    score: 3,
    image: "/images/quiz/quiz-result-3.png",
    title: "대단해요!",
    description: "모든 문제를 정확히 해결했어요!",
  },
];

const DailyQuizReport: React.FC<DailyQuizReportProps> = ({
  premium,
  dailyQuizList,
  dailyQuiz,
  onLoadHistory,
  onClickDailyQuiz,
  onQuizReference,
  onQuizLike,
  onQuizDislike,
  onClickPremium,
  onKakaoShare,
  onFinish,

  historyStartDays,
  setHistoryStartDays,
}) => {
  const score = useMemo<number>(() => {
    let score = 0;
    if (dailyQuiz && dailyQuiz.results && dailyQuiz.results.length > 0) {
      for (let i = 0; i < dailyQuiz.results.length; i++) {
        if (dailyQuiz.results[i].score > 0) score++;
      }

      if (dailyQuiz.quizzes && score < dailyQuiz.quizzes.length && score >= 3)
        score = 2;
      else if (score > 3) score = 3;
    }

    return score;
  }, [dailyQuiz]);
  log(LogLevel.UI_LIFECYCLE, "DailyQuizReport:render", dailyQuiz, score);

  return (
    <Body headerHeight={0}>
      <Flex
        direction="column"
        customStyle={{
          width: "100%",
          paddingTop: "24px",
          background: COLOR_SYSTEM.get("Gray")[10],
        }}
      >
        <Flex
          direction="column"
          alignItems="center"
          justifyContents="center"
          customStyle={{ padding: "40px 20px" }}
        >
          <img
            src={infos[score].image}
            style={{ width: "144px", height: "144px", marginBottom: "18px" }}
          />
          <Text textType="LargeTitle2" color={COLOR_SYSTEM.get("Gray")[800]}>
            {infos[score].title}
          </Text>
          <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
            {infos[score].description}
          </Text>
        </Flex>
        <Flex
          direction="column"
          gap="12px"
          customStyle={{ padding: "0px 20px" }}
        >
          {dailyQuiz.quizzes &&
            dailyQuiz.quizzes.map((quiz: BoardContentQuiz, index) => {
              return (
                <DailyQuizReportQuizResultCard
                  key={index.toString()}
                  dailyQuiz={dailyQuiz}
                  quiz={quiz}
                  result={dailyQuiz.results[index]}
                  index={index}
                  onReference={onQuizReference}
                  onLike={onQuizLike}
                  onDislike={onQuizDislike}
                />
              );
            })}
        </Flex>
        <DailyQuizHistory
          premium={premium}
          dailyQuizList={dailyQuizList}
          onLoad={onLoadHistory}
          onClick={onClickDailyQuiz}
          onPremium={onClickPremium}
          days={historyStartDays}
          setDays={setHistoryStartDays}
        />
        <Flex
          direction="column"
          gap="8px"
          customStyle={{
            width: "100%",
            padding: "16px 20px",
            background: COLOR_SYSTEM.get("Gray")[0],
          }}
        >
          <Button
            size={"Large"}
            type={"Text"}
            variant={"Contained"}
            color={"Primary"}
            style={{ width: "100%" }}
            onClick={onFinish}
          >
            완료하기
          </Button>
          {onKakaoShare && (
            <Button
              size={"Large"}
              type={"IconWithText"}
              variant={"Contained"}
              color={"Primary"}
              style={{
                width: "100%",
                background: COLOR_SYSTEM.get("Yellow")[400],
                color: COLOR_SYSTEM.get("Gray")[800],
              }}
              onClick={onKakaoShare}
              icon="Kakao"
              iconColor={COLOR_SYSTEM.get("Gray")[800]}
              left={true}
            >
              카카오로 퀴즈 공유하기
            </Button>
          )}
        </Flex>
      </Flex>
    </Body>
  );
};

export interface DailyQuizReportResultCardProps {
  dailyQuiz: DailyQuiz;
  quiz: BoardContentQuiz;
  result: BoardContentQuizResult;
  index: number;
  onReference: (quiz: BoardContentQuiz) => void;
  onLike: (quiz: BoardContentQuiz) => void;
  onDislike: (quiz: BoardContentQuiz) => void;
}

const DailyQuizReportQuizResultCardWapper = styled.div<{}>`
  display: flex;
  flex-direction: column;
  background: ${COLOR_SYSTEM.get("Gray")[0]};
  padding: 20px;
  border-radius: 20px;
  ${SHADOW.get("1")}
`;

const DailyQuizReportQuizResultCardHeaders = [
  <Text textType="Body2SemiBold" color={COLOR_SYSTEM.get("Red")[300]}>
    아쉽지만 오답이에요
  </Text>,
  <Text textType="Body2SemiBold" color={COLOR_SYSTEM.get("Skyblue")[400]}>
    완벽한 정답! 잘했어요!
  </Text>,
];

const DailyQuizReportQuizResultCard: React.FC<DailyQuizReportResultCardProps> = ({
  dailyQuiz,
  quiz,
  result,
  index,
  onReference,
  onLike,
  onDislike,
}) => {
  log(
    LogLevel.UI_LIFECYCLE,
    "DailyQuizReportQuizResultCard:render",
    quiz,
    result,
    index
  );
  return (
    <DailyQuizReportQuizResultCardWapper>
      <Static customStyle={{ marginBottom: "4px" }}>
        {DailyQuizReportQuizResultCardHeaders[result.score ? 1 : 0]}
      </Static>
      <Static customStyle={{ marginBottom: "12px" }}>
        <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Gray")[900]}>
          {" "}
          {quiz.question}
        </Text>
      </Static>
      <Static customStyle={{ marginBottom: "12px" }}>
        <Text textType="Body2Medium" color={COLOR_SYSTEM.get("Gray")[900]}>
          {" "}
          {quiz.comment}
        </Text>
      </Static>
      <Flex alignItems="center" gap="8px">
        <Button
          size={"Small"}
          type={"Text"}
          variant={"Tinted"}
          color={"Tertiary"}
          onClick={() => onReference(quiz)}
        >
          자세히 알아보기
        </Button>
        <div style={{ flexGrow: 1 }} />
        <ToggleButton
          type={!quiz.liked && !quiz.likeCnt ? "Icon" : "IconWithText"}
          color={"Skyblue"}
          size={"Small"}
          variant={"Tinted"}
          onClick={() => onLike(quiz)}
          active={quiz.liked}
          icon="Thumbs Up Filled"
          left={true}
        >
          {quiz.likeCnt ? quiz.likeCnt : ""}
        </ToggleButton>
        <ToggleButton
          color={"Red"}
          type={!quiz.disliked && !quiz.dislikeCnt ? "Icon" : "IconWithText"}
          size={"Small"}
          variant={"Tinted"}
          onClick={() => onDislike(quiz)}
          active={quiz.disliked}
          icon="Thumbs Down Filled"
          left={true}
        >
          {quiz.dislikeCnt ? quiz.dislikeCnt : ""}
        </ToggleButton>
      </Flex>
    </DailyQuizReportQuizResultCardWapper>
  );
};

export default DailyQuizReport;
