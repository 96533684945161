import AnalyticsUtil from "../utils/AnalyticsUtil";
import { fetchAPI } from "../utils/APIUtil";
import { getGlobal, GlobalKey } from "../utils/GlobalUtil";
import * as API from "./../API.json";

export type SearchType = "Post" | "Medicine";
export interface Board {
  id: number;
  subject: string;
  bodyText: string;
  createdAt: string;
  boardsCategoriesName: string;
  score: number;
  likeCntAll: number;
  viewCnt: number;
  replyCnt: number;
  boardsCategoriesAttribute: string;
  isNice: boolean;
  subjectHightlight: string;
  bodyTextHightlight: string;
}

export interface Partner {
  id: string;
  category: number;
  bodies: string;
  imgUrl: string;
  scoreCount: number;
  title: string;
  titleHighlight: string;
  viewCount: number;
}

export interface Seminar {
  id: number;
  title: string;
  subtitle: string;
  description: string;
  effect: string;
  createdAt: string;
  score: number;
  scoreAverage: number;
  viewCount: number;
  scoreCount: number;
  advertiserCode: string;
  titleHighlight: string;
  bodyHighlight: string;
}

export interface Medicine {
  type: number;
  id: number;
  text: string;
  textReverse: string;
  description: string;
  value: string;
}

interface SearchState {
  isLoading: boolean;
  searchType: SearchType;
  keyword: string;
  currentSearchedKeyword: string;
  post: {
    scrollPosition: number;
    hotKeywords: any[] | null;
    suggestedKeywords: any[] | null;
    searchedKeyword: string;
    searchedList: (Board | Seminar)[] | null;
    searchedCount: number;
    sort: string;
  };
  medicine: {
    scrollPosition: number;
    searchedKeyword: string;
    searchedList: Medicine[] | null;
  };
}

interface SearchAction {
  type: string;
  payload?: any;
}

const SWITCH_SEARCH_TYPE = "search/SWITCH_SEARCH_TYPE";
const SET_LOADING = "search/SET_LOADING";
const RESET_POST = "search/RESET_POST";
const RESET_MEDICINE = "search/RESET_MEDICINE";
const RESET_CURRENT_SEARCHED_KEYWORD = "search/RESET_CURRENT_SEARCHED_KEYWORD";
const ONCHANGE_KEYWORD = "search/ONCHANGE_KEYWORD";
const SET_CURRENT_SEARCHED_KEYWORD = "search/SET_CURRENT_SEARCHED_KEYWORD";
const SET_POST_SEARCHED_LIST = "search/SET_POST_SEARCHED_LIST";
const SET_MEDICINE_SEARCHED_LIST = "search/SET_MEDICINE_SEARCHED_LIST";
const SET_SCROLL_POS = "search/SET_SCROLL_POS";
const SET_SEARCHED_KEYWORD = "search/SET_SEARCHED_KEYWORD";
const FETCH_POST = "search/FETCH_POST";
const FETCH_MEDICINE = "search/FETCH_MEDICINE";
const SET_HOT_KEYWORDS = "search/SET_HOT_KEYWORDS";
const SET_SUGGESTED_KEYWORDS = "search/SET_SUGGESTED_KEYWORDS";
const SORT_SEARCH = "search/SORT_SEARCH";

export const switchSearchType = (searchType: SearchType) => ({
  type: SWITCH_SEARCH_TYPE,
  payload: {
    searchType,
  },
});

export const setLoading = (loading: boolean) => ({
  type: SET_LOADING,
  payload: {
    loading,
  },
});

export const resetPost = () => ({
  type: RESET_POST,
});

export const resetMedicine = () => ({
  type: RESET_MEDICINE,
});

export const resetCurrentSearchedKeyword = () => ({
  type: RESET_CURRENT_SEARCHED_KEYWORD,
});

export const onChangeKeyword = (value: string) => ({
  type: ONCHANGE_KEYWORD,
  payload: {
    value,
  },
});

export const setCurrentSearchedKeyword = (keyword: string) => ({
  type: SET_CURRENT_SEARCHED_KEYWORD,
  payload: {
    keyword,
  },
});

export const fetchPost = (isInit = false) => ({
  type: FETCH_POST,
  payload: {
    isInit,
  },
});

export const fetchMedicine = (keyword: string) => ({
  type: FETCH_MEDICINE,
  payload: {
    keyword,
  },
});

export const setPostSearchedList = (
  searchResult: (Board | Seminar | Partner)[],
  searchedCount: number,
  isInit: boolean
) => ({
  type: SET_POST_SEARCHED_LIST,
  payload: {
    searchResult,
    searchedCount,
    isInit,
  },
});

export const setMedicineSearchedList = (searchResult: Medicine[]) => ({
  type: SET_MEDICINE_SEARCHED_LIST,
  payload: {
    searchResult,
  },
});

export const setScrollPos = (tab: SearchType, position: number) => ({
  type: SET_SCROLL_POS,
  payload: {
    tab,
    position,
  },
});

export const setSearchedKeyword = (tab: SearchType, keyword: string) => ({
  type: SET_SEARCHED_KEYWORD,
  payload: {
    tab,
    keyword,
  },
});

export const setHotKeywords = (hotKeywords: any[]) => ({
  type: SET_HOT_KEYWORDS,
  payload: {
    hotKeywords,
  },
});

export const setSuggestedKeywords = (suggestedKeywords: any[]) => ({
  type: SET_SUGGESTED_KEYWORDS,
  payload: {
    suggestedKeywords,
  },
});

export const sortSearch = (sort: string) => ({
  type: SORT_SEARCH,
  payload: {
    sort,
  },
});

// Middleware
export const searchMiddleware = (store) => (next) => async (action) => {
  next(action);
  // console.log("searchMiddleware action: ", action);
  try {
    const { type } = action;

    if (type === FETCH_POST) {
      const { isInit } = action.payload;
      const { currentSearchedKeyword, post } = store.getState().newSearch;

      if (isInit) {
        next(resetPost());
        next(setLoading(true));
        next(setSearchedKeyword("Post", currentSearchedKeyword.trim()));
      }

      const response = await fetchAPI(
        API.BOARD_SEARCH,
        "",
        null,
        {
          search: currentSearchedKeyword.trim(),
          count: 20,
          must: true,
          start: isInit ? 0 : post.searchedList.length,
          full: true,
          sort: post.sort,
        },
        getGlobal(GlobalKey.TOKEN)
      );

      next(setLoading(false));
      next(
        setPostSearchedList(
          response.data.contents || [],
          response.data.total || 0,
          isInit
        )
      );
    }

    if (type === FETCH_MEDICINE) {
      const { keyword } = action.payload;

      next(setLoading(true));
      next(setSearchedKeyword("Medicine", keyword));

      const response = await fetchAPI(
        API.MEDICINE_DICTIONARY,
        "",
        {
          keyword,
          type: -4,
        },
        null,
        getGlobal(GlobalKey.TOKEN)
      );

      next(setLoading(false));
      next(setMedicineSearchedList(response.data || []));
    }
  } catch (err) {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "Search_Error",
      "검색통합에러",
      {
        page: "Search",
        where: "searchMiddleware",
        errorMessage: err,
      }
    );
  }
};

const initialState: SearchState = {
  isLoading: false,
  searchType: "Post",
  keyword: "",
  currentSearchedKeyword: "",
  post: {
    scrollPosition: 0,
    hotKeywords: null,
    searchedKeyword: "",
    suggestedKeywords: null,
    searchedList: null,
    searchedCount: 0,
    sort: "score",
  },
  medicine: {
    scrollPosition: 0,
    searchedKeyword: "",
    searchedList: null,
  },
};

const reducer = (state = initialState, action: SearchAction) => {
  const { type } = action;

  switch (type) {
    case SWITCH_SEARCH_TYPE: {
      return {
        ...state,
        searchType: action.payload.searchType,
      };
    }
    case ONCHANGE_KEYWORD:
      return {
        ...state,
        keyword: action.payload.value,
      };
    case SET_CURRENT_SEARCHED_KEYWORD:
      return {
        ...state,
        currentSearchedKeyword: action.payload.keyword,
      };
    case SET_POST_SEARCHED_LIST:
      return {
        ...state,
        post: action.payload.isInit
          ? {
              ...state.post,
              searchedList: [...action.payload.searchResult],
              searchedCount: action.payload.searchedCount,
              scrollPosition: 0,
            }
          : {
              ...state.post,
              searchedList: [
                ...state.post.searchedList,
                ...action.payload.searchResult,
              ],
              searchedCount: action.payload.searchedCount,
            },
      };
    case SET_MEDICINE_SEARCHED_LIST:
      return {
        ...state,
        medicine: {
          ...state.medicine,
          searchedList: [...action.payload.searchResult],
        },
      };
    case SET_SCROLL_POS: {
      const { tab, position } = action.payload;

      return tab === "Post"
        ? {
            ...state,
            post: {
              ...state.post,
              scrollPosition: position,
            },
          }
        : {
            ...state,
            medicine: {
              ...state.medicine,
              scrollPosition: position,
            },
          };
    }
    case RESET_POST: {
      return {
        ...state,
        post: {
          ...initialState.post,
          hotKeywords: state.post.hotKeywords,
          suggestedKeywords: state.post.suggestedKeywords,
          sort: state.post.sort,
        },
      };
    }
    case RESET_MEDICINE:
      return {
        ...state,
        medicine: {
          ...initialState.medicine,
        },
      };
    case RESET_CURRENT_SEARCHED_KEYWORD:
      return {
        ...state,
        currentSearchedKeyword: "",
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.loading,
      };
    case SET_SEARCHED_KEYWORD: {
      const { tab, keyword } = action.payload;

      return tab === "Post"
        ? {
            ...state,
            post: {
              ...state.post,
              searchedKeyword: keyword,
            },
          }
        : {
            ...state,
            medicine: {
              ...state.medicine,
              searchedKeyword: keyword,
            },
          };
    }
    case SET_HOT_KEYWORDS:
      return {
        ...state,
        post: {
          ...state.post,
          hotKeywords: action.payload.hotKeywords,
        },
      };
    case SET_SUGGESTED_KEYWORDS:
      return {
        ...state,
        post: {
          ...state.post,
          suggestedKeywords: action.payload.suggestedKeywords,
        },
      };
    case SORT_SEARCH:
      const { sort } = action.payload;
      return {
        ...state,
        post: {
          ...state.post,
          searchedList: null,
          searchedCount: 0,
          sort: sort,
        },
      };
    default:
      return state;
  }
};

export default reducer;
