import React, { Component, createRef, useState } from "react";
import styled from "styled-components";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import { log, LogLevel } from "../../../utils/LogUtil";
import Badge from "../../atoms/Badge/Badge";
import Text from "../../atoms/Text";
import ToggleButton from "../../atoms/ToggleButton/ToggleButton";
import { COLOR_SYSTEM } from "../../design/design-system";
import { typography } from "../../design/typography";

type TabBarSize = "Large" | "Medium" | "Small";
type TabBarType = "Contained" | "Underlined";

export interface Item {
  text?: string;
  active?: boolean;
  badge?: boolean;
  onClick?: (params?: any) => any;
  onTouchStart?: (params?: any) => any;
  onTouchMove?: (params?: any) => any;
  onTouchEnd?: (params?: any) => any;
}

export interface TabBarWrapperProps {
  size?: TabBarSize;
  type?: TabBarType;
  active?: boolean;
  sameWidth?: boolean;
  accordion?: boolean;
}

const TabBarWrapper = styled.nav<TabBarWrapperProps>`
  width: 100%;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
`;

const TabItems = styled.div<TabBarWrapperProps>`
  flex-grow: 1;
  padding: ${({ size }) => (size === "Medium" ? "0 24px" : "0 24px ")};
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: ${({ type }) => {
    if (type === "Underlined")
      return `1px solid ${COLOR_SYSTEM.get("Gray")[50]}`;
  }};
  ${typography.get("Body1Bold")};
  overflow-x: scroll;
  overflow-y: hidden;
  transform: translateZ(0);

  ${({ accordion }) => (accordion ? "flex-wrap: wrap;" : "")}

  & > * {
    ${({ sameWidth }) => {
      if (sameWidth) return "flex: 1 1 33%;";
    }}
  }

  gap: 0px 32px;
`;

const UnderLinedItem = styled.div<TabBarWrapperProps>`
  height: ${({ size }) => {
    const map = {
      Large: "60px",
      Medium: "48px",
      Small: "40px",
    };

    return map[size];
  }};

  line-height: ${({ size }) => {
    const map = {
      Large: "60px",
      Medium: "48px",
      Small: "40px",
    };

    return map[size];
  }};
  color: ${({ active }) => COLOR_SYSTEM.get("Gray")[active ? 700 : 300]};
  position: relative;
  z-index: 1;
  text-align: center;
  flex-shrink: 0;
  cursor: pointer;

  .tabbar-item-underlined__text {
    height: 100%;
    line-height: ${({ size }) => {
      const map = {
        Large: "60px",
        Medium: "48px",
        Small: "40px",
      };

      return map[size];
    }};
    display: inline-block;
    border-bottom: ${({ active }) => {
      if (active) return `2px solid ${COLOR_SYSTEM.get("Gray")[700]}`;
    }};
  }
`;

const ContainedItem = styled.li<TabBarWrapperProps>`
  height: 100%;
  line-height: ${({ size }) => (size === "Medium" ? "48px" : "40px")};
  padding: ${({ size }) => (size === "Medium" ? "0 16px" : "0 14px")};
  background-color: ${({ active }) =>
    COLOR_SYSTEM.get("Gray")[active ? 700 : 20]};
  color: ${({ active }) => COLOR_SYSTEM.get("Gray")[active ? 0 : 300]};
  border-radius: 12px;
  position: relative;
  text-align: center;
  flex-shrink: 0;
  cursor: pointer;
`;

const Alert = styled.div<TabBarWrapperProps>`
  position: absolute;
  top: ${({ size }) => (size === "Medium" ? "6px" : "4px")};
  right: ${({ size }) => (size === "Medium" ? "6px" : "4px")};
  line-height: 0;
`;

export interface TabBarProps {
  size: TabBarSize;
  type: TabBarType;
  items: Item[];
  selectedIndex?: number;
  scrollable: boolean;
  sameWidth?: boolean;
  enableAccordion?: boolean;
}

type State = {
  accordion: boolean;
};

class TabBar extends Component<TabBarProps, State> {
  state = {
    accordion: false,
  };

  itemRefs = null;
  listRef = createRef<HTMLDivElement>();

  constructor(props: TabBarProps) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "TabBar:constructor");
    this.itemRefs = this.props.items.map((item, index) =>
      createRef<HTMLDivElement>()
    );
  }

  componentDidUpdate(
    prevProps: Readonly<TabBarProps>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (this.props.items.length != prevProps.items.length) {
      this.itemRefs = this.props.items.map((item, index) =>
        createRef<HTMLDivElement>()
      );
    }

    if (this.props.selectedIndex != prevProps.selectedIndex) {
      this.scrollToCurrentFocusItem();
    }
  }

  componentDidMount(): void {
    this.scrollToCurrentFocusItem();
  }

  closeAccordion = () => {
    if (!this.props.enableAccordion || !this.state.accordion) return;
    this.setState({ accordion: false });
    setTimeout(() => {
      this.scrollToCurrentFocusItem();
    }, 0);
  };

  toggleAccordion = () => {
    if (!this.props.enableAccordion) return;
    const accordion = !this.state.accordion;
    this.setState({ accordion });
    if (this.state.accordion)
      setTimeout(() => {
        this.scrollToCurrentFocusItem();
      }, 0);
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "TABBAR_ACCORDION",
      "탭바_아코디언_클릭",
      { 활성화: accordion }
    );
  };

  scrollToCurrentFocusItem = () => {
    log(
      LogLevel.UI_LIFECYCLE,
      "TabBar.scrollToCurrentFocusItem",
      this.itemRefs,
      this.props.selectedIndex
    );
    if (this.itemRefs.length <= this.props.selectedIndex) return;

    if (
      !this.itemRefs ||
      !this.itemRefs[this.props.selectedIndex] ||
      !this.listRef.current ||
      !this.itemRefs[this.props.selectedIndex].current
    )
      return;

    log(
      LogLevel.UI_LIFECYCLE,
      "TabBar.scrollToCurrentFocusItem check",
      this.listRef.current.clientLeft,
      this.listRef.current.scrollLeft,
      this.listRef.current.clientWidth,
      this.itemRefs[this.props.selectedIndex].current.offsetLeft,
      this.itemRefs[this.props.selectedIndex].current.clientWidth
    );
    if (
      this.listRef.current.scrollLeft + this.listRef.current.clientWidth <
        this.itemRefs[this.props.selectedIndex].current.offsetLeft +
          this.itemRefs[this.props.selectedIndex].current.clientWidth ||
      this.listRef.current.scrollLeft >
        this.itemRefs[this.props.selectedIndex].current.offsetLeft
    )
      this.listRef.current.scrollTo({
        left:
          this.itemRefs[this.props.selectedIndex].current.offsetLeft -
          this.listRef.current.clientWidth / 2,
      });
  };

  render() {
    return (
      <TabBarWrapper>
        <TabItems
          ref={this.listRef}
          size={this.props.size}
          type={this.props.type}
          sameWidth={this.props.sameWidth}
          accordion={this.state.accordion}
        >
          {this.props.type === "Underlined"
            ? this.props.items.map((item, index) => (
                <UnderLinedItem
                  key={index}
                  ref={this.itemRefs[index]}
                  size={this.props.size}
                  type={this.props.type}
                  active={index == this.props.selectedIndex || item.active}
                  onClick={item.onClick}
                >
                  <Text
                    textType="Body1Bold"
                    color={
                      COLOR_SYSTEM.get("Gray")[
                        index == this.props.selectedIndex || item.active
                          ? 700
                          : 300
                      ]
                    }
                    className="tabbar-item-underlined__text"
                  >
                    {item.text}
                  </Text>
                  {item.badge && (
                    <Alert size={this.props.size}>
                      <Badge color={"Primary"} />
                    </Alert>
                  )}
                </UnderLinedItem>
              ))
            : this.props.items.map((item, index) => (
                <ContainedItem
                  key={index}
                  ref={this.itemRefs[index]}
                  size={this.props.size}
                  type={this.props.type}
                  active={index == this.props.selectedIndex || item.active}
                  onClick={item.onClick}
                >
                  <Text
                    textType="Body1Bold"
                    color={COLOR_SYSTEM.get("Gray")[700]}
                  >
                    {item.text}
                  </Text>
                  {item.badge && (
                    <Alert size={this.props.size}>
                      <Badge color={"Primary"} />
                    </Alert>
                  )}
                </ContainedItem>
              ))}
        </TabItems>
        {this.props.enableAccordion && (
          <ToggleButton
            color={"Gray"}
            size={"Medium"}
            variant={"Ghost"}
            type="Icon"
            icon={this.state.accordion ? "CaretUp" : "CaretDown"}
            left
            onClick={this.toggleAccordion}
            style={{ float: "left" }}
          />
        )}
      </TabBarWrapper>
    );
  }
}

export default TabBar;
