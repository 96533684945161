import React, { FC, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useBottomSheet from "../../../hooks/useBottomSheet";
import { BoardAttribute } from "../../../models/Model.Board";
import { RootState } from "../../../store";
import { getBoard, sortBoard } from "../../../store/board/actions";
import {
  BoardSortType,
  BoardSortTypeName,
  BoardType,
} from "../../../store/board/types";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import BottomSheetList from "../../molecules/BottomSheetList";
import BottomSheetListItem from "../../molecules/BottomSheetListItem";

interface BoardListSortButtonProps {
  tab: number;
  boardId: BoardType;
}

const BoardListSortButton: FC<BoardListSortButtonProps> = ({
  tab,
  boardId,
}) => {
  const dispatch = useDispatch();
  const boards = useSelector((state: RootState) => state.board.boards);
  const boardList = useSelector((state: RootState) => state.board.boardList);
  const currentBoard = useMemo(() => boards[boardId], [boards, boardId]);
  const boardAttribute: string = useMemo(
    () => boards[boardList[tab]].attribute,
    [boards, boardList, tab]
  );
  const bottomSheet = useBottomSheet();

  const checkSelectedSort = useCallback(
    (sort: BoardSortType) => {
      if (
        (!currentBoard.sort || currentBoard.sort === BoardSortType.DEFAULT) &&
        sort === BoardSortType.TIME_DESC
      )
        return true;

      return currentBoard.sort === sort;
    },
    [currentBoard]
  );

  const handleClickSortItem = (sort: BoardSortType) => () => {
    dispatch(sortBoard(boardId, sort));
    dispatch(getBoard(boardId, 15));
    bottomSheet.close();

    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "BOARD_SORT", "게시판정렬", {
      Sort: BoardSortType[sort],
      게시판이름: BoardType[boardId],
    });
  };

  const handleClickSort = () => {
    bottomSheet.show({
      title: {
        value: "정렬 기준",
        align: "left",
      },
      body: (
        <BottomSheetList>
          {boardId == BoardType.RECOMMEND && (
            <BottomSheetListItem
              left={
                <Text
                  textType="InputLarge"
                  color={
                    COLOR_SYSTEM.get("Gray")[
                      checkSelectedSort(BoardSortType.NICE) ? 700 : 400
                    ]
                  }
                >
                  추천일순
                </Text>
              }
              right={
                checkSelectedSort(BoardSortType.NICE) ? (
                  <Icon
                    name="Check"
                    width={24}
                    height={24}
                    color={COLOR_SYSTEM.get("Gray")[900]}
                  />
                ) : null
              }
              onClick={handleClickSortItem(BoardSortType.NICE)}
            />
          )}
          <BottomSheetListItem
            left={
              <Text
                textType="InputLarge"
                color={
                  COLOR_SYSTEM.get("Gray")[
                    checkSelectedSort(BoardSortType.TIME_DESC) ? 700 : 400
                  ]
                }
              >
                최신순
              </Text>
            }
            right={
              checkSelectedSort(BoardSortType.TIME_DESC) ? (
                <Icon
                  name="Check"
                  width={24}
                  height={24}
                  color={COLOR_SYSTEM.get("Gray")[900]}
                />
              ) : null
            }
            onClick={handleClickSortItem(BoardSortType.TIME_DESC)}
          />
          <BottomSheetListItem
            left={
              <Text
                textType="InputLarge"
                color={
                  COLOR_SYSTEM.get("Gray")[
                    checkSelectedSort(BoardSortType.TIME_ASC) ? 700 : 400
                  ]
                }
              >
                예전글 순
              </Text>
            }
            right={
              checkSelectedSort(BoardSortType.TIME_ASC) ? (
                <Icon
                  name="Check"
                  width={24}
                  height={24}
                  color={COLOR_SYSTEM.get("Gray")[900]}
                />
              ) : null
            }
            onClick={handleClickSortItem(BoardSortType.TIME_ASC)}
          />
          <BottomSheetListItem
            left={
              <Text
                textType="InputLarge"
                color={
                  COLOR_SYSTEM.get("Gray")[
                    checkSelectedSort(BoardSortType.LIKE) ? 700 : 400
                  ]
                }
              >
                좋아요 순
              </Text>
            }
            right={
              checkSelectedSort(BoardSortType.LIKE) ? (
                <Icon
                  name="Check"
                  width={24}
                  height={24}
                  color={COLOR_SYSTEM.get("Gray")[900]}
                />
              ) : null
            }
            onClick={handleClickSortItem(BoardSortType.LIKE)}
          />
          <BottomSheetListItem
            left={
              <Text
                textType="InputLarge"
                color={
                  COLOR_SYSTEM.get("Gray")[
                    checkSelectedSort(BoardSortType.VIEW) ? 700 : 400
                  ]
                }
              >
                조회수 순
              </Text>
            }
            right={
              checkSelectedSort(BoardSortType.VIEW) ? (
                <Icon
                  name="Check"
                  width={24}
                  height={24}
                  color={COLOR_SYSTEM.get("Gray")[900]}
                />
              ) : null
            }
            onClick={handleClickSortItem(BoardSortType.VIEW)}
          />
        </BottomSheetList>
      ),
    });
  };

  if (!boardAttribute.includes(BoardAttribute.UI_LIST_SORT)) return null;

  return (
    <Button
      color="Secondary"
      size="Small"
      variant="Ghost"
      type="IconWithText"
      icon="Swap Vertical"
      left
      onClick={handleClickSort}
    >
      {currentBoard.sort ? BoardSortTypeName[currentBoard["sort"]] : "최신순"}
    </Button>
  );
};

export default BoardListSortButton;
