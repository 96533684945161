import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Button from "../../atoms/Button";
import ToggleButton from "../../atoms/ToggleButton/ToggleButton";
import { DetailFooter } from "./style";
import {
  ApplyMethod,
  applyMethodCoupler,
  JobOfferInfo,
  JobPostRegistrationForm,
} from "../../../models/Model.JobPost";
import { UserInfo, UserLevel, UserWorkType } from "../../../models/Model.User";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { log, LogLevel } from "../../../utils/LogUtil";
import Header from "./Header";
import Main from "./Main";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import ApplyOptionsBottomSheet from "../../organisms/JobPostBottomSheet/ApplyOptionsBottomSheet";
import OptionBottomSheet from "../../organisms/JobPostBottomSheet/OptionBottomSheet";
import RegisterSuccessPopup from "../../organisms/JobPostPopup/RegisterSuccessPopup";
import RaisePopup from "../../organisms/JobPostPopup/RaisePopup";
import RaiseErrorPopup from "../../organisms/JobPostPopup/RaiseErrorPopup";
import FinishJobPostPopup from "../../organisms/JobPostPopup/FinishJobPostPopup";
import AfterTaxPopup from "../../organisms/JobPostPopup/AfterTaxPopup";
import {
  ButtonStyle,
  closePopup,
  openPopup,
  updatePopup,
} from "../../../store/popup";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import { addHyphenToPhoneNumber } from "../../../utils/JobPostUtil";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { fetchAPI } from "../../../utils/APIUtil";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
import * as API from "../../../API.json";
import { RootState, actions } from "../../../store";
import {
  hideBottomSheet,
  offBottomSheet,
  onBottomSheet,
} from "../../../store/modal/bottomsheet";
import useClipboard from "../../../hooks/useClipboard";
import { JOBOFFER_ALIM_FREE_MAX } from "./../../../config.json";
import { JobListType } from "../../../store/jobpost/types";
import AdsUtil from "../../../utils/AdsUtil";

export type OptionType = "raise" | "alim" | "edit" | "finish";

const PhoneMessagePopupMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .main__description {
    margin: 12px 0 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * + * {
      margin-top: 6px;
    }

    ol > li {
      list-style: decimal inside;
      padding-left: 20px;
      text-indent: -20px;

      &:first-child {
        margin-bottom: 4px;
      }

      &::marker {
        color: ${COLOR_SYSTEM.get("Gray")[600]};
      }
    }
  }

  .main__example {
    width: 100%;
    padding: 12px 16px;
    background-color: ${COLOR_SYSTEM.get("Gray")[10]};
    border-radius: 8px;
  }
`;

const PopupBody = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * + * {
    margin-top: 20px;
  }
`;

export interface JobPostDetailProps {
  preview?: boolean;
  form?: JobPostRegistrationForm;
  post?: JobOfferInfo;
  me?: UserInfo;
  backKeyControl?: any;
  onClose?: () => void;
  onScrap?: () => Promise<void>;
  onUnScrap?: () => Promise<void>;
  onRaisePost?: () => Promise<void>;
  onSendAlimTalk?: (postId: number) => void;
  onPremiumAd?: (postId: number) => void;
  onGoToEdit?: (postId: number) => void;
  onRegisterPost?: (form: JobPostRegistrationForm) => Promise<any>;
  onMapPath?: () => void;
  onMap?: () => void;
  onChat?: () => void;
  onKakaoShare?: () => Promise<void>;
  isNew?: boolean;
}

const JobPostDetail: React.FC<RouteComponentProps & JobPostDetailProps> = ({
  history,
  preview = false,
  form,
  post,
  me,
  isNew = false,
  backKeyControl,
  onClose,
  onScrap,
  onUnScrap,
  onRaisePost,
  onSendAlimTalk,
  onPremiumAd,
  onGoToEdit,
  onRegisterPost,
  onMapPath,
  onMap,
  onChat,
  onKakaoShare,
}) => {
  try {
    const isOwner = useMemo(() => me.workType === UserWorkType.PHARMACY_OWNER, [
      me,
    ]);
    const isManager = useMemo(() => me.level >= UserLevel.MANAGER, [me]);
    const isMyPost = useMemo(() => {
      return preview ? true : me.id === post.ownerId;
    }, [preview, me, post]);

    const [optionBottomSheet, setOptionBottomSheet] = useState(false);
    const [applyBottomSheet, setApplyBottomSheet] = useState(false);
    const [successPopup, setSuccessPopup] = useState(false);
    const [raisePopup, setRaisePopup] = useState(false);
    const [raiseErrorPopup, setRaiseErrorPopup] = useState(false);
    const [finishPopup, setFinishPopup] = useState(false);
    const [afterTaxPopup, setAfterTaxPopup] = useState(false);
    const [passed, setPassed] = useState<number[]>([]);
    const [registerPost, setRegisterPost] = useState<JobOfferInfo>(null);
    const [disabled, setDisabled] = useState({
      register: false,
      raise: false,
    });

    const { copyToClipboard } = useClipboard();

    const { active: bottomSheetActive } = useSelector(
      (state: RootState) => state.bottomSheet
    );

    // Trigger event handler when click "apply by message" button
    const dispatch = useDispatch();

    const sendMessage = async () => {
      if (!post || !me) {
        log(LogLevel.UI_ACTION, "JobPostDetail:sendChat: not ready");
        return;
      }
      log(LogLevel.UI_ACTION, "JobPostDetail:sendChat: ");
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_APPLY_METHOD",
        "구인구직_공고_지원",
        {
          jobPostApplyMethod: applyMethodCoupler[post.applyMethod],
          selectedApplyMethod: applyMethodCoupler[ApplyMethod.Message],
        }
      );
      try {
        await fetchAPI(
          API.JOBPOST_LIKE,
          post.id.toString(),
          null,
          null,
          getGlobal(GlobalKey.TOKEN)
        );
        console.log("notice", navigator);
        const isApple =
          navigator.userAgent.toLocaleLowerCase().includes("iphone") ||
          navigator.userAgent.toLocaleLowerCase().includes("mac");

        window.location.href = `sms:${
          post.phonePersonInCharge || post.owner.phone
        }${isApple ? "&" : "?"}body=안녕하세요, 저는 ${
          me.name
        } 약사입니다.%0D%0A약문약답 구인공고 보고 연락드립니다.%0D%0A`;

        fetchAPI(
          API.JOBPOST_SMS,
          post.id.toString(),
          null,
          null,
          getGlobal(GlobalKey.TOKEN)
        ).catch((err) => console.error(err));
        dispatch(actions.jobpost.updateOffer({ id: post.id, liked: true }));
        dispatch(
          actions.jobpost.reloadList(
            null,
            JobListType.OFFER | JobListType.MY_LIKE
          )
        );
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, "JobPostDetail:sendChat:exception ", e);
      }
    };

    const handleClickSendMessage = () => {
      sendMessage();
      const popupBody = (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "24px",
            }}
          >
            <div>
              <Text
                textType="Body1SemiBold"
                color={COLOR_SYSTEM.get("Gray")[800]}
              >
                {post.pharmacy.name}
              </Text>
              <br />
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
                {addHyphenToPhoneNumber(
                  post.phonePersonInCharge || post.owner.phone
                )}
              </Text>
            </div>
            <div>
              <Button
                color="Secondary"
                size="Small"
                variant="Outlined"
                type="Text"
                onClick={copyToClipboard(
                  post.phonePersonInCharge || post.owner.phone
                )}
              >
                전화번호 복사하기
              </Button>
            </div>
          </div>
          <div>
            <Text
              textType="Body2SemiBold"
              color={COLOR_SYSTEM.get("Skyblue")[400]}
            >
              ※ 문자 프로그램이 실행되지 않는다면 상단의 전화번호 복사를 눌러
              문자 작성 시 붙여넣어 주세요.
            </Text>
          </div>
        </div>
      );
      const popupButtons: ButtonStyle[][] = [
        [
          {
            value: "닫기",
            color: "Secondary",
            size: "Large",
            variant: "Tinted",
            onClick: () => dispatch(closePopup()),
          },
        ],
      ];

      dispatch(updatePopup(popupBody, popupButtons));
    };

    const handleClickApplyByMessage = () => {
      const popupBody = (
        <PhoneMessagePopupMain>
          <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
            휴대폰 기본 문자로 문의
          </Text>
          <div className="main__description">
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[600]}>
              <Text
                textType="Body1SemiBold"
                color={COLOR_SYSTEM.get("Skyblue")[400]}
              >
                다음
              </Text>
              을 누르면 전화의 “문자” 프로그램이 실행되고 아래 인사말이
              자동입력됩니다.
            </Text>
          </div>
          <div className="main__example">
            <Text
              textType="Body2SemiBold"
              color={COLOR_SYSTEM.get("Gray")[800]}
              style={{ marginBottom: "4px" }}
            >
              [자동 입력 인사말]
            </Text>
            <br />
            <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[800]}>
              안녕하세요, 저는 ㅇㅇㅇ 약사입니다.
              <br />
              약문약답 구인공고 보고 연락드립니다.
            </Text>
          </div>
        </PhoneMessagePopupMain>
      );
      const popupButtons: ButtonStyle[][] = [
        [
          {
            value: "취소",
            color: "Secondary",
            size: "Large",
            variant: "Tinted",
            onClick: () => dispatch(closePopup()),
          },
          {
            value: "다음",
            color: "Primary",
            size: "Large",
            variant: "Contained",
            onClick: handleClickSendMessage,
          },
        ],
      ];

      dispatch(openPopup(popupBody, popupButtons));
    };

    const registerPostId = useRef<any>();

    const renderDetailFooterButton = () => {
      if (preview) {
        return (
          <>
            <Button
              color="Primary"
              size="Large"
              type="Text"
              variant="Tinted"
              style={{ flex: 1 }}
              onClick={onClose}
            >
              수정하기
            </Button>
            <Button
              color="Primary"
              size="Large"
              type="Text"
              variant="Contained"
              style={{ flex: 1 }}
              onClick={handleRegisterClick}
            >
              등록하기
            </Button>
          </>
        );
      } else {
        if (!isOwner) {
          if (me && me.id === post.ownerId) {
            return (
              <Button
                color="Tertiary"
                size="Large"
                type="Text"
                variant="Tinted"
                className="footer__main-button"
                onClick={handleFinalizeClick}
              >
                공고 종료하기
              </Button>
            );
          } else {
            return (
              <>
                <ToggleButton
                  color="Gray"
                  icon="BookmarkFilled"
                  onClick={handleScrapClick}
                  size="Large"
                  type="Icon"
                  variant="Ghost"
                  active={post.liked}
                />
                <Button
                  color="Primary"
                  icon="CaretRight"
                  size="Large"
                  type="Text"
                  variant="Contained"
                  className="footer__main-button"
                  disabled={post.endedAt !== null}
                  disabledColor={post.endedAt !== null}
                  onClick={() => {
                    setApplyBottomSheet(true);
                  }}
                >
                  약국에 문의하기
                </Button>
              </>
            );
          }
        } else {
          if (me && me.id === post.ownerId) {
            return (
              <Button
                color="Tertiary"
                size="Large"
                type="Text"
                variant="Tinted"
                className="footer__main-button"
                onClick={handleFinalizeClick}
              >
                공고 종료하기
              </Button>
            );
          } else {
            return null;
          }
        }
      }
    };

    const handleScrapClick = async () => {
      if (post.liked) await onUnScrap();
      else {
        await onScrap();
        AdsUtil.show(AdsUtil.TYPE_INTERSTITIAL, {
          name: "구인공고스크랩",
          solution: AdsUtil.SOLUTION_ADMOB,
        });
      }
    };

    const handleOpenAfterTaxPopupClick = () => {
      setAfterTaxPopup(true);
    };

    const handleRaiseClick = () => {
      setOptionBottomSheet(false);
      if (
        new Date(post.raisedAt).toDateString() !== new Date().toDateString()
      ) {
        setRaisePopup(true);
      } else {
        setRaiseErrorPopup(true);
      }
    };

    const handleFinalizeClick = () => {
      log(LogLevel.UI_ACTION, "JobPostDetail:onClickFinalize: ");

      setOptionBottomSheet(false);
      setPassed([]);
      setFinishPopup(true);
    };

    const handleGoBackClick = useCallback(() => history.goBack(), []);

    const handleMoreClick = () => setOptionBottomSheet(true);

    const handleSendAlimClick = (postId?: number) => {
      log(LogLevel.UI_ACTION, "JobPostDetail:handleSendAlimClick: ", postId);

      if (postId) {
        setOptionBottomSheet(false);
        onSendAlimTalk(postId);
      } else {
        setSuccessPopup(false);
        onSendAlimTalk(registerPostId.current);
      }
    };

    const handlePremiumAdClick = (postId?: number) => {
      log(LogLevel.UI_ACTION, "JobPostDetail:handlePremiumAdClick: ", postId);

      if (postId) {
        setOptionBottomSheet(false);
        onPremiumAd(postId);
      } else {
        setSuccessPopup(false);
        onPremiumAd(registerPostId.current);
      }
    };

    const handleCloseSuccessPopup = () => {
      setSuccessPopup(false);
      AdsUtil.show(AdsUtil.TYPE_REWARD, { name: "구인공고등록" });
      history.goBack();
    };

    const handleRegisterClick = async () => {
      if (disabled.register) return;
      setDisabled({ ...disabled, register: true });

      try {
        const post = await onRegisterPost(form);
        registerPostId.current = post.id;
        setRegisterPost(post);
        setSuccessPopup(true);
      } catch (err) {
        console.error(err);
        setDisabled({ ...disabled, register: false });
      }
    };

    const handleOptionSelect = useCallback(
      (optionType: OptionType) => () => {
        switch (optionType) {
          case "raise":
            handleRaiseClick();
            return;
          case "alim":
            handleSendAlimClick(post.id);
            return;
          case "edit":
            onGoToEdit(post.id);
            return;
          case "finish":
            handleFinalizeClick();
            return;
          default:
            return;
        }
      },
      [post]
    );

    useEffect(() => {
      if (backKeyControl) {
        backKeyControl.pushListener(() => {
          if (bottomSheetActive) {
            dispatch(hideBottomSheet());
          } else {
            handleGoBackClick();
          }
          return true;
        }, "jobpost-detail");
      }
    }, [preview, bottomSheetActive]);

    useEffect(() => {
      if (successPopup) {
        setDisabled({ ...disabled, register: false });
      }
    }, [successPopup]);

    useEffect(() => {
      dispatch(onBottomSheet());

      return () => {
        dispatch(offBottomSheet());
        if (backKeyControl) {
          backKeyControl.popListener("jobpost-detail");
        }
      };
    }, []);

    const openPharmacyNameNoticePopup = useCallback(() => {
      const popupBody = (
        <PopupBody>
          <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
            [공지] 약국명 공개범위 안내
          </Text>
          <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[600]}>
            등록하신 공고의 약국명과 주소는
            <br />
            <Text textType="Body1Medium" color={COLOR_SYSTEM.get("Gray")[900]}>
              구직약사에게만
            </Text>{" "}
            보입니다.
            <br />타 약국장에게는 노출되지 않으니
            <br />
            안심하고 약국 정보를 입력해주세요.
          </Text>
          <Text textType="Body2" color={COLOR_SYSTEM.get("Skyblue")[400]}>
            * 구직자가 보는 채용 공고의 업체명은
            <br />
            공개되어야 합니다.
            <br />
            (직업안정법 시행령 제28조 1항)
          </Text>
        </PopupBody>
      );
      const popupButtons: ButtonStyle[][] = [
        [
          {
            value: "다시보지않기",
            color: "Secondary",
            size: "Large",
            variant: "Tinted",
            onClick: () => {
              localStorage.setItem("yc_close_notice_popup", "1");
              dispatch(closePopup());
            },
          },
          {
            value: "확인",
            color: "Primary",
            size: "Large",
            variant: "Contained",
            onClick: () => dispatch(closePopup()),
          },
        ],
      ];

      dispatch(openPopup(popupBody, popupButtons));
    }, [localStorage, dispatch]);

    useEffect(() => {
      if (isOwner && localStorage.getItem("yc_close_notice_popup") !== "1") {
        openPharmacyNameNoticePopup();
      }
    }, [localStorage, isOwner]);

    log(LogLevel.UI_LIFECYCLE, "JobPostDetail: render ", preview, post, form);

    return (
      <>
        <Header
          preview={preview}
          post={post}
          me={me}
          isMyPost={isMyPost}
          onClose={onClose}
          handleGoBackClick={handleGoBackClick}
          handleMoreClick={handleMoreClick}
        />
        <Main
          preview={preview}
          form={form}
          post={post}
          me={me}
          isNew={isNew}
          isMyPost={isMyPost}
          isOwner={isOwner}
          onMapPath={onMapPath}
          onMap={onMap}
          handleOpenAfterTaxPopupClick={handleOpenAfterTaxPopupClick}
          onKakaoShare={onKakaoShare}
          onChat={onChat}
          onClickApplyByMessage={handleClickApplyByMessage}
        />

        {!(isOwner && !preview && !isMyPost) && (
          <DetailFooter hasBanner={AdsUtil.isAvailable()}>
            {renderDetailFooterButton()}
          </DetailFooter>
        )}

        {!preview && (
          <>
            <OptionBottomSheet
              active={optionBottomSheet}
              isMyPost={isMyPost}
              isManager={isManager}
              post={post}
              onClose={() => setOptionBottomSheet(false)}
              onSelect={handleOptionSelect}
            />
            <ApplyOptionsBottomSheet
              active={applyBottomSheet}
              onClose={() => {
                setApplyBottomSheet(false);
                AdsUtil.show(AdsUtil.TYPE_INTERSTITIAL, {
                  name: "구인공고문의하기",
                  solution: AdsUtil.SOLUTION_ADMOB,
                });
              }}
              onChat={onChat}
              setPhoneMessagePopup={handleClickApplyByMessage}
              post={post}
            />
          </>
        )}

        {successPopup && (
          <RegisterSuccessPopup
            onClose={() => setSuccessPopup(false)}
            isAlimFreeLimited={
              registerPost.initCandidateCnt >= JOBOFFER_ALIM_FREE_MAX
            }
            handleCloseSuccessPopup={handleCloseSuccessPopup}
            handlePremiumAdClick={handlePremiumAdClick}
            handleSendAlimClick={handleSendAlimClick}
          />
        )}

        {/* 끌어올리기 팝업 */}
        {raisePopup && (
          <RaisePopup
            onClose={() => setRaisePopup(false)}
            handleRaiseClick={async () => {
              if (disabled.raise) return;

              setDisabled({ ...disabled, raise: true });
              await onRaisePost();
              setRaisePopup(false);
              setDisabled({ ...disabled, raise: false });
              AdsUtil.show(AdsUtil.TYPE_REWARD, { name: "구인공고끌어올리기" });
            }}
          />
        )}

        {/* 끌어올리기 에러 팝업 */}
        {raiseErrorPopup && (
          <RaiseErrorPopup
            onClose={() => {
              setRaiseErrorPopup(false);
            }}
          />
        )}

        {/* 공고 종료 팝업 */}
        {finishPopup && (
          <FinishJobPostPopup
            post={post}
            passed={passed}
            setPassed={setPassed}
            onClose={() => {
              setFinishPopup(false);
            }}
          />
        )}

        {/* 세후 계산 팝업 */}
        {afterTaxPopup && (
          <AfterTaxPopup
            preview={preview}
            form={form}
            post={post}
            onClose={() => {
              setAfterTaxPopup(false);
            }}
          />
        )}
      </>
    );
  } catch (error) {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "JOBPOST_Detail_Error",
      "에러",
      {
        page: "DetailTemplate",
        errorMessage: error,
      }
    );
    log(LogLevel.UI_EXCEPTION, "JobpostDetail.render exception", error);

    return <div>Error</div>;
  }
};

export default withRouter(JobPostDetail);
