import React, { Component } from "react";
import "./RegisterStage4.css";
import "./RegisterStage.css";
import "./../../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import { IonButton, IonToast, IonIcon, IonTextarea } from "@ionic/react";
import { userInfo } from "os";
import CheckToggleButton from "./../CheckToggleButton";
import {
  UserInfo,
  NotiSetting,
  UserLevel,
  UserPermanentType,
} from "../../models/Model.User";
import { fetchAPI } from "./../../utils/APIUtil";
import * as API from "./../../API.json";
import { action } from "typesafe-actions";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { getGlobal, GlobalKey, setGlobal } from "./../../utils/GlobalUtil";
import { log, LogLevel } from "../../utils/LogUtil";
import { UIPopupType } from "../../store/ui/types";
import { timerLap } from "../../utils/TimeUtil";
import InformationViewerPopup from "./../../component/template/InformationViewPopup";
import InformationContract from "./../../component/organism/Information/InformationContract";
import InformationPrivacy from "./../../component/organism/Information/InformationPrivacy";
import AnalyticsUtil from "./../../utils/AnalyticsUtil";
import InformationMarketing from "../../component/organism/Information/InformationMarketing";
import { CONTRACT_VERSION } from "./../../config.json";

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    private: boolean;
    user: UserInfo;
    onDone: (data: any) => void;
    onBack: (data: any) => void;
  };

type State = {
  ygChecked: boolean;
  piChecked: boolean;
  notiChecked: boolean;
  permanentChecked: boolean;
  marketingChecked: boolean;
  allChecked: boolean;
};

const windowAny: any = window;

class RegisterStage4 extends Component<Props, State> {
  // popupFinalize=null;
  // props.waitingPopup = null;
  informationPopup = null;
  holding = false;

  constructor(props) {
    super(props);
    this.state = {
      ygChecked: false,
      piChecked: false,
      notiChecked: false,
      permanentChecked: false,
      marketingChecked: false,
      allChecked: false,
    };
  }

  canPass() {
    if (!(this.state.ygChecked && this.state.piChecked)) {
      return false;
    }
    return true;
  }

  finalize = async () => {
    if (!(this.state.ygChecked && this.state.piChecked)) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "REGISTRATION_6_FAIL",
        "회원가입_6_약관동의살퍄",
        { 이유: "필수동의사항누락" }
      );
      this.props.toastPopup.show(
        "필수 동의사항을 동의하셔야 이용하실수 있습니다."
      );
      // this.setState({toastMessage: "필수 동의사항을 동의하셔야 이용하실수 있습니다."});
      return;
    }
    let user: UserInfo = this.props.user;
    log(LogLevel.UI_ACTION, "finalize User:", user);

    user.contractVersion = CONTRACT_VERSION;
    if (this.state.marketingChecked) {
      user.agreeMarketingAt = 1;
    }
    if (this.state.permanentChecked) {
      user.permenent = UserPermanentType.PERMANENT;
    } else {
      user.permenent = UserPermanentType.NORMAL;
    }

    if (this.state.notiChecked) {
      let setting =
        NotiSetting.REPLY_ON_MY_POST +
        NotiSetting.REPLY_ON_SCRAP +
        NotiSetting.REPLY_ON_LIKE;
      user.notiSocial = setting;
      user.notiPush = setting;
    }

    this.props.onDone(user);
  };

  onBack = () => {
    this.props.onBack({});
  };

  onChecked(type: string) {
    let ygChecked = this.state.ygChecked;
    let piChecked = this.state.piChecked;
    let notiChecked = this.state.notiChecked;
    let permanentChecked = this.state.permanentChecked;
    let marketingChecked = this.state.marketingChecked;
    let allChecked = this.state.allChecked;

    switch (type) {
      case "yg":
        ygChecked = !ygChecked;
        break;
      case "pi":
        piChecked = !piChecked;
        break;
      case "noti":
        notiChecked = !notiChecked;
        if (notiChecked) {
          setGlobal(GlobalKey.NOTIFICATION_OPTION, "", true);
        } else {
          setGlobal(GlobalKey.NOTIFICATION_OPTION, "-", true);
        }
        break;
      case "permanent":
        permanentChecked = !permanentChecked;
        break;
      case "marketing":
        marketingChecked = !marketingChecked;
        break;
      case "all":
        ygChecked = !allChecked;
        piChecked = !allChecked;
        notiChecked = !allChecked;
        permanentChecked = !allChecked;
        marketingChecked = !allChecked;
        break;
    }

    if (
      ygChecked &&
      piChecked &&
      notiChecked &&
      permanentChecked &&
      marketingChecked
    )
      allChecked = true;
    else allChecked = false;

    this.setState({
      ygChecked,
      piChecked,
      notiChecked,
      allChecked,
      permanentChecked,
      marketingChecked,
    });
  }

  showContract = (e) => {
    e.stopPropagation();
    this.informationPopup.show(InformationContract);
  };

  showPrivacy = (e) => {
    e.stopPropagation();
    this.informationPopup.show(InformationPrivacy);
  };

  showMarketing = (e) => {
    e.stopPropagation();
    this.informationPopup.show(InformationMarketing);
  };

  render() {
    return (
      <div className="register4-container">
        <InformationViewerPopup ref={(ref) => (this.informationPopup = ref)} />
        <div className="register-progress-container">
          <div className="register-progress-done">1</div>
          <div className="register-progress-undone">2</div>
          <div className="register-progress-undone">3</div>
          <div className="register-progress-undone">4</div>
          <div className="register-progress-undone">5</div>
        </div>
        <div className="register4-body-container">
          <div className="register-title">약문약답 서비스 약관</div>
          <div className="register4-service-description-container">
            <div className="register4-service-description">
              약문약답 서비스 이용에 필요한 약관에 동의해주세요.
            </div>
          </div>

          <div className="register4-check-item-container">
            <CheckToggleButton
              checked={this.state.allChecked}
              onClick={() => this.onChecked("all")}
            />
            <div className="register4-check-item-all">모두 동의합니다.</div>
          </div>
          <div className="register4-sub-item-container">
            <div className="register4-check-item-description">
              전체 동의는 필수 및 선택정보에 대한 동의도 포함되어 있습니다.
              개별적 동의 선택도 가능하며, 선택항목에 대한 동의를 거부하여도
              서비스 이용은 가능합니다.{" "}
            </div>
          </div>
          <div className="register4-line" />
          <div className="register4-sub-item-container">
            <div
              className="register4-check-item-container"
              onClick={() => this.onChecked("yg")}
            >
              <CheckToggleButton checked={this.state.ygChecked} />
              <div className="register4-check-item">[필수] 이용 약관 동의</div>
              <IonButton
                expand="block"
                color="register4-detail-view"
                onClick={this.showContract}
              >
                자세히보기
              </IonButton>
            </div>
            <div
              className="register4-check-item-container"
              onClick={() => this.onChecked("pi")}
            >
              <CheckToggleButton checked={this.state.piChecked} />
              <div className="register4-check-item">
                [필수] 개인정보 처리방침 동의
              </div>
              <IonButton
                expand="block"
                color="register4-detail-view"
                onClick={this.showPrivacy}
              >
                자세히보기
              </IonButton>
            </div>
            <div
              className="register4-check-item-container"
              onClick={() => this.onChecked("noti")}
            >
              <CheckToggleButton checked={this.state.notiChecked} />
              <div className="register4-check-item">앱 알림 수신 동의</div>
            </div>
            <div className="register4-check-item-description-container">
              <div className="register4-check-item-description common-color-point">
                답변을 실시간으로 받으려면 푸시알림 동의하셔야 합니다.
              </div>
            </div>
            <div
              className="register4-check-item-container"
              onClick={() => this.onChecked("marketing")}
            >
              <CheckToggleButton checked={this.state.marketingChecked} />
              <div className="register4-check-item">
                혜택 및 마케팅 정보 수신 동의
              </div>
              <IonButton
                expand="block"
                color="register4-detail-view"
                onClick={this.showMarketing}
              >
                자세히보기
              </IonButton>
            </div>
          </div>
        </div>
        <div className="register-navigate-container">
          <IonButton color="register-navigate-inactive" onClick={this.onBack}>
            &lt; 이전 단계로
          </IonButton>
          <div className="register-navigate-space" />
          <IonButton
            color={
              this.canPass()
                ? "register-navigate-active"
                : "register-navigate-inactive"
            }
            onClick={this.finalize}
          >
            다음 단계로 &gt;
          </IonButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  users: state.user,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {
  loginUser: () => actions.user.login(),
  logoutUser: () => actions.user.logout(),
  setUserToken: (token: string) => actions.user.setToken(token),
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterStage4);
