import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { IonButton, IonFab, IonFabButton, IonLabel, IonSpinner } from "@ionic/react";
import "./Viewer.css";
import "./../Common.css";
import "./../theme.css";
import nudgeInviteImage from "./../assets/image/confirm-card-nudge-invite.png";
import kakaoIcon from "./../assets/icon/kakao_logo.png";
import * as API from "./../API.json";
import { fetchAPI } from "./../utils/APIUtil";
import ViewerBody from "./../components/ViewerBody";
import ReplyList from "./../components/ViewerReplyList";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { log, LogLevel } from "../utils/LogUtil";
import { getGlobal, GlobalKey, clearGlobal, setGlobal } from "./../utils/GlobalUtil";
import { UIPopupType, UIServiceType } from "../store/ui/types";
import { BoardContent, BoardAttribute } from "../models/Model.Board";
import AnalyticsUtil from "./../utils/AnalyticsUtil";
import { UserLevel, UserNudgeState } from "../models/Model.User";
import ABTestUtil, { ABTestFeature } from "../utils/ABTestUtil";
import ViewerRecommends from "../components/ViewerRecommends";
import { sendKakaoInviteMessage } from "../utils/KakaoUtil";
import { timerStart, timerStop } from "../utils/TimeUtil";
import BoardViewTopAppBar from "../components/organisms/BoardView/BoardViewTopAppBar";
import { Absolute, Fixed, Flex, Relative, Static } from "../components/atoms/Layout";
import FloatingButton from "../components/atoms/FloatingButton";
import Footer from "../components/cell/Footer";
import Button from "../components/atoms/Button";
import Text from "../components/atoms/Text";
import { COLOR_SYSTEM } from "../components/design/design-system";
import { callPhone } from "../utils/UrlSchemeUtil";
import withBottomSheet, { WithBottomSheetProps } from "../hoc/withBottomSheet";
import ConsultPopup from "../components/templates/AllInOneDetail/ConsultPopup";
import BoardSummaryPopupPage from "./BoardSummaryPopup";
import { BoardType } from "../store/board/types";
import Icon from "../components/atoms/Icon";
import AdsUtil from "../utils/AdsUtil";

const queryString = require("query-string");
const windowAny: any = window;

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  WithBottomSheetProps & {
    contentId?: number;
    recommendDisabled?: boolean;
    adminMode?: boolean;
    onBack?: () => void;
  };

type State = {
  reload: boolean;
  needAnswerButton: boolean;
  partnerId: string;
  focus: { contentId?: number; commentId?: number };
  guided: number;
};

class Viewer extends Component<Props, State> {
  id: number = 0;
  contentScroll = null;
  postRef = null;
  replyRef = null;
  recommendRef = null;
  focusedScroll = 0;

  progressScroll = 0;
  progressCardnews = -1;

  constructor(props: Props) {
    super(props);
    let partnerId = "";
    log(LogLevel.UI_LIFECYCLE, "Viewer.constructor", this.props, this.props.location, this.props.history.length);
    let qs = queryString.parse(this.props.location.search);
    let newId = 0;
    try {
      newId = parseInt(qs.id);
    } catch (err) {
      newId = 0;
    }
    if (!newId && this.props.contentId) {
      newId = this.props.contentId;
    }

    if (qs.partnerId) partnerId = qs.partnerId;

    log(LogLevel.UI_LIFECYCLE, "Viewer:constructor new ID ", newId);
    if (this.id != newId) {
      log(LogLevel.UI_LIFECYCLE, "Viewer:constructor new ID DETECTED", newId);
      this.id = newId;
      this.progressScroll = 0;
      this.progressCardnews = -1;
      timerStart();

      this.props.loadContent(this.id);

      if (this.props.contents) {
        let content: BoardContent = this.props.contents[this.id];
        // AMPLITUDE
        let body = "";
        let categoryName = "";
        if (content && content.bodyText) {
          log(LogLevel.UI_LIFECYCLE, "Viewer:constructor content", content);
          partnerId = content.usersBizId;

          if (content.subject) body = content.subject.substring(0, 10);
          else body = content.bodyText.substring(0, 10);

          categoryName = content.boardsCategoriesName;
          AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "CONTENT_START", "게시물진입", {
            게시물id: this.id,
            게시물내용: body,
            게시판이름: categoryName,
          });

          if (content.usersBizId) {
            AdsUtil.hide(AdsUtil.TYPE_BANNER);
          } else {
            AdsUtil.show(AdsUtil.TYPE_BANNER, {});
          }
        }
      }
    }

    if (!this.props.isAccomplished && !getGlobal(GlobalKey.NEED_GROUND_RULE_GUIDE, true)) {
      let today = Math.floor((Date.now() + 9 * 60 * 60 * 1000) / (24 * 60 * 60 * 1000));
      if (
        !getGlobal(GlobalKey.GROUND_RULE_GUIDED_DATE, true) ||
        today > getGlobal(GlobalKey.GROUND_RULE_GUIDED_DATE, true)
      )
        setGlobal(GlobalKey.NEED_GROUND_RULE_GUIDE, true, true);
    }

    let focus: { contentId?: number; commentId?: number } = {};
    try {
      let focusContent = parseInt(qs.fcnt);
      if (focusContent) focus.contentId = focusContent;
    } catch (err) {
      focus.contentId = 0;
    }
    try {
      let focusComment = parseInt(qs.fcmt);
      if (focusComment) focus.commentId = focusComment;
    } catch (err) {
      focus.commentId = 0;
    }

    this.state = {
      reload: false,
      needAnswerButton: true,
      partnerId,
      focus,
      guided: getGlobal(GlobalKey.VIEWER_SUMMARY_GUIDED, true),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    let qs = queryString.parse(this.props.location.search);
    let newId = this.id;
    try {
      newId = parseInt(qs.id);
      if (!newId) newId = 0;
    } catch (err) {
      newId = 0;
    }
    if (
      (!newId && this.props.contentId) ||
      ((this.props.contentId || prevProps.contentId) && this.props.contentId != prevProps.contentId)
    ) {
      newId = this.props.contentId;
    }

    //console.log(LogLevel.UI_LIFECYCLE, "Viewer:compoenetDidUpdate new ID ", newId);
    if (this.id != newId) {
      log(
        LogLevel.UI_LIFECYCLE,
        "Viewer:compoenetDidUpdate new ID DETECTED",
        newId,
        this.props.contentId,
        prevProps.contentId,
        this.id
      );
      let timeSpent = Math.floor(timerStop() / 1000);
      let lastContent = this.props.contents[this.id];
      if (lastContent && lastContent.advertiserCode) {
        let isCardnews = lastContent.cards && lastContent.cards.length;
        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "ADVERTISE_VIEW_COMPLETE", "광고콘텐츠시청완료", {
          type: isCardnews ? "카드뉴스" : "컨텐츠",
          code: lastContent.advertiserCode,
          productCode: lastContent.advertiserProductCode,
          contentType: isCardnews ? "CARDNEWS" : "CONTENT",
          contentId: lastContent.id,
          contentTitle: lastContent.subject,
          timeSpent,
          progress: isCardnews ? this.progressCardnews : this.progressScroll,
        });
      }

      this.id = newId;
      this.props.loadContent(this.id);
      this.setState({ reload: !this.state.reload });
      this.scrollToTop();
      this.progressScroll = 0;
      this.progressCardnews = -1;
      timerStart();

      if (this.props.contents) {
        let content: BoardContent = this.props.contents[this.id];

        // AMPLITUDE
        let body = "";
        let categoryName = "";
        if (content && content.bodyText) {
          log(LogLevel.UI_LIFECYCLE, "Viewer:compoenetDidUpdate content", content);
          if (this.state.partnerId != content.usersBizId) {
            this.setState({ partnerId: content.usersBizId });
          }

          if (content.subject) body = content.subject.substring(0, 10);
          else body = content.bodyText.substring(0, 10);

          categoryName = content.boardsCategoriesName;
          AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "CONTENT_START", "게시물진입", {
            게시물id: this.id,
            게시물내용: body,
            게시판이름: categoryName,
          });
        }

        if (content) {
          if (content.usersBizId) {
            AdsUtil.hide(AdsUtil.TYPE_BANNER);
          } else {
            AdsUtil.show(AdsUtil.TYPE_BANNER, {});
          }
        }
      }
    } else if (
      this.id &&
      prevProps.contents &&
      (!prevProps.contents[this.id] || !prevProps.contents[this.id].bodyText) &&
      this.props.contents &&
      this.props.contents[this.id] &&
      this.props.contents[this.id].bodyText
    ) {
      let content: BoardContent = this.props.contents[this.id];

      // AMPLITUDE
      let body = "";
      let categoryName = "";
      if (content) {
        log(LogLevel.UI_LIFECYCLE, "Viewer:compoenetDidUpdate content loading success", content);

        if (this.state.partnerId != content.usersBizId) {
          this.setState({ partnerId: content.usersBizId });
        }

        if (content.subject) body = content.subject.substring(0, 10);
        else body = content.bodyText.substring(0, 10);

        categoryName = content.boardsCategoriesName;

        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "CONTENT_START", "게시물진입", {
          게시물id: this.id,
          게시물내용: body,
          게시판이름: categoryName,
        });

        if (content.usersBizId) {
          AdsUtil.hide(AdsUtil.TYPE_BANNER);
        } else {
          AdsUtil.show(AdsUtil.TYPE_BANNER, {});
        }
      }
    }
    if (!prevProps.isAccomplished && this.props.isAccomplished && getGlobal(GlobalKey.NEED_GROUND_RULE_GUIDE, true)) {
      setGlobal(GlobalKey.NEED_GROUND_RULE_GUIDE, false, true);
    }

    if (!prevProps.backKeyControl && this.props.backKeyControl) this.props.backKeyControl.setListener(this.goBack);

    // let focus = {};
    // let focusChaged = false;
    // try {
    //   let focusContent = parseInt(qs.fcnt);
    //   if (focusContent && this.state.focus.contentId === focusContent)
    //   focus = { contentId: focusContent };
    //   focusChaged = true;
    // } catch (err) {}
    // try {
    //   let focusComment = parseInt(qs.fcmt);
    //   if (focusComment && this.state.focus.commentId === focusComment)
    //   focus = { commentId: focusComment };
    //   focusChaged = true;
    // } catch (err) {}

    // if(focusChaged)
    //   this.setState({focus});
  }

  componentDidMount() {
    log(LogLevel.UI_LIFECYCLE, "Viewer:componentDidMount backbutton register");
    if (this.props.backKeyControl) {
      setTimeout(() => {
        this.props.backKeyControl.setListener(this.goBack);
      }, 50);
    }
    // document.addEventListener("backbutton", this.goBack);
    if (!this.props.partnerList) this.props.getPartnerList();

    if (getGlobal(GlobalKey.OS) === "browser") document.body.addEventListener("copy", this.onCopyDetect);
  }

  componentWillUnmount() {
    // if(this.props.progressPopup)
    //   this.props.progressPopup.hide();
    // document.removeEventListener("backbutton", this.goBack);
    if (getGlobal(GlobalKey.OS) == "browser") document.body.removeEventListener("copy", this.onCopyDetect);

    let timeSpent = Math.floor(timerStop() / 1000);
    let lastContent = this.props.contents[this.id];
    if (lastContent && lastContent.advertiserCode) {
      let isCardnews = lastContent.cards && lastContent.cards.length;
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "ADVERTISE_VIEW_COMPLETE", "광고콘텐츠시청완료", {
        type: isCardnews ? "카드뉴스" : "컨텐츠",
        code: lastContent.advertiserCode,
        productCode: lastContent.advertiserProductCode,
        contentType: isCardnews ? "CARDNEWS" : "CONTENT",
        contentId: lastContent.id,
        contentTitle: lastContent.subject,
        timeSpent,
        progress: isCardnews ? this.progressCardnews : this.progressScroll,
      });
    }
    log(LogLevel.UI_EVENT, "Viewer:componentWillUnmount backbutton unregister", lastContent);
    AdsUtil.hide(AdsUtil.TYPE_BANNER);
  }

  onCopyDetect = (e) => {
    let content: BoardContent = this.props.contents[this.id];
    // AMPLITUDE
    let body = "";
    if (content) {
      if (content.subject) body = content.subject.substring(0, 10);
      else content.bodyText;
      body = content.bodyText.substring(0, 10);
    }

    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "CONTENT_COPY", "게시물복사", {
      게시물id: this.id,
      게시물내용: body,
    });

    let lastCopied: number = getGlobal(GlobalKey.COPYRIGHT_NOTIFIED_AT, true);
    if (!lastCopied) {
      setGlobal(GlobalKey.COPYRIGHT_NOTIFIED_AT, Math.floor(Date.now() / (1000 * 60 * 60 * 24)), true);
      if (this.props.confirmPopup) {
        this.props.confirmPopup.show({
          iconImage: null,
          title: <div className="common-container">저작권 관련 공지</div>,
          body: (
            <div className="common-container">
              1. 약문약답 내의 컨텐츠의 저작권은 작성자에게 있으므로 임의로 다른 사이트에 게재하거나 외부에 공개되는
              자료 작성에 이용해서는 안됩니다. (이용약관 11조)
              <br />
              <br />
              2. 단, 회원 개인 학습목적으로는 이용 가능합니다.
            </div>
          ),
          doneText: "네, 알겠습니다",
          backDropDismiss: true,
        });
      }
    }
    // alert("copy!!");
  };

  onCardnewsProgress = (page, total) => {
    log(LogLevel.UI_ACTION, "Viewer:onCardnewsProgress", page, total);
    let progressCardnews = Math.floor(((page + 1) * 100) / total);
    if (this.progressCardnews < progressCardnews) this.progressCardnews = progressCardnews;
  };

  scrollToTop = () => {
    setTimeout(() => {
      log(LogLevel.UI_LIFECYCLE, "Viewer:compoenetDidUpdate scroll up", this.contentScroll);
      if (this.contentScroll) this.contentScroll.scrollTo(0, 0);
      else this.scrollToTop();
    }, 10);
  };

  doNudgeInvite = async () => {
    log(LogLevel.UI_LIFECYCLE, "Viewer:doNudgeInvite");
    let me = this.props.me;
    me.nudgeState = UserNudgeState.NUDGED_ACCEPED;
    this.props.setMe(me);

    let newMe = {
      nudgeState: me.nudgeState,
    };

    fetchAPI(API.USER_ME_UPDATE, "", null, newMe, getGlobal(GlobalKey.TOKEN));
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "NUDGE_INVITE", "NUDGE초대하기", {});
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "INVITE", "초대하기", {
      위치: "넛지",
    });
    sendKakaoInviteMessage(this.props.me.name, this.props.me);
  };

  cancelNudgeInvite = () => {
    log(LogLevel.UI_LIFECYCLE, "Viewer:cancelNudgeInvite");
    let me = this.props.me;
    me.nudgeState = me.nudgeState ? me.nudgeState + 1 : 1;
    this.props.setMe(me);

    let newMe = {
      nudgeState: me.nudgeState,
    };

    fetchAPI(API.USER_ME_UPDATE, "", null, newMe, getGlobal(GlobalKey.TOKEN));
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "NUDGE_INVITE_CANCEL", "NUDGE초대하기취소", {
      id: this.props.me.id,
      name: this.props.me.name,
      nudgeState: me.nudgeState,
    });
  };

  goBack = (e) => {
    // this.props.toast.show("go back");
    log(LogLevel.UI_ACTION, "Viewer:goBack", this.props.imageViewerPopup.isShown(), this.props.keywordPopup.isShown());

    if (this.props.imageViewerPopup && this.props.imageViewerPopup.isShown()) this.props.imageViewerPopup.hide();
    else if (this.props.keywordPopup && this.props.keywordPopup.isShown()) this.props.keywordPopup.hide();
    // else if (this.props.confirmPopup && this.props.confirmPopup.isShown())
    //   this.props.confirmPopup.hide();
    else {
      if (this.props.onBack) {
        this.props.onBack();
      } else if (ABTestUtil.isTest(ABTestFeature.NUDGE) && getGlobal(GlobalKey.NUDGE_READY)) {
        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "NUDGE_INVITE_POPUP", "NUDGE초대하기팝업", {
          id: this.props.me.id,
          name: this.props.me.name,
          nudgeState: this.props.me.nudgeState,
        });
        let point: number =
          (this.props.me.likeCnt ? this.props.me.likeCnt : 0) + (this.props.me.scrapCnt ? this.props.me.scrapCnt : 0);
        setGlobal(GlobalKey.NUDGE_READY, false);
        this.props.confirmPopup.show({
          image: nudgeInviteImage,
          iconImage: null,
          buttons: (
            <div className="common-container-column common-flex-align-center">
              <IonButton color="common-button" class="viewer-nudge-button" onClick={this.doNudgeInvite}>
                <img className="login-kakao-logo" src={kakaoIcon} />
                <span>초대 메시지 보내기</span>
              </IonButton>
            </div>
          ),
          backDropDismiss: true,
          onBackdropDismiss: this.cancelNudgeInvite,
        });
      } else {
        if (this.props.history.length > 1) this.props.history.goBack();
        else this.props.history.replace("/");
      }
    }
  };

  onReply = () => {
    this.props.history.push(`/reply?id=${this.id}`);
  };

  onSummary = () => {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "GPT_SUMMARY_START", "게시글상세보기_요약_클릭", {
      게시물id: this.id,
    });

    this.props.bottomSheet.show({
      body: (
        <BoardSummaryPopupPage
          contentId={this.id}
          onClickReference={(focus) => {
            log(LogLevel.UI_LIFECYCLE, "Viewer:bottomSheet:onClickReference", focus);
            this.props.bottomSheet.close();
            if (focus == null) {
              focus = { contentId: this.id };
            }
            this.setState({ focus });
            AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "GPT_SUMMARY_GROUP_REFERENCE", "게시글_요약팝업_원문보기", {
              게시물id: this.id,
              url: focus,
            });
          }}
        />
      ),
    });
  };

  scroll = (event) => {
    if (getGlobal(GlobalKey.LONG_PRESS_TIMER)) {
      clearTimeout(getGlobal(GlobalKey.LONG_PRESS_TIMER));
      clearGlobal(GlobalKey.LONG_PRESS_TIMER);
    }

    if (this.contentScroll && this.recommendRef) {
      // log(LogLevel.UI_ACTION,"Viewer:scroll", this.contentScroll.scrollHeight, this.contentScroll.scrollTop, this.contentScroll.clientHeight, this.recommendRef.offsetTop);
      // if(this.state.needAnswerButton){
      //   if(this.contentScroll.scrollTop + this.contentScroll.clientHeight > this.recommendRef.offsetTop + 100){
      //     this.setState({needAnswerButton:false})
      //   }
      // }else {
      //   if(this.contentScroll.scrollTop + this.contentScroll.clientHeight <= this.recommendRef.offsetTop + 100){
      //     this.setState({needAnswerButton:true})
      //   }
      // }
    }

    let progressScroll = Math.floor(
      ((this.contentScroll.scrollTop + this.contentScroll.clientHeight) * 100) / this.contentScroll.scrollHeight
    );
    if (this.progressScroll < progressScroll) this.progressScroll = progressScroll;
    log(LogLevel.UI_ACTION, "Viewer:scroll", this.progressScroll, progressScroll);
    // log(LogLevel.UI_ACTION,"Viewer:scroll", this.progressScroll, progressScroll, this.contentScroll.scrollHeight, this.contentScroll.scrollTop, this.contentScroll.clientHeight, this.recommendRef.offsetTop);
    // log(LogLevel.UI_ACTION,"Viewer:scroll", this.progressScroll, progressScroll, event.target.scrollHeight, event.target.scrollTop, event.target.clientHeight, this.postRef?this.postRef.offsetTop:0, this.postRef?this.postRef.clientHeight:0, this.replyRef?this.replyRef.offsetTop:0, this.replyRef?this.replyRef.clientHeight:0,);
  };

  onFindFocusComponent = (focused: any) => {
    log(LogLevel.UI_ACTION, "Viewer:onFindFocusComponent", focused);
    if (this.focusedScroll) return;
    this.focusedScroll = focused;

    setTimeout(() => {
      if (this.contentScroll)
        this.contentScroll.scrollTo(0, this.focusedScroll - (this.state.focus.commentId ? 100 : 50));
      this.focusedScroll = 0;
    }, 50);
  };

  onConsult = () => {
    if (this.props.partners && this.state.partnerId && this.props.partners[this.state.partnerId]) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "ALLINONE_SELECT_PARTNER_CONSULT_START",
        "약사파트너스_파트너_상담신청_클릭",
        {
          회사:
            this.props.partners && this.state.partnerId && this.props.partners[this.state.partnerId]
              ? this.props.partners[this.state.partnerId].companyName
              : "",
        }
      );
      this.props.history.push("/partners/consult?id=" + this.state.partnerId);
    }

    // this.props.bottomSheet.show({
    //   body: (
    //     <ConsultPopup me={this.props.me} onConsult={this.onConsultDone}/>
    //   ),
    // });
  };

  onConsultDone = async (message: string) => {
    this.props.bottomSheet.close();
    await fetchAPI(
      API.ALLINONE_CONSULT,
      "",
      null,
      { partnerId: this.state.partnerId, questionText: message },
      getGlobal(GlobalKey.TOKEN)
    );
    this.props.toast.show("상담예약이 완료되었습니다.");
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "ALLINONE_SELECT_PARTNER_CONSULT",
      "약사파트너스_파트너_상담신청_완료",
      {
        회사:
          this.props.partners && this.state.partnerId && this.props.partners[this.state.partnerId]
            ? this.props.partners[this.state.partnerId].companyName
            : "",
      }
    );
  };

  render() {
    log(
      LogLevel.UI_LIFECYCLE,
      "Viewer:render()",
      this.props,
      this.postRef,
      this.replyRef,
      this.contentScroll,
      this.props.partners,
      this.state.partnerId,
      this.props.partners[this.state.partnerId]
    );
    let content: BoardContent = this.props.contents[this.id];
    let noReply: boolean = true;
    let categoryName = "";
    let attribute = "";

    if (!content || content.bodyText === null || content.bodyText === undefined) {
      return (
        <>
          <BoardViewTopAppBar boardId={this.id} scrapped={false} content={content} />
          <div className="viewer-content-no-footer">
            <div className="viewer-content-waiting-container">
              <IonSpinner class="viewer-content-waiting" name="crescent" />
            </div>
          </div>
        </>
      );
    }

    if (content) {
      if (content.boardsCategoriesAttribute) attribute = content.boardsCategoriesAttribute;
      else if (this.props.boards[content.boardsCategoriesId])
        attribute = this.props.boards[content.boardsCategoriesId].attribute;
    }

    if (content) {
      categoryName = this.props.boards[content.boardsCategoriesId]
        ? this.props.boards[content.boardsCategoriesId].name
        : "보기";
      noReply = !attribute.includes(BoardAttribute.CAN_REPLY);
    }

    let replyButton;
    if (!noReply && this.state.needAnswerButton) {
      let replyTerm = "답변";
      if (attribute.includes(BoardAttribute.IS_DISCUSSION)) replyTerm = "의견";

      replyButton = (
        // <IonFab slot="fixed" vertical="bottom" horizontal="end">
        //   <IonFabButton color="boards-fab" onClick={this.onReply}>
        //     <IonLabel class="boards-fab-text">
        //       {replyTerm}
        //       <br />
        //       작성
        //     </IonLabel>
        //   </IonFabButton>
        // </IonFab>
        <Fixed
          bottom={AdsUtil.isAvailable() ? "60px" : "32px"}
          left="50%"
          zIndex={100}
          customStyle={{ transform: "translateX(-50%)" }}
        >
          <FloatingButton size="Large" color="Primary" type="IconWithText" icon="Pencil" left onClick={this.onReply}>
            의견 남기기
          </FloatingButton>
        </Fixed>
      );
    }

    let summaryButton;

    let showSummaryButton = false;
    if (!ABTestUtil.isTest(ABTestFeature.UI_GPT_SUMMARY)) showSummaryButton = false;
    else if (
      !content ||
      (content.replyCnt == 0 && content.bodyText.length < 500) ||
      !content.replies ||
      content.replies.length == 0 ||
      content.boardsCategoriesId == BoardType.ALLINONE
    ) {
      showSummaryButton = false;
    } else {
      if (ABTestUtil.isTest()) {
        showSummaryButton = true;
      } else {
        for (let reply of content.replies) {
          if (reply && reply.bodyText && reply.bodyText.length > 30) {
            showSummaryButton = true;
            break;
          }
        }
      }
    }
    if (showSummaryButton) {
      summaryButton = (
        <Fixed bottom={AdsUtil.isAvailable() ? "60px" : "32px"} right="20px" zIndex={100}>
          {!this.state.guided && (
            <Absolute top="-48px" right="0px">
              <Static
                style={{
                  background: COLOR_SYSTEM.get("Blue")[500],
                  padding: "8px 12px",
                  display: "flex",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                  borderRadius: "8px",
                  gap: "12px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setGlobal(GlobalKey.VIEWER_SUMMARY_GUIDED, Date.now(), true);
                  this.setState({ guided: 1 });
                }}
              >
                <Text textType={"Body2"} color={COLOR_SYSTEM.get("Gray")[0]}>
                  AI가 중요한 내용만 알기 쉽게 정리해드려요!
                </Text>
                <Icon size={16} name="X" color={COLOR_SYSTEM.get("Blue")[100]} />
              </Static>
              <Flex justifyContent="end">
                <div
                  style={{
                    marginRight: "20px",
                    height: "8px",
                    width: "12px",
                    borderLeft: "8px solid transparent",
                    borderRight: "8px solid transparent",
                    borderTop: `8px solid ${COLOR_SYSTEM.get("Blue")[500]}`,
                  }}
                />
              </Flex>
            </Absolute>
          )}
          <FloatingButton
            size="Large"
            color="Primary"
            type="Icon"
            right
            onClick={this.onSummary}
            style={{
              width: "48px",
              height: "48px",
              background: COLOR_SYSTEM.get("Gray")[900],
            }}
          >
            <img style={{ height: "35px", minWidth: "45px" }} src="/images/robot.png" />
          </FloatingButton>
        </Fixed>
      );
    }

    return (
      <>
        <BoardViewTopAppBar
          boardId={this.id}
          scrapped={content.scrapped}
          content={content}
          adminMode={this.props.adminMode}
          onBack={this.goBack}
        />
        <div className="viewer-content">
          <Relative
            customStyle={{
              zIndex: 1,
              width: "100%",
              height: "100%",
              backgroundColor: "#eff3f5",
              overflowY: "auto",
              webkitOverflowScrolling: "touch",
              overscrollBehaviorY: "none",
              paddingBottom: AdsUtil.isAvailable() ? "130px" : "100px",
            }}
            ref={(ref) => {
              this.contentScroll = ref;
            }}
            onScroll={this.scroll}
          >
            <div
              ref={(ref) => {
                this.postRef = ref;
              }}
            >
              <ViewerBody
                postingId={this.id}
                findFocus={this.state.focus}
                onFindFocusComponent={this.onFindFocusComponent}
                adminMode={this.props.adminMode}
                partner={this.props.partners && this.state.partnerId && this.props.partners[this.state.partnerId]}
                onCardnewProgress={this.onCardnewsProgress}
              />
            </div>
            <div
              ref={(ref) => {
                this.replyRef = ref;
              }}
            >
              <ReplyList
                noReply={noReply}
                postingId={this.id}
                findFocus={this.state.focus}
                onFindFocusComponent={this.onFindFocusComponent}
                adminMode={this.props.adminMode}
              />
            </div>
            <div
              ref={(ref) => {
                this.recommendRef = ref;
              }}
            >
              <ViewerRecommends posting={content} disabled={this.props.recommendDisabled} />
            </div>
          </Relative>
          {replyButton}
          {summaryButton}
          {this.props.partners && this.state.partnerId && this.props.partners[this.state.partnerId] && (
            <Footer
              customStyle={{
                background: COLOR_SYSTEM.get("Gray")[0],
                zIndex: 1,
              }}
            >
              <div className="common-container-row common-flex-align-center" style={{ gap: "10px" }}>
                <Button
                  style={{ flexGrow: 1 }}
                  size={"Medium"}
                  type={"Text"}
                  variant={"Contained"}
                  color={"Secondary"}
                  onClick={() => {
                    callPhone(this.props.partners[this.state.partnerId].phone);
                  }}
                >
                  전화하기
                </Button>
                <Button
                  style={{ flexGrow: 1 }}
                  size={"Medium"}
                  type={"Text"}
                  variant={"Outlined"}
                  color={"Secondary"}
                  onClick={this.onConsult}
                >
                  문의 남기기
                </Button>
              </div>
            </Footer>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  // user: state.user,
  boards: state.board.boards,
  contents: state.board.contents,
  imageViewerPopup: state.ui.popups[UIPopupType.IMAGE_VIEWER_POPUP],
  keywordPopup: state.ui.popups[UIPopupType.KEYWORD_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  toast: state.ui.popups[UIPopupType.TOAST_POPUP],
  isAccomplished: state.user.isAccomplished,
  me: state.user.me,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
  partnerList: state.allinone.partnerList,
  partners: state.allinone.partners,
});

const mapDispatchToProps = {
  // logOutUser: () => actions.user.logout(),
  // refreshBoards: () => actions.board.refreshBoards(),
  // loadBoard: (board:BoardType) => actions.board.getBoard(board.toString(), 50),
  loadContent: (id: number) => actions.board.getContent(id),
  setMe: (me) => actions.user.setMe(me),
  getPartnerList: () => actions.allinone.getPartnerList(),
};
export default withBottomSheet(withRouter(connect(mapStateToProps, mapDispatchToProps)(Viewer)));
