import React, { Component } from "react";
import "./../../Admin.scss";
import "./../../../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "./../../../utils/APIUtil";
import { timeout } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
  AllInOnePartnerLevel,
  CompanyTypeName,
  PARTNERS_TEMPLATE,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import ProfileImage from "../../../components/ProfileImage";
import IconButton from "../../../component/atom/IconButton";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import StringUtil from "../../../utils/StringUtil";
import { RootState } from "../../../store";
import { UIPopupType } from "../../../store/ui/types";
import { connect } from "react-redux";
import ToggleSwitch from "../../../component/atom/ToggleSwitch";
import { BizUserInfo } from "../../../models/Mode.Biz";
import { COLOR_SYSTEM } from "../../../components/design/design-system";
import { Flex } from "../../../components/atoms/Layout";
import Button from "../../../components/atoms/Button";
import { on } from "cluster";

const API = {
  PARTNERS_LIST: {
    method: "GET",
    path: "/admin/allinone/partnerList",
    contentType: "application/json",
  },
  PARTNER_LOGIN: {
    method: "GET",
    path: "/admin/allinone/auth",
    contentType: "application/json",
  },
};

const USER_ROW_CNT = 30;

const MESSAGE = `안녕하세요 #{name} 약사님,\n약문약답 서비스 운영진입니다.`;
type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onDone: (user: BizUserInfo) => void;
    showPartners?: boolean;
    onClose?: () => void;
    noActions?: boolean;
  };

type State = {
  search: string;
  partners: BizUserInfo[];
  showPartners: boolean;
  activeFirst: boolean;
  finished: boolean;
};

class AllInOnePartnerSelect extends Component<Props, State> {
  loading = false;
  userList = null;

  state = {
    search: "",
    partners: [],
    showPartners: this.props.showPartners !== false && true,
    activeFirst: true,
    finished: false,
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  onKeyPress = (e) => {
    log(
      LogLevel.UI_ACTION,
      "AllInOnePartnerSelect:onKeyPress",
      e.keyCode,
      e.shiftKey
    );
    if (e.keyCode == 13) {
      this.fetchData();
      e.preventDefault();
    }
  };

  onNew = () => {
    this.props.onDone({ ...PARTNERS_TEMPLATE });
  };

  onSearchChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let keywords = e.detail.value;
    this.setState({ search: keywords });
  };

  refresh = () => {
    this.fetchData();
  };

  onShowingPartnersClick = () => {
    log(
      LogLevel.UI_DATA_LOAD,
      "Admin:AllInOnePartnerSelect:onShowingPartnersClick"
    );
    this.setState({ showPartners: !this.state.showPartners });
    setTimeout(() => {
      this.fetchData();
    }, 50);
  };

  fetchData = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:AllInOnePartnerSelect:fetchData");
    this.loading = true;
    fetchAPI(
      API.PARTNERS_LIST,
      "",
      {
        start: 0,
        count: USER_ROW_CNT,
        search: this.state.search,
        isFull: true,
        isPartners: this.state.showPartners,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      if (result && !result.error) {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:AllInOnePartnerSelect:fetchData result",
          result
        );
        let finished = false;
        if (result.data && result.data.length < USER_ROW_CNT) finished = true;

        let companylist = result.data;
        if (
          companylist &&
          companylist.length > 0 &&
          this.state.showPartners &&
          this.state.activeFirst
        ) {
          companylist = companylist.sort((a: BizUserInfo, b: BizUserInfo) => {
            const dateA = new Date(a.expireAt);
            const dateB = new Date(b.expireAt);
            const now = new Date();

            if (dateA > now && dateB <= now) {
              return -1;
            } else if (dateB > now && dateA <= now) {
              return 1;
            } else {
              return dateB.getTime() - dateA.getTime();
            }
          });
        }
        this.setState({ partners: companylist, finished });
        this.loading = false;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:AllInOnePartnerSelect:fetchData result",
          result
        );
        this.setState({ partners: [], finished: true });
      }
    });
  };

  fetchDataMore = async () => {
    log(
      LogLevel.UI_DATA_LOAD,
      "Admin:AllInOnePartnerSelect:fetchDataMore",
      this.state.finished
    );
    this.loading = true;
    if (!this.state.finished) {
      fetchAPI(
        API.PARTNERS_LIST,
        "",
        {
          start: this.state.partners.length,
          count: USER_ROW_CNT,
          search: this.state.search,
          isFull: true,
          isPartners: this.state.showPartners,
        },
        null,
        getGlobal(GlobalKey.TOKEN)
      ).then((result) => {
        if (result && !result.error) {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:AllInOnePartnerSelect:fetchData result",
            result
          );

          let finished = false;
          if (result.data && result.data.length < USER_ROW_CNT) finished = true;
          let companylist = [...this.state.partners, ...result.data];
          if (
            companylist &&
            companylist.length > 0 &&
            this.state.showPartners &&
            this.state.activeFirst
          ) {
            companylist = companylist.sort((a: BizUserInfo, b: BizUserInfo) => {
              const dateA = new Date(a.expireAt);
              const dateB = new Date(b.expireAt);
              const now = new Date();

              if (dateA > now && dateB <= now) {
                return -1;
              } else if (dateB > now && dateA <= now) {
                return 1;
              } else {
                return dateB.getTime() - dateA.getTime();
              }
            });
          }
          this.setState({ partners: companylist, finished });
          this.loading = false;
        } else {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:AllInOnePartnerSelect:fetchData result",
            result
          );
          this.setState({ finished: true });
          this.loading = false;
        }
      });
    }
  };

  clearSearch = () => {
    this.setState({ search: "" });
  };

  changeAlive = () => {
    // this.setState({ activeOnly: !this.state.activeOnly });
    setTimeout(() => {
      this.fetchData();
    }, 100);
  };

  onSelect = (user: BizUserInfo) => {
    log(LogLevel.UI_EVENT, "Admin:AllInOnePartnerSelect:onSelect", user);
    this.props.onDone(user);
  };

  onLogin = (user: BizUserInfo) => {
    log(LogLevel.UI_EVENT, "Admin:AllInOnePartnerSelect:onLogin", user);
    this.props.confirmPopup.show({
      title: (
        <div className="common-container">
          <span className="common-bold">
            <span className="common-highlight">{user.companyName}</span>{" "}
            계정으로 로그인 하시겠습니까??
          </span>
        </div>
      ),
      body: (
        <div className="common-container">
          다시 돌아오려면 반드시 재로그인을 해야합니다.
        </div>
      ),
      onDone: () => {
        fetchAPI(
          API.PARTNER_LOGIN,
          "",
          { id: user.id },
          null,
          getGlobal(GlobalKey.TOKEN)
        ).then((result) => {
          if (result && !result.error && result.data && result.data.token) {
            setGlobal(GlobalKey.TOKEN, result.data.token, true);
            window.open(location.origin, "_blank");
          }
        });
      },
      doneText: "네",
    });
  };

  onChange = () => {
    this.fetchData();
  };

  download = (filename, text) => {
    var pom = document.createElement("a");
    pom.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(text)
    );
    pom.setAttribute("download", filename);

    if (document.createEvent) {
      var event = document.createEvent("MouseEvents");
      event.initEvent("click", true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }
  };

  endAtStyle = (endAt: string) => {
    if (!this.state.showPartners) return {};
    const endDate = new Date(endAt);
    const currentDate = new Date();

    const diffInTime = endDate.getTime() - currentDate.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    log(
      LogLevel.ALL,
      "AllInOneOrderList:diffInDays",
      diffInDays,
      endAt,
      currentDate,
      endDate,
      diffInTime
    );

    if (diffInDays < 0) {
      return {
        backgroundColor: COLOR_SYSTEM.get("Red")[50],
      };
    }
    return {};
  };

  onDownload = () => {
    this.props.confirmPopup.show({
      iconImage: null,
      title: (
        <div className="common-container">
          <span className="common-bold">
            회원정보 다운로드에{" "}
            <span className="common-bold common-color-caution">
              주의를 기울이십시오.
            </span>
          </span>
        </div>
      ),
      body: (
        <div className="common-container">
          회원정보 다운로드 받는 기록이 서버에 남습니다.
          <br />
          사용된 정보는 반드시 삭제하시고, <br /> 파일의 보안에 철저히
          신경쓰세요.
        </div>
      ),
      onDone: () => {
        // fetchAPI(
        //   API.PARTNERS_LIST_DOWNLOAD,
        //   "",
        //   { start: 0, query: 0, level: this.state.level, search: this.state.search },
        //   null,
        //   getGlobal(GlobalKey.TOKEN)
        // ).then((result) => {
        //   log(LogLevel.UI_DATA_LOAD, "Admin:UserManage", result);
        //   if (result)
        //     this.download(
        //       `Users-${UserLevelName[this.state.level]}-${this.state.search}-${new Date()
        //         .toISOString()
        //         .substring(0, 10)
        //         .replace(/-/gi, "")}.csv`,
        //       result
        //     );
        // });
      },
      doneText: "네",
    });
  };

  render() {
    log(
      LogLevel.UI_DATA_LOAD,
      "Admin:AllInOnePartnerSelect:render",
      this.state.partners
    );
    return (
      <div className="admin-container">
        {this.props.onClose && (
          <IonHeader class="common-header" no-border>
            <IonToolbar color="common-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  class="common-header-toolbar-button"
                  onClick={this.props.onClose}
                >
                  <IonIcon name="arrow-back" mode="ios" />
                </IonButton>
                <IonLabel
                  class="common-header-title"
                  onClick={this.props.onClose}
                >
                  법인회원 선택
                </IonLabel>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}
        <div className="admin-item-container">
          <IonInput
            class="admin-input"
            placeholder="검색"
            value={this.state.search}
            onIonChange={this.onSearchChange}
            onKeyDown={this.onKeyPress}
          />
          <IonButton fill="clear" color="primary" onClick={this.clearSearch}>
            <IonIcon name="close" />
          </IonButton>
        </div>
        <Flex>
          <ToggleSwitch
            checked={this.state.showPartners}
            onClick={() => this.onShowingPartnersClick()}
          >
            <IonIcon size="large" slot="icon" icon="moon" />
          </ToggleSwitch>
          {this.state.showPartners ? "파트너" : "법인구인구직"}
          {this.state.showPartners && (
            <Flex customStyle={{ marginLeft: "8px" }}>
              <ToggleSwitch
                checked={this.state.activeFirst}
                onClick={() =>
                  this.setState({ activeFirst: !this.state.activeFirst })
                }
              >
                <IonIcon size="large" slot="icon" icon="moon" />
              </ToggleSwitch>
              활성사용자 우선
            </Flex>
          )}
        </Flex>
        {!this.props.noActions && (
          <div className="admin-item-container">
            <IonButton color="primary" onClick={this.onNew}>
              신규
            </IonButton>
            <IonButton color="primary" onClick={this.onDownload}>
              다운로드
            </IonButton>
            {/* <div
            className="common-flex-row common-flex-align-center"
            onClick={this.changeAlive}
          >
            <div>활성화only</div>
            <ToggleSwitch checked={this.state.activeOnly} />
          </div> */}
          </div>
        )}

        <div className="common-container">
          <IonList
            ref={(ref) => {
              this.userList = ref;
            }}
          >
            {!this.state.finished && (
              <Button
                size={"Large"}
                type={"Text"}
                variant={"Contained"}
                color={"Primary"}
                style={{ width: "100%" }}
                onClick={this.fetchDataMore}
              >
                더 불러오기
              </Button>
            )}
            {this.state.partners.map((item: BizUserInfo, index: number) => {
              let route = [];

              if (
                !item.id.includes(this.state.search) &&
                !item.companyName.includes(this.state.search) &&
                !item.companyProfileName.includes(this.state.search) &&
                !item.userName.includes(this.state.search) &&
                !item.phone.includes(this.state.search) &&
                !item.email.includes(this.state.search) &&
                !CompanyTypeName[item.companyType].includes(this.state.search)
              )
                return null;
              return (
                <div
                  key={index.toString()}
                  className="common-container-row common-flex-align-center"
                  onClick={() => this.onSelect(item)}
                >
                  <ProfileImage
                    className="user-admin-list-item-profile"
                    profileUrl={item.logoImageUrl}
                  />
                  <div className="user-admin-list-item-container common-flex-grow">
                    <div className="common-flex-row common-flex-align-center">
                      {route}
                    </div>
                    <div>
                      <table style={this.endAtStyle(item.expireAt)}>
                        <tbody>
                          <tr>
                            <td>ID : </td>
                            <td>{item.id}</td>
                          </tr>

                          <tr>
                            <td>회사이름/프로필이름 :</td>
                            <td>
                              {item.companyName} / {item.companyProfileName}
                            </td>
                          </tr>

                          <tr>
                            <td>담당자/이메일/전화번호:</td>
                            <td>
                              {item.userName} / {item.phone} / {item.email}
                            </td>
                          </tr>

                          <tr>
                            <td>카테고리 :</td>
                            <td>{CompanyTypeName[item.companyType]}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              );
            })}
            {!this.state.finished && (
              <Button
                size={"Large"}
                type={"Text"}
                variant={"Contained"}
                color={"Primary"}
                style={{ width: "100%" }}
                onClick={this.fetchDataMore}
              >
                더 불러오기
              </Button>
            )}
          </IonList>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  // filePath: state.board.filePath,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AllInOnePartnerSelect);
