import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";

import { setGlobal, GlobalKey, getGlobal } from "../utils/GlobalUtil";
import { BoardType } from "../store/board/types";
import {
  UserLevel,
  UserWorkType,
  UserInfo,
  UserWorkTypeName,
  UserLevelName,
  AllInOnePartnerInfo,
} from "../models/Model.User";
import { fetchAPI } from "../utils/APIUtil";
import AllInOnePartnerManageProfile from "./component/allinone/AllInOnePartnerManageProfile";
import ProfileImage from "../components/ProfileImage";
import { threadId } from "worker_threads";
import AllInOnePartnerSelect from "./component/allinone/AllInOnePartnerSelect";
import AllInOneOrderList from "./component/allinone/AllInOneOrderList";
import { BizUserInfo, ORDER_TEMPLATE, OrderInfo } from "../models/Mode.Biz";
import AllInOneOrderEdit from "./component/allinone/AllInOneOrderEdit";
import ToggleSwitch from "../component/atom/ToggleSwitch";

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  selectedPartner: BizUserInfo;
  selectedOrder: OrderInfo;
  showSelectPartner: boolean;
  showPartners: boolean;
};

class AllInOnePartnerManage extends Component<Props, State> {
  partnerList = null;
  orderEdit = null;
  orderList = null;

  state = {
    selectedPartner: null,
    selectedOrder: null,
    showSelectPartner: false,
    showPartners: true,
  };

  constructor(props: Props) {
    super(props);
    this.props.loadFilePath();
    document.title = "법인회원구매내역관리";
  }

  componentDidMount() {}

  onShowingPartnersClick = () => {
    log(LogLevel.UI_EVENT, "onCick", this.state.showPartners);
    this.setState({ showPartners: !this.state.showPartners });
  };

  onSelectPartner = (partner: BizUserInfo) => {
    log(LogLevel.UI_EVENT, "onSelect", partner);
    if (this.orderEdit) this.orderEdit.onSelectPartner(partner);
    this.setState({ selectedPartner: partner });
  };

  onSelectOrder = (order: OrderInfo) => {
    log(LogLevel.UI_EVENT, "onSelectOrder", order);
    this.setState({ selectedOrder: order });
  };

  onChangePartner = () => {
    log(LogLevel.UI_EVENT, "onDone", this.state.selectedPartner);
    if (this.partnerList) this.partnerList.refresh();
    this.setState({ selectedPartner: null });
  };

  onCancel = () => {
    log(LogLevel.UI_EVENT, "onDone", this.state.selectedPartner);
    this.setState({ selectedPartner: null });
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "AllInOnePartnerManage.render", this.props);

    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonText slot="middle">법인회원구매내역관리</IonText>
          <IonButtons slot="end">
            <IonButton
              onClick={() =>
                this.setState({
                  showSelectPartner: !this.state.showSelectPartner,
                })
              }
            >
              파트너선택
            </IonButton>
            <IonButton
              onClick={() =>
                this.setState({
                  selectedOrder: { ...ORDER_TEMPLATE },
                })
              }
            >
              신규결제등록
            </IonButton>
          </IonButtons>
          <div className="admin-header-title">
            <div> | </div>
            <ToggleSwitch
              checked={this.state.showPartners}
              onClick={() => this.onShowingPartnersClick()}
            >
              <IonIcon size="large" slot="icon" icon="moon" />
            </ToggleSwitch>
            <IonText slot="children">
              {this.state.showPartners ? "파트너" : "법인구인구직"}
            </IonText>
          </div>
        </IonHeader>
        <IonContent>
          <div className="admin-main-container">
            <div className="admin-container">
              <AllInOneOrderList
                onSelect={this.onSelectOrder}
                showingPartners={this.state.showPartners}
                ref={(ref) => {
                  this.orderList = ref;
                }}
              />
            </div>
            {this.state.selectedOrder && (
              <div className="admin-container">
                <AllInOneOrderEdit
                  ref={(ref) => {
                    this.orderEdit = ref;
                  }}
                  onCancel={() => {
                    this.setState({ selectedOrder: null });
                  }}
                  showPartners={() => {
                    this.setState({ showSelectPartner: true });
                  }}
                  order={this.state.selectedOrder}
                  onDone={() => {
                    log(
                      LogLevel.UI_LIFECYCLE,
                      "AllInOnePartnerManage.AllInOneOrderEdit onDone",
                      this.orderList
                    );
                    if (this.orderList) this.orderList.refresh();
                  }}
                />
              </div>
            )}
            {this.state.showSelectPartner && (
              <div className="admin-container">
                <AllInOnePartnerSelect
                  onDone={this.onSelectPartner}
                  ref={(ref) => {
                    this.partnerList = ref;
                  }}
                  onClose={() => {
                    this.setState({ showSelectPartner: false });
                  }}
                />
              </div>
            )}
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

const mapDispatchToProps = {
  //   refreshBoards: () => actions.board.refreshBoards(),
  loadFilePath: () => actions.board.getFilePath(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllInOnePartnerManage)
);
