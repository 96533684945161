import React, { Component } from 'react';
import './RegisterStage12.css';
import './RegisterStage2.css';
import './RegisterStage.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonInput, IonIcon, IonContent } from '@ionic/react';
import DynamicChecker, {CheckStatus} from './../DynamicChecker'
import {fetchAPI} from './../../utils/APIUtil'
import * as API from './../../API.json';;
import {UserInfo} from '../../models/Model.User'
import {getGlobal, GlobalKey} from './../../utils/GlobalUtil'
import {log, LogLevel} from '../../utils/LogUtil'
import { timerStart, TimerId } from '../../utils/TimeUtil';

type Props = {
  private:boolean,
  user: any
  onDone: (data: any) => void;
  onAddressSearch: (data: any) => void;
  onBack: (data: any) => void;
};

type State = {
  emailCheckStatus: number;
  licenseCheckStatus: number;
  workPlaceCheckStatus: number;
  email: string;
  workPlace: string;
  workPlaceId: number;
  workType: number;
  license: string;
  licenseImage: any;
  licenseImageBase64: any;
  licenseImageRemoteUrl: string;
  toastMessage: string;
}

const windowAny: any = window;

class RegisterStage12 extends Component<Props, State> {
  contentScroll = null;

  constructor(props){
    super(props);
    this.state={
      emailCheckStatus: CheckStatus.UNCHECKED,
      licenseCheckStatus: CheckStatus.UNCHECKED,
      workPlaceCheckStatus: this.props.user.workPlaceAddress?CheckStatus.CHECKED:CheckStatus.UNCHECKED,
      email: this.props.user.email,
      workPlace: this.props.user.workPlaceAddress,
      workPlaceId: this.props.user.workPlaceId,
      workType: this.props.user.workType?this.props.user.workType:9,
      license: this.props.user.license,
      licenseImage: this.props.user.licenseLocalImage,
      licenseImageRemoteUrl: this.props.user.licenseImageRemoteUrl,
      licenseImageBase64: null,
      toastMessage:'',
    }
    this.canPass = this.canPass.bind(this);

    if(this.state.email)
      this.validateEmail();
    if(this.state.license)
      this.validateLicense();

    if (this.state.licenseImage) {
      var reader = new FileReader();
      reader.onload = this.onImageLoad;
      reader.readAsDataURL(this.state.licenseImage);
    }
  }

  canPass() {
    return (this.state.emailCheckStatus == 2 && this.state.licenseCheckStatus == 2 && this.state.workType && this.state.workPlaceCheckStatus ==2);
  }

  onDone = () => {
    if(this.state.emailCheckStatus != CheckStatus.CHECKED){
      this.setState({toastMessage: "이메일을 확인해주세요."});
      return;
    }
    if(this.state.workPlaceCheckStatus != CheckStatus.CHECKED){
      this.setState({toastMessage: "학교정보를 확인해주세요."});
      return;
    }
    if(this.state.workType == 0){
      this.setState({toastMessage: "학생타입을 확인해주세요."});
      return;
    }
    if(this.state.licenseCheckStatus != CheckStatus.CHECKED){
      this.setState({toastMessage: "학번을 확인해주세요."});
      return;
    }
    if(!this.state.licenseImage){
      this.setState({toastMessage: "학생증사진을 추가하셔야합니다."});
      return;
    }

    let rvalue = {
      email: this.state.email,
      workPlace: this.state.workPlace,
      workPlaceId: this.state.workPlaceId,
      workType: this.state.workType,
      license: this.state.license,
      licenseImage: this.state.licenseImage,
    }
    this.props.onDone(rvalue);
  }

  onBack = () => {
    let rvalue = {
      email: this.state.email,
      workPlace: this.state.workPlace,
      workPlaceId: this.state.workPlaceId,
      workType: this.state.workType,
      license: this.state.license,
      licenseImage: this.state.licenseImage,
    }
    this.props.onBack(rvalue);
  }

  validateEmail = () => {
    let body = {
      value:this.state.email,
    };
    fetchAPI(API.USER_FIELD_VALIDATE_OPEN, 'email',null, body)
      .then(result => {
        if(result && !result.error && result.data.value == this.state.email){
          this.setState({emailCheckStatus:CheckStatus.CHECKED});
        }else if(result && result.error && result.data.value == this.state.email){
          this.setState({emailCheckStatus:CheckStatus.WARNING})
        }else{
          log(LogLevel.NONE, result);
        }
      }).catch(e => {
        log(LogLevel.NONE, e);
      });
  }

  onEmailChange = (e: CustomEvent) => {
    // log(LogLevel.NONE, e);
    this.setState({email: e.detail.value, emailCheckStatus:1});
    this.validateEmail();
  }

  onClickWorkPlace  = (e) => {
    log(LogLevel.NONE, e);
    let option = "manual";
    timerStart(TimerId.TIMER_SUB);
    let rvalue = {
      email: this.state.email,
      workType: this.state.workType,
      license: this.state.license,
      licenseImageBase64: this.state.licenseImageBase64,
    }
    this.props.onAddressSearch(rvalue)
  }

  onWorkPlaceChange = (e: CustomEvent) => {
    log(LogLevel.NONE, e);
    // this.setState({email: e.detail.value});
  }

  onPressType = (type: number) => {
    this.setState({workType: type});
  }

  validateLicense = () => {
    if(this.state.license)
      this.setState({licenseCheckStatus:CheckStatus.CHECKED});
    else 
      this.setState({licenseCheckStatus:CheckStatus.UNCHECKED})
  }

  onLicenseChange = (e: CustomEvent) => {
    log(LogLevel.NONE, e);
    this.setState({license: e.detail.value});
    this.validateLicense();
  }

  onPharmacySelected = (id:number, address:string) => {
    log(LogLevel.NONE, id, address);
    this.setState({workPlaceId:id, workPlace:address, workPlaceCheckStatus:2});

  }

  onClickCamera = () => {
    log(LogLevel.UI_ACTION,"RegisterStage12:onClickCamera");
    let os = getGlobal(GlobalKey.OS);
    if(!os || os == "browser") return;

    if(windowAny.navigator && windowAny.navigator.camera && windowAny.navigator.camera.getPicture){
      windowAny.navigator.camera.getPicture(this.onCameraSuccess, this.onCameraFail, { quality: 80,
        destinationType: windowAny.Camera.DestinationType.DATA_URL,
        encodingType: windowAny.Camera.EncodingType.JPEG,
        mediaType: windowAny.Camera.MediaType.PICTURE,
        correctOrientation: true,
        targetWidth: 1920,
        targetHeight: 1920,
      });
    }
  }

  onCameraSuccess = (imageURI) => {
    this.setState({licenseImageBase64:"data:image/jpeg;base64," + imageURI});
    setTimeout(() => {
      this.contentScroll.scrollToPoint(null, this.contentScroll.clientHeight);
    }, 300);

  }


  onCameraFail = (message) => {
    log(LogLevel.UI_EXCEPTION, 'RegisterStage12::onCameraFail Failed because: ', message);
  }

  onImageSelected = (e) => {
    // console.dir(e);
    console.dir(e.target.files[0]);
    console.dir(e.target);
    if (e.target.files && e.target.files[0]) {
      var reader = new FileReader();
      reader.onload = this.onImageLoad;
      reader.readAsDataURL(e.target.files[0]);
      this.setState({licenseImage: e.target.files[0] });
      // e.target.files.clear();
    }
  }

  onImageLoad = (e) => {
    this.setState({licenseImageBase64:e.target.result});
    setTimeout(() => {
      this.contentScroll.scrollToPoint(null, this.contentScroll.clientHeight);
    }, 300);
  }

  render() {
    let attachmenet;
    let os = getGlobal(GlobalKey.OS)
    if(os && os != "browser")
    {
      attachmenet = (
        <div className="register2-upload-button-container">
          <IonButton class="register2-upload-button" fill="clear" onClick={this.onClickCamera}>
            <IonIcon class="register2-upload-button-icon" name="camera"/>
            <div className="register2-upload-button-text">촬영하기</div>
          </IonButton>
        </div>
      );
    }

    let attachmentViewer;
    if(this.state.licenseImageBase64){
      attachmentViewer = (
        <div className="register2-upload-viewer-container">
          <img className="register2-upload-viewer" src={this.state.licenseImageBase64}/>
        </div>
      )
    }

    return (
      <IonContent class="register-container" ref={(ref)=>{this.contentScroll=ref}}>

      <div className="register2-container">
        {/* <RegisterAddressPopup ref={ref => {this.popupLocationInput = ref;}} onSelected={this.onPharmacySelected}/> */}
        <div className="register-progress-container">
          <div className="register-progress-done">1</div>
          <div className="register-progress-done">2</div>
          <div className="register-progress-undone">3</div>
          <div className="register-progress-undone">4</div>
        </div>
        <div className="register2-service-description">
          회원정보는 약사인증, 맞춤형 정보제공을 위한 정보이며, 타회원에게 공개되지 않습니다.
        </div>
        <div className="register2-body-container">
          <div className="register-title">
            회원정보
          </div>
          <div className="register2-item-container">
            <div className="register2-item-name">이름</div>
            <div className="register2-item-value-diabled">{this.props.user.name}</div>
          </div>
          <div className="register2-item-container">
            <div className="register2-item-name">연락처</div>
            <div className="register2-item-value-diabled">{this.props.user.phone}</div>
          </div>
          <div className="register2-item-container">
            <div className="register2-item-name">이메일</div>
            <IonInput class="register2-item-input" value={this.state.email} onIonChange={this.onEmailChange}/>
            <DynamicChecker status={this.state.emailCheckStatus}/>
          </div>
        </div>
        <div className="register2-body-container">
          <div className="register-title">
            학교정보
          </div>
          <div className="register2-title-description">
          학교정보는 <span className="register2-title-description-highlight">맞춤형 정보제공의 구분정보</span>로 사용됩니다. 
          </div>          
          <div className="register2-item-container">
            <div className="register2-item-name">학교명</div>
            <IonInput class="register2-item-input" value={this.state.workPlace} disabled onClick={this.onClickWorkPlace} onIonChange={this.onWorkPlaceChange}/>
            <DynamicChecker status={this.state.workPlaceCheckStatus}/>

          </div>
          <div className="register12-work-type-container">
            <div className={(this.state.workType==9)?"register12-work-type-selected":"register12-work-type-unselected"} onClick={() => this.onPressType(9)}>약대 재학</div>
            <div className={(this.state.workType==10)?"register12-work-type-selected":"register12-work-type-unselected"} onClick={() => this.onPressType(10)}>대학원 재학</div>
          </div>
          
          <div className="register-title">
            학생정보
          </div>
          <div className="register2-item-container">
            <div className="register2-item-name">학번</div>
            <IonInput class="register2-item-input" value={this.state.license} onIonChange={this.onLicenseChange}/>
            <DynamicChecker status={this.state.licenseCheckStatus}/>

          </div>
          <div className="register2-title-description">
            학번은 도용방지를 위해 서버에 보관되며 <span className="register2-title-description-highlight">암호화</span>되어 식별이 불가합니다. 
          </div>   
          <div className="register2-item-container">
            <div className="register2-item-name">학생증 사진</div>
          </div>
          <div className="register2-title-description">
            촬영된 학생증은 <span className="register2-title-description-highlight">가입승인 즉시 폐기</span>됩니다. 
          </div>
          {attachmentViewer}
          <div className="register2-upload-container">
            {attachmenet}
            <div className="register2-upload-button-container">
              <IonButton class="register2-upload-button" fill="clear">
                <IonIcon class="register2-upload-button-icon" name="image"/>
                <div className="register2-upload-button-text">첨부하기</div>
              </IonButton>
              <input className="register2-upload-file" type="file" accept="image/*" onChange={this.onImageSelected}/>
            </div>
          </div>
        </div>
        <div className="register-navigate-container">

          <IonButton color="register-navigate-inactive" onClick={this.onBack}>
            &lt; 이전 단계로
          </IonButton>
          <div className="register-navigate-space"/>
          <IonButton color={this.canPass()?"register-navigate-active":"register-navigate-inactive"}  onClick={this.onDone}>
            다음 단계로 &gt;
          </IonButton>
        </div>   
      </div>
      </IonContent>
    );
  }
}

export default RegisterStage12;