import { getDateStringFromToday } from "../utils/TimeUtil";
import {
  AllInOnePartnerLevel,
  CompanyType,
  UserInfo,
  UserType,
} from "./Model.User";

export interface CouponInfo {
  //   @ApiProperty({
  //     default: "1",
  //     required: false,
  //     type: "string",
  //     name: "id",
  //     description: "id입니다",
  //   })
  //   @IsOptional()
  id?: number; // [공용] 구인공고 ID

  //   @ApiProperty({
  //     default: 1,
  //     required: false,
  //     type: "string",
  //     name: "bizUserId",
  //     description:
  //       "코그니토에서 제공하는 ID입니다. (예 :80d6b053-27b3-4a0c-b601-5bf50e9bea7b) ",
  //   })
  //   @IsOptional()
  bizUserId?: string;
  userId?: number;
  //   @ApiProperty({
  //     default: "WELCOME.30",
  //     required: false,
  //     type: "string",
  //     name: "name",
  //     description: "쿠폰이름",
  //   })
  //   @IsOptional()
  name?: string;

  //   @ApiProperty({
  //     default: "회원가입 기념 쿠폰 30만원",
  //     required: false,
  //     type: "string",
  //     name: "detail",
  //     description: "쿠폰상세",
  //   })
  //   @IsOptional()
  detail?: string;

  //   @ApiProperty({
  //     default: "2022-12-31",
  //     required: false,
  //     type: "string",
  //     name: "expireAt",
  //     description: "상품 끝나는 날짜",
  //   })
  //   @IsOptional()
  expireAt?: string;

  //   @ApiProperty({
  //     default: 300000,
  //     required: false,
  //     type: "number",
  //     name: "priceDiscount",
  //     description: "상품 할인할 금액",
  //   })
  //   @IsOptional()
  priceDiscount?: number;

  //   @ApiProperty({
  //     default: "",
  //     required: false,
  //     type: "string",
  //     name: "usedAt",
  //     description: "쿠폰 사용 시점",
  //   })
  //   @IsOptional()
  usedAt?: string;

  //   @ApiProperty({
  //     default: "2022-11-03",
  //     required: false,
  //     type: "string",
  //     name: "createdAt",
  //     description: "지급일",
  //   })
  //   @IsOptional()
  createdAt?: string;

  etc?: any;
  etcJson?: string;

  //////////////// 아직 미구현//////////////

  //   @ApiProperty({
  //     default: 300000,
  //     required: false,
  //     type: "number",
  //     name: "priceDiscountLimit",
  //     description: "상품 가격",
  //   })
  //   @IsOptional()
  priceDiscountLimit?: number;

  //   @ApiProperty({
  //     default: "0",
  //     required: false,
  //     type: "string",
  //     name: "conditionMin",
  //     description: "????",
  //   })
  //   @IsOptional()
  conditionMin?: string;

  //   @ApiProperty({
  //     default: "0",
  //     required: false,
  //     type: "",
  //     name: "productType",
  //     description: "상품타입 (광고=0, 알림톡=1 , =2) ",
  //   })
  //   @IsOptional()
  productType?: string;
}

export const EMPTY_COUPON: CouponInfo = {
  id: 0, // [공용] 구인공고 ID
  bizUserId: "",
  userId: 0,
  name: "",
  detail: "",
  expireAt: "",
  priceDiscount: 300000,
  usedAt: "",
  priceDiscountLimit: 0,
  conditionMin: "",
  productType: "",
};

export const CASH_COUPON = {
  name: "CASH_COUPON",
  detail: "법인구인구직 이용권",
  expireAt: "2026-1-1",
  priceDiscount: 300000,
};

export const FREE_COUPON = {
  name: "FREE_COUPON",
  detail: "구인구직 특별이용 쿠폰",
  expireAt: "2026-1-1",
  priceDiscount: 300000,
};

export const KAKAO_100_COUPON = {
  name: "FREE_COUPON",
  detail: "구인구직 알림톡 특별이용 쿠폰",
  expireAt: "2026-1-1",
  priceDiscount: 10000,
};

export const MOU_COUPON_30 = {
  name: "MOU_COUPON_30",
  detail: "지역약사회 MOU기념 쿠폰 (1/2)",
  expireAt: "2026-1-1",
  priceDiscount: 300000,
};

export const MOU_COUPON_60 = {
  name: "MOU_COUPON_60",
  detail: "지역약사회 MOU기념 쿠폰",
  expireAt: "2026-1-1",
  priceDiscount: 600000,
};

export const CONFERENCE_COUPON_3 = {
  name: "CONFERENCE_COUPON_3",
  detail: "이벤트 쿠폰",
  expireAt: "2034-1-1",
  priceDiscount: 30000,
};

export interface BizUserInfo {
  id?: string;
  userName?: string;
  email?: string;
  userType?: UserType;
  level?: number;
  phone?: string;
  division?: string;
  companyName?: string;
  companyProfileName?: string;
  companyRegiDoc?: string;
  companyRegiNum?: string;
  companyAddress?: string;
  companyAddressDetail?: string;
  companyRegion?: string;
  companyPosition?: any;
  companyType?: CompanyType;
  employeeCnt?: number;
  homepage?: string;
  logoImageUrl?: string;
  introductionHTML?: string;
  introductionText?: string;
  createdAt?: string;
  deletedAt?: string;
  expireAt?: string;

  phoneNumbers?: string;
  companyPhone?: string; // [공용] 구인공고 ID
  registerGrantedAt?: string;
}

export class OrderDetail {
  //결제에 대한 상세정보
  id?: string;
  bizOrderId?: number;
  type?: number; // 0 : 기간, 1: 1회성, 2: 쿠폰, 3: 세금
  code?: string;
  title?: string;
  startAt?: string;
  endAt?: string;
  price?: number;
  couponId?: number;
  payStatus?: string;
  contentType?: string;
  contentId?: number;
  count?: number;
}

// OrderDto jobpost DTO 내용이 들어가야함
export class OrderInfo {
  id?: number;
  userId?: number;
  userInfo?: UserInfo;
  bizUserId?: string;
  bizUserInfo?: BizUserInfo;
  contentType?: string;
  contentId?: number;
  productCode?: string;
  productImageUrl?: string;
  productTitle?: string;
  payAmount?: number;
  refundAmount?: number;
  payMerchantId?: string;
  payMethod?: string;
  paidAmount?: string;
  canceledAmount?: number;
  payApplyNumber?: string;
  payRecieptUrl?: string;
  payUid?: string;
  payCardName?: string;
  createdAt?: string;
  paidAt?: string;
  details?: OrderDetail[];
  payStatus?: string;
}

interface Product {
  name: string;
  productCode: {
    basic: {
      productTitle: string;
      expireMonths: number;
      price: number;
    }[];
    pro: {
      productTitle: string;
      expireMonths: number;
      price: number;
    }[];
    premium: {
      productTitle: string;
      expireMonths: number;
      price: number;
    }[];
  };
}

export const PRODUCTS: Product = {
  name: "PARTNERS상품",
  productCode: {
    basic: [
      {
        productTitle: "BASIC(3개월)",
        expireMonths: 3,
        price: 1200000,
      },
      {
        productTitle: "BASIC(12개월)",
        expireMonths: 12,
        price: 4800000,
      },
    ],
    pro: [
      {
        productTitle: "PRO(3개월)",
        expireMonths: 3,
        price: 2100000,
      },
      {
        productTitle: "PRO(12개월)",
        expireMonths: 12,
        price: 8400000,
      },
    ],
    premium: [
      {
        productTitle: "PREMIUM(3개월)",
        expireMonths: 3,
        price: 3300000,
      },
      {
        productTitle: "PREMIUM(3개월)",
        expireMonths: 12,
        price: 13200000,
      },
    ],
  },
};

export const ORDER_PREMIUM3_TEMPLATE: OrderInfo = {
  contentId: 0,
  productCode: "PREMIUM",
  productTitle: "Premium 3개월 이용권",
  payAmount: 3300000 * 1.1,
  refundAmount: 0,
  payMerchantId: "",
  payMethod: "cash",
  paidAmount: (3300000 * 1.1).toString(),
  canceledAmount: 0,
  payApplyNumber: "",
  payRecieptUrl: "",
  payUid: "",
  payCardName: "",
  details: [
    {
      type: 0, // 0 : 기간, 1: 1회성, 2: 쿠폰, 3: 세금
      code: "PREMIUM",
      title: "Premium 3개월 이용권",
      startAt: getDateStringFromToday(),
      endAt: getDateStringFromToday({ months: 3 }),
      price: 3300000 * 1.1,
      count: 3,
    },
  ],
};
export const ORDER_PREMIUM12_TEMPLATE: OrderInfo = {
  contentId: 0,
  productCode: "PREMIUM",
  productTitle: "Premium 12개월 이용권",
  payAmount: 13200000 * 1.1,
  refundAmount: 0,
  payMerchantId: "",
  payMethod: "cash",
  paidAmount: (13200000 * 1.1).toString(),
  canceledAmount: 0,
  payApplyNumber: "",
  payRecieptUrl: "",
  payUid: "",
  payCardName: "",
  details: [
    {
      type: 0, // 0 : 기간, 1: 1회성, 2: 쿠폰, 3: 세금
      code: "BASIC",
      title: "Premium 12개월 이용권",
      startAt: getDateStringFromToday(),
      endAt: getDateStringFromToday({ months: 12 }),
      price: 13200000 * 1.1,
      count: 3,
    },
  ],
};

export const ORDER_PRO3_TEMPLATE: OrderInfo = {
  contentId: 0,
  productCode: "PRO",
  productTitle: "Pro 3개월 이용권",
  payAmount: 2100000 * 1.1,
  refundAmount: 0,
  payMerchantId: "",
  payMethod: "cash",
  paidAmount: (2100000 * 1.1).toString(),
  canceledAmount: 0,
  payApplyNumber: "",
  payRecieptUrl: "",
  payUid: "",
  payCardName: "",
  details: [
    {
      type: 0, // 0 : 기간, 1: 1회성, 2: 쿠폰, 3: 세금
      code: "PRO",
      title: "Pro 3개월 이용권",
      startAt: getDateStringFromToday(),
      endAt: getDateStringFromToday({ months: 3 }),
      price: 2100000 * 1.1,
      count: 3,
    },
  ],
};
export const ORDER_PRO12_TEMPLATE: OrderInfo = {
  contentId: 0,
  productCode: "PRO",
  productTitle: "Pro 12개월 이용권",
  payAmount: 8400000 * 1.1,
  refundAmount: 0,
  payMerchantId: "",
  payMethod: "cash",
  paidAmount: (8400000 * 1.1).toString(),
  canceledAmount: 0,
  payApplyNumber: "",
  payRecieptUrl: "",
  payUid: "",
  payCardName: "",
  details: [
    {
      type: 0, // 0 : 기간, 1: 1회성, 2: 쿠폰, 3: 세금
      code: "BASIC",
      title: "Pro 12개월 이용권",
      startAt: getDateStringFromToday(),
      endAt: getDateStringFromToday({ months: 12 }),
      price: 8400000 * 1.1,
      count: 3,
    },
  ],
};

export const ORDER_BASIC3_TEMPLATE: OrderInfo = {
  contentId: 0,
  productCode: "BASIC",
  productTitle: "Basic 3개월 이용권",
  payAmount: 1200000 * 1.1,
  refundAmount: 0,
  payMerchantId: "",
  payMethod: "cash",
  paidAmount: (1200000 * 1.1).toString(),
  canceledAmount: 0,
  payApplyNumber: "",
  payRecieptUrl: "",
  payUid: "",
  payCardName: "",
  details: [
    {
      type: 0, // 0 : 기간, 1: 1회성, 2: 쿠폰, 3: 세금
      code: "BASIC",
      title: "Basic 3개월 이용권",
      startAt: getDateStringFromToday(),
      endAt: getDateStringFromToday({ months: 3 }),
      price: 1200000 * 1.1,
      count: 0,
    },
  ],
};
export const ORDER_BASIC12_TEMPLATE: OrderInfo = {
  contentId: 0,
  productCode: "BASIC",
  productTitle: "Basic 12개월 이용권",
  payAmount: 4800000 * 1.1,
  refundAmount: 0,
  payMerchantId: "",
  payMethod: "cash",
  paidAmount: (4800000 * 1.1).toString(),
  canceledAmount: 0,
  payApplyNumber: "",
  payRecieptUrl: "",
  payUid: "",
  payCardName: "",
  details: [
    {
      type: 0, // 0 : 기간, 1: 1회성, 2: 쿠폰, 3: 세금
      code: "BASIC",
      title: "Basic 12개월 이용권",
      startAt: getDateStringFromToday(),
      endAt: getDateStringFromToday({ months: 12 }),
      price: 4800000 * 1.1,
      count: 0,
    },
  ],
};

export const ORDER_TEMPLATE: OrderInfo = {
  id: 0,
  bizUserId: "",
  contentType: "partners",
  contentId: 0,
  productCode: "",
  productTitle: "",
  payAmount: 0,
  refundAmount: 0,
  payMerchantId: "",
  payMethod: "cash",
  paidAmount: "",
  canceledAmount: 0,
  payApplyNumber: "",
  payRecieptUrl: "",
  payUid: "",
  payCardName: "",
  createdAt: "",
  paidAt: getDateStringFromToday(),
  details: [
    {
      type: 0, // 0 : 기간, 1: 1회성, 2: 쿠폰, 3: 세금
      code: "",
      title: "",
      startAt: getDateStringFromToday(),
      endAt: getDateStringFromToday({ years: 1 }),
      price: 0,
      count: 0,
    },
  ],
  payStatus: "paid",
};
