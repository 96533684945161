import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Absolute, Fixed, Flex, Relative, Static } from "../../atoms/Layout";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";

import styled from "styled-components";
import Button from "../../atoms/Button";
import Separator from "../../atoms/Separator";
import Tag from "../../atoms/Tag";
import Icon from "../../atoms/Icon";
import { DailyQuiz } from "../../../models/Model.Board";
import {
  PremiumType,
  UserPremiumInfo,
} from "../../../models/Model.User.Premium";
import { getDate } from "date-fns";
import {
  getDateStringFromToday,
  convertDateToDays,
} from "../../../utils/TimeUtil";
import CardView from "../../../component/molecule/CardView";
import { log, LogLevel } from "../../../utils/LogUtil";
import { get } from "https";
import { DAILY_QUIZ_HISTORY_CARD_COUNT } from "../../../store/board/types";

export interface DailyQuizHistoryProps {
  premium: PremiumType;
  dailyQuizList: DailyQuiz[];
  onLoad: (options: { startDate: string; endDate: string }) => void;
  onClick: (quiz: DailyQuiz) => void;
  onPremium: () => void;
  days: number;
  setDays: (days: number) => void;
}

const TODAY = getDateStringFromToday({});
const TODAY_DAYS = convertDateToDays(TODAY);
const MIN_DAYS = convertDateToDays("2024-09-01");

const DailyQuizHistory: React.FC<DailyQuizHistoryProps> = ({
  premium,
  dailyQuizList,
  onLoad,
  onClick,
  onPremium,
  days,
  setDays,
}) => {
  const isPremium = useMemo(
    () => premium !== PremiumType.NONE && premium != null,
    [premium]
  );
  useEffect(() => {
    log(
      LogLevel.UI_LIFECYCLE,
      "DailyQuizHistory:useEffect"[days],
      days,
      getDateStringFromToday({ dateDays: days })
    );
    if (dailyQuizList[days] === undefined) {
      onLoad({
        startDate: getDateStringFromToday({ dateDays: days }),
        endDate: getDateStringFromToday({
          dateDays: days + DAILY_QUIZ_HISTORY_CARD_COUNT - 1,
        }),
      });
    }

    if (dailyQuizList[days - DAILY_QUIZ_HISTORY_CARD_COUNT] == undefined) {
      onLoad({
        startDate: getDateStringFromToday({
          dateDays: days - DAILY_QUIZ_HISTORY_CARD_COUNT,
        }),
        endDate: getDateStringFromToday({ dateDays: days - 1 }),
      });
    }
  }, [days]);

  const onPageMove = useCallback(
    (before: boolean) => {
      let newDays: number;
      if (before) {
        newDays = days - DAILY_QUIZ_HISTORY_CARD_COUNT;
        if (newDays < MIN_DAYS) {
          newDays = MIN_DAYS;
        }
      } else {
        newDays = days + DAILY_QUIZ_HISTORY_CARD_COUNT;
        if (newDays >= TODAY_DAYS + 1) {
          newDays = TODAY_DAYS - DAILY_QUIZ_HISTORY_CARD_COUNT + 2;
        }
      }
      log(
        LogLevel.UI_LIFECYCLE,
        "DailyQuizHistory:onPageMove",
        before,
        days,
        TODAY_DAYS,
        newDays,
        getDateStringFromToday({ dateDays: days })
      );
      setDays(newDays);
    },
    [days]
  );

  const onClickHistoryCard = (quiz: DailyQuiz) => {
    if (!isPremium) return;
    onClick(quiz);
  };

  return (
    <Flex
      direction="column"
      customStyle={{
        width: "100%",
        marginTop: "24px",
        padding: "32px 0px",
        gap: "32px",
        backgroundColor: COLOR_SYSTEM.get("Gray")[10],
      }}
      onClick={() => {
        if (!isPremium) {
          onPremium();
        }
      }}
    >
      {!isPremium ? (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          customStyle={{ padding: "0px 20px" }}
        >
          <Text color={COLOR_SYSTEM.get("Gray")[800]} textType="Subhead1">
            후원 회원이 되시면 지난 문제를 <br />
            풀어보실 수 있어요.
          </Text>
          <Button
            size={"Medium"}
            type={"Text"}
            variant={"Contained"}
            color={"Secondary"}
          >
            후원하기
          </Button>
        </Flex>
      ) : (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          customStyle={{ padding: "0px 20px" }}
        >
          <Text color={COLOR_SYSTEM.get("Gray")[800]} textType="Subhead1">
            🚩 지난 문제를 풀어서 연승을 이어가세요.
          </Text>
        </Flex>
      )}
      <Flex
        alignItems="center"
        gap="12px"
        customStyle={{ width: "100%", padding: "0px 0px" }}
      >
        <Button
          size={"Medium"}
          type={"Icon"}
          variant={"Ghost"}
          color={"Quaternary"}
          icon="CaretLeft"
          style={{ minWidth: "24px", marginRight: "-8px" }}
          disabled={days <= MIN_DAYS}
          disabledColor={days <= MIN_DAYS}
          onClick={() => onPageMove(true)}
        />
        {Array.from({ length: DAILY_QUIZ_HISTORY_CARD_COUNT }, (_, i) => {
          const quiz = dailyQuizList[days + i];

          if (!quiz) return null;
          return (
            <DailyQuizHistoryCard
              key={i.toString()}
              onClick={onClickHistoryCard}
              dailyQuiz={quiz}
            />
          );
        })}
        <Button
          size={"Medium"}
          type={"Icon"}
          variant={"Ghost"}
          color={"Quaternary"}
          icon="CaretRight"
          style={{ minWidth: "24px", marginLeft: "-8px" }}
          disabled={days + DAILY_QUIZ_HISTORY_CARD_COUNT >= TODAY_DAYS + 2}
          disabledColor={days + DAILY_QUIZ_HISTORY_CARD_COUNT >= TODAY_DAYS + 2}
          onClick={() => onPageMove(false)}
        />
      </Flex>
    </Flex>
  );
};

interface DailyQuizHistoryCardProps {
  dailyQuiz: DailyQuiz;
  onClick: (quiz: DailyQuiz) => void;
}

const DailyQuizHistoryCard: React.FC<DailyQuizHistoryCardProps> = ({
  dailyQuiz,
  onClick,
}) => {
  let date = getDateStringFromToday({
    date: dailyQuiz.date,
    withoutYear: true,
    separator: "/",
    trim: true,
  });

  log(LogLevel.UI_LIFECYCLE, "DailyQuizHistoryCard:render", date, dailyQuiz);

  if (dailyQuiz.id > TODAY_DAYS) {
    // 미래의 문제 클릭 안됨
    return (
      <Flex
        direction="column"
        alignItems="center"
        gap="8px"
        justifyContent="center"
        customStyle={{
          flex: 1,
          height: "60px",
          borderRadius: "8px",
          border: `1px dashed ${COLOR_SYSTEM.get("Gray")[100]}`,
        }}
      >
        <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[100]}>
          {date}
        </Text>
        <Flex
          gap="6px"
          alignItem="center"
          justifyContent="space-between"
          customStyle={{
            height: "8px",
          }}
        >
          <div
            style={{
              width: "2px",
              height: "2px",
              background: COLOR_SYSTEM.get("Gray")[200],
              borderRadius: "100px",
            }}
          />
          <div
            style={{
              width: "2px",
              height: "2px",
              background: COLOR_SYSTEM.get("Gray")[200],
              borderRadius: "100px",
            }}
          />
          <div
            style={{
              width: "2px",
              height: "2px",
              background: COLOR_SYSTEM.get("Gray")[200],
              borderRadius: "100px",
            }}
          />
        </Flex>
      </Flex>
    );
  } else if (dailyQuiz.score > 0) {
    // 풀었던 문제
    return (
      <Flex
        direction="column"
        alignItems="center"
        gap="8px"
        justifyContent="center"
        customStyle={{
          flex: 1,
          height: "60px",
          backgroundColor: COLOR_SYSTEM.get("Green")[400],
          borderRadius: "8px",
        }}
        onClick={() => onClick(dailyQuiz)}
      >
        <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[0]}>
          {date}
        </Text>
        <Flex justifyContent="space-between">
          <Icon
            name="Star"
            size={8}
            color={
              dailyQuiz.score > 0
                ? COLOR_SYSTEM.get("Yellow")[300]
                : COLOR_SYSTEM.get("Green")[600]
            }
          />
          <Icon
            name="Star"
            size={8}
            color={
              dailyQuiz.score > 1
                ? COLOR_SYSTEM.get("Yellow")[300]
                : COLOR_SYSTEM.get("Green")[600]
            }
          />
          <Icon
            name="Star"
            size={8}
            color={
              dailyQuiz.score > 2
                ? COLOR_SYSTEM.get("Yellow")[300]
                : COLOR_SYSTEM.get("Green")[600]
            }
          />
        </Flex>
      </Flex>
    );
  } else {
    // 풀지 않은 문제
    return (
      <Flex
        direction="column"
        alignItems="center"
        gap="8px"
        justifyContent="center"
        customStyle={{
          flex: 1,
          height: "60px",
          backgroundColor: COLOR_SYSTEM.get("Gray")[50],
          borderRadius: "8px",
        }}
        onClick={() => onClick(dailyQuiz)}
      >
        <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[400]}>
          {date}
        </Text>
        <Flex
          gap="6px"
          alignItem="center"
          justifyContent="space-between"
          customStyle={{ height: "8px" }}
        >
          <div
            style={{
              width: "2px",
              height: "2px",
              background: COLOR_SYSTEM.get("Gray")[200],
              borderRadius: "100px",
            }}
          />
          <div
            style={{
              width: "2px",
              height: "2px",
              background: COLOR_SYSTEM.get("Gray")[200],
              borderRadius: "100px",
            }}
          />
          <div
            style={{
              width: "2px",
              height: "2px",
              background: COLOR_SYSTEM.get("Gray")[200],
              borderRadius: "100px",
            }}
          />
        </Flex>
      </Flex>
    );
  }
  return null;
};

export default DailyQuizHistory;
