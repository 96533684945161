import React, { Component } from 'react';
import './../../Common.css'
import './RegisterStage0.css';
import './RegisterStage.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton } from '@ionic/react';
import { RootState } from '../../store';
import { UIPopupType } from '../../store/ui/types';
import { connect } from 'react-redux';
import guideImage from './../../assets/image/registration-guide.png'

const windowAny: any = window;

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  onSelected: (isStudent:boolean) => void;
};

class RegisterStage0 extends Component<Props> {
  onStudent = () => {
    this.props.toast.show("약대생을 위한 서비스는 준비중에 있습니다.")
  }

  render() {
    return (
      <div className="register0-container">
        <div className="register0-service-description-container">
          <div>약문약답은</div>
          <div>안전한 의약품 사용에 기여하는</div>
          <div><span style={{background:"#FFF5B6"}}>멋진 약사님들을 위한 서비스</span>입니다.</div>
          
        </div>
        <div className="register0-image-container"  onClick={()=>this.props.onSelected(false)}>
          <img className="register0-image" src={guideImage}/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  toast : state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(RegisterStage0);