import React, { useEffect } from "react";
import { Absolute, Fixed, Flex, Relative, Static } from "../../atoms/Layout";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";

import styled from "styled-components";
import Button from "../../atoms/Button";
import Separator from "../../atoms/Separator";
import Tag from "../../atoms/Tag";
import Icon from "../../atoms/Icon";
import {
  BoardContentSummaryGroup,
  DailyQuiz,
} from "../../../models/Model.Board";
import Skeleton from "react-loading-skeleton";
import ReactHtmlParser from "react-html-parser";
import { LogLevel, log } from "../../../utils/LogUtil";
import { getSummaryHtml } from "../BoardSummary";
import { PremiumType } from "../../../models/Model.User.Premium";
import DailyQuizHistory from "./History";
import { set } from "lodash";

const QustionWrapper = styled.div<{ index: number }>`
  padding: 16px;
  background: ${COLOR_SYSTEM.get("Gray")[10]};
  border-radius: 12px;
  transform: translateZ(0);
  overflow: show;

  * {
    opacity: ${({ index }) => (index > 0 ? 1 - 0.3 * index : 1)};
  }
`;

export interface BoardSummaryCoverProps {
  premium: PremiumType;
  dailyQuizList: DailyQuiz[];
  dailyQuiz: DailyQuiz;
  onLoadHistory: (options: { startDate: string; endDate: string }) => void;
  onClickDailyQuiz: (dailyQuiz: DailyQuiz) => void;
  onPremium: () => void;
  onStart: () => void;

  historyStartDays: number;
  setHistoryStartDays: (days: number) => void;
}

const DailyQuizCover: React.FC<BoardSummaryCoverProps> = ({
  premium,
  dailyQuizList,
  dailyQuiz,
  onLoadHistory,
  onClickDailyQuiz,
  onPremium,
  onStart,

  historyStartDays,
  setHistoryStartDays,
}) => {
  const done = dailyQuiz && dailyQuiz.score != null && dailyQuiz.score >= 0;
  return (
    <Flex
      direction="column"
      alignItems="center"
      customStyle={{
        width: "100%",
        height: "100%",
        paddingTop: "100px",
        background: COLOR_SYSTEM.get("Gray")[0],
      }}
    >
      {done ? (
        <Text
          textType="LargeTitle2"
          color={COLOR_SYSTEM.get("Gray")[800]}
          style={{ textAlign: "center", marginBottom: "12px" }}
        >
          이미 오늘의 퀴즈를 <br />
          완료하셨어요!
        </Text>
      ) : (
        <Text
          textType="LargeTitle2"
          color={COLOR_SYSTEM.get("Gray")[800]}
          style={{ textAlign: "center", marginBottom: "12px" }}
        >
          약문약답 오늘의 퀴즈에 <br /> 도전하세요!
        </Text>
      )}
      <Text
        textType="Body1"
        color={COLOR_SYSTEM.get("Gray")[800]}
        style={{ textAlign: "center" }}
      >
        오늘의 퀴즈는 약문약답의 Q&A로 만들어집니다.
        <br />
        하루 단 3개의 퀴즈로 즐겁게 지식을 넓혀보세요.
      </Text>
      {done ? (
        <img
          src="/images/quiz/daily-quiz-done.png"
          style={{ maxHeight: "35%", maxWidth: "60%", marginTop: "20px" }}
        />
      ) : (
        <img
          src="/images/quiz/daily-quiz.png"
          style={{ maxHeight: "50%", maxWidth: "90%", marginTop: "40px" }}
        />
      )}
      <Fixed
        bottom={0}
        left={0}
        customStyle={{ width: "100%", paddingBottom: "20px" }}
      >
        <Flex
          direction="column"
          alignItems="center"
          gap="8px"
          customStyle={{ width: "100%", padding: "0 20px" }}
        >
          {done && (
            <DailyQuizHistory
              premium={premium}
              dailyQuizList={dailyQuizList}
              onLoad={onLoadHistory}
              onClick={onClickDailyQuiz}
              onPremium={onPremium}
              days={historyStartDays}
              setDays={setHistoryStartDays}
            />
          )}
          <Button
            size={"Large"}
            type={"Text"}
            variant={"Contained"}
            color={"Primary"}
            style={{ width: "100%" }}
            onClick={() => onStart()}
          >
            시작하기
          </Button>
          <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[500]}>
            매일 자정 12시에 새로운 퀴즈가 생성됩니다.
          </Text>
        </Flex>
      </Fixed>
    </Flex>
  );
};

export default DailyQuizCover;
