import * as boards from "./actions";
import { ActionType, getType } from "typesafe-actions";
import {
  BoardState,
  BoardActionTypes,
  BoardListState,
  BoardType,
  BoardSortType,
  BoardFilterType,
  DAILY_QUIZ_HISTORY_CARD_COUNT,
} from "./types";
import {
  BoardInfo,
  BoardContent,
  Reference,
  BoardAttribute,
  NOTICE_PRESET,
  RECOMMEND_PRESET,
  QNA_PRESET,
  POST_PRESET,
  DISCUSS_PRESET,
  ALL_QNA_PRESET,
  BoardContentSummary,
  DailyQuiz,
  BoardContentQuiz,
} from "../../models/Model.Board";
import { log, LogLevel } from "../../utils/LogUtil";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import ABTestUtil, { ABTestFeature } from "../../utils/ABTestUtil";
import { CompanyType } from "../../models/Model.User";
import allinoneCategoryAuto from "../../assets/image/allinone-cate-auto.png";
import allinoneCategoryBank from "../../assets/image/allinone-cate-bank.png";
import allinoneCategoryConsult from "../../assets/image/allinone-cate-consult.png";
import allinoneCategoryEMR from "../../assets/image/allinone-cate-emr.png";
import allinoneCategoryFranchize from "../../assets/image/allinone-cate-frenchize.png";
import allinoneCategoryInsurance from "../../assets/image/allinone-cate-insurance.png";
import allinoneCategoryInterior from "../../assets/image/allinone-cate-interior.png";
import allinoneCategoryLabor from "../../assets/image/allinone-cate-labor.png";
import allinoneCategoryLaw from "../../assets/image/allinone-cate-law.png";
import allinoneCategoryRealAsset from "../../assets/image/allinone-cate-realasset.png";
import allinoneCategoryTax from "../../assets/image/allinone-cate-tax.png";
import allinoneCategoryPharmaceutical from "../../assets/image/allinone-cate-pharmaceutical.png";
import allinoneCategoryScholar from "../../assets/image/allinone-cate-scholar.png";
// import allinoneCategoryQuasiDrug from "../../assets/image/allinone-cate-quasi-drug.png";
import allinoneCategoryFunctional from "../../assets/image/allinone-cate-functional.png";
import allinoneCategoryEducation from "../../assets/image/allinone-cate-functional.png";
import allinoneCategoryEtc from "../../assets/image/allinone-cate-etc.png";
import Keyword from "../../components/organisms/Search/Keyword";
import { shuffle } from "lodash";
import {
  convertDateToDays,
  getDateStringFromToday,
} from "../../utils/TimeUtil";

const defaultPreBoardList = [BoardType.UNION_TAB, BoardType.RECOMMEND];
const defaultPostBoardList = [BoardType.HOLY_TREE];

let targets = getGlobal(GlobalKey.BOARD_UNION_CATEGORIES, true);
if (ABTestUtil.isTest(ABTestFeature.UI_PARTNERS_UNION_BOARD_DEFAULT)) {
  if (!targets.includes(15)) targets.push(15);
  if (!targets.includes(3)) targets.push(3);
}

let targetsNice = getGlobal(
  GlobalKey.BOARD_UNION_CATEGORIES + BoardType.RECOMMEND,
  true
);
if (
  ABTestUtil.isTest(ABTestFeature.UI_PARTNERS_UNION_BOARD_DEFAULT) &&
  !targetsNice.includes(15)
)
  targetsNice.push(15);

const defaultBoards = {
  [BoardType.UNION_TAB]: {
    category: BoardType.UNION,
    categories: targets,
    name: "모아보기",
    fullname: "",
    attribute: ALL_QNA_PRESET,
    contents: null,
    end: false,
    loading: false,
    checksum: 0,
    needReload: true,
    lastVisitedTime: getGlobal(
      GlobalKey.BOARD_LAST_VISITED + BoardType.UNION_TAB.toString(),
      true
    ),
    sort: BoardSortType.DEFAULT,
  },
  [BoardType.HOLY_TREE]: {
    category: BoardType.QNA,
    name: "#HolyTree",
    fullname: "",
    attribute: QNA_PRESET,
    contents: null,
    end: false,
    loading: false,
    checksum: 0,
    needReload: true,
    user: 8450,
    sort: BoardSortType.DEFAULT,
    filter: BoardFilterType.UNREAD,
  },
  [BoardType.RECOMMEND]: {
    category: BoardType.NONE,
    categories: targetsNice,
    name: "추천",
    fullname: "",
    attribute: RECOMMEND_PRESET,
    contents: null,
    end: false,
    loading: false,
    checksum: 0,
    needReload: true,
    sort: BoardSortType.NICE,
    filter: BoardFilterType.UNREAD,
  },
  [BoardType.NOTICE_TAB]: {
    category: BoardType.NOTICE,
    name: "공지",
    fullname: "",
    attribute: NOTICE_PRESET,
    contents: null,
    end: false,
    loading: false,
    checksum: 0,
    needReload: true,
    lastVisitedTime: getGlobal(
      GlobalKey.BOARD_LAST_VISITED + BoardType.NOTICE_TAB.toString(),
      true
    ),
    sort: BoardSortType.DEFAULT,
  },
};

const bizCategoryList = [
  CompanyType.ALL,
  CompanyType.PHARMACEUTICAL_COMPANY,
  CompanyType.HEALTH_FUNTIONAL_FOOD_COMPANY,
  CompanyType.QUASI_DRUG,
  CompanyType.FRENCHISE,
  CompanyType.DISTRIBUTOR,
  CompanyType.EMR,
  CompanyType.FINANCE,
  CompanyType.SCHOLAR,
  // CompanyType.CONSULT,
  // CompanyType.LABOR,
  CompanyType.TAX,
  CompanyType.EDUCATION,
  // CompanyType.AUTOMATION,
  // CompanyType.REAL_ASSET,
  CompanyType.LAW,
  CompanyType.LIFE,
  // CompanyType.INSURANCE,
  // CompanyType.ETC,
];
const bizCartegories = {
  [CompanyType.ALL]: {
    category: 15,
    sort: BoardSortType.TIME_DESC,
    bizCategoryId: CompanyType.ALL,
    name: "홈",
    contents: null,
    end: false,
    loading: false,
    checksum: 0,
    needReload: true,
    lastVisitedTime: getGlobal(
      GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.ALL.toString(),
      true
    ),
  },
  [CompanyType.PHARMACEUTICAL_COMPANY]: {
    category: 15,
    sort: BoardSortType.TIME_DESC,
    bizCategoryId: CompanyType.PHARMACEUTICAL_COMPANY,
    name: "제약사",
    image: allinoneCategoryPharmaceutical,
    contents: null,
    end: false,
    loading: false,
    checksum: 0,
    needReload: true,
    lastVisitedTime: getGlobal(
      GlobalKey.BIZ_BOARD_LAST_VISITED +
        CompanyType.PHARMACEUTICAL_COMPANY.toString(),
      true
    ),
  },
  [CompanyType.HEALTH_FUNTIONAL_FOOD_COMPANY]: {
    category: 15,
    sort: BoardSortType.TIME_DESC,
    bizCategoryId: CompanyType.HEALTH_FUNTIONAL_FOOD_COMPANY,
    name: "건강기능식품",
    image: allinoneCategoryFunctional,
    contents: null,
    end: false,
    loading: false,
    checksum: 0,
    needReload: true,
    lastVisitedTime: getGlobal(
      GlobalKey.BIZ_BOARD_LAST_VISITED +
        CompanyType.HEALTH_FUNTIONAL_FOOD_COMPANY.toString(),
      true
    ),
  },
  [CompanyType.QUASI_DRUG]: {
    category: 15,
    sort: BoardSortType.TIME_DESC,
    bizCategoryId: CompanyType.QUASI_DRUG,
    name: "의약외품",
    // image: allinoneCategoryQuasiDrug,
    image: allinoneCategoryFunctional,
    contents: null,
    end: false,
    loading: false,
    checksum: 0,
    needReload: true,
    lastVisitedTime: getGlobal(
      GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.QUASI_DRUG.toString(),
      true
    ),
  },
  [CompanyType.FRENCHISE]: {
    category: 15,
    sort: BoardSortType.TIME_DESC,
    bizCategoryId: CompanyType.FRENCHISE,
    name: "약국체인",
    image: allinoneCategoryFranchize,
    contents: null,
    end: false,
    loading: false,
    checksum: 0,
    needReload: true,
    lastVisitedTime: getGlobal(
      GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.FRENCHISE.toString(),
      true
    ),
  },
  [CompanyType.EMR]: {
    category: 15,
    sort: BoardSortType.TIME_DESC,
    bizCategoryId: CompanyType.EMR,
    name: "IT서비스",
    image: allinoneCategoryEMR,
    contents: null,
    end: false,
    loading: false,
    checksum: 0,
    needReload: true,
    lastVisitedTime: getGlobal(
      GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.EMR.toString(),
      true
    ),
  },
  // [CompanyType.INTERIOR]: {
  //   category: 15,
  //   sort: BoardSortType.TIME_DESC,
  //   bizCategoryId: CompanyType.INTERIOR,
  //   name: "인테리어",
  //   image: allinoneCategoryInterior,
  //   contents: null,
  //   end: false,
  //   loading: false,
  //   checksum: 0,
  //   needReload: true,
  //   lastVisitedTime: getGlobal(
  //     GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.INTERIOR.toString(),
  //     true
  //   ),
  // },
  [CompanyType.FINANCE]: {
    category: 15,
    sort: BoardSortType.TIME_DESC,
    bizCategoryId: CompanyType.FINANCE,
    name: "대출",
    image: allinoneCategoryBank,
    contents: null,
    end: false,
    loading: false,
    checksum: 0,
    needReload: true,
    lastVisitedTime: getGlobal(
      GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.FINANCE.toString(),
      true
    ),
  },
  // [CompanyType.CONSULT]: {
  //   category: 15,
  //   sort: BoardSortType.TIME_DESC,
  //   bizCategoryId: CompanyType.CONSULT,
  //   name: "컨설팅",
  //   image: allinoneCategoryConsult,
  //   contents: null,
  //   end: false,
  //   loading: false,
  //   checksum: 0,
  //   needReload: true,
  //   lastVisitedTime: getGlobal(
  //     GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.CONSULT.toString(),
  //     true
  //   ),
  // },
  // [CompanyType.LABOR]: {
  //   category: 15,
  //   sort: BoardSortType.TIME_DESC,
  //   bizCategoryId: CompanyType.LABOR,
  //   name: "노무",
  //   image: allinoneCategoryLabor,
  //   contents: null,
  //   end: false,
  //   loading: false,
  //   checksum: 0,
  //   needReload: true,
  //   lastVisitedTime: getGlobal(
  //     GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.LABOR.toString(),
  //     true
  //   ),
  // },
  [CompanyType.TAX]: {
    category: 15,
    sort: BoardSortType.TIME_DESC,
    bizCategoryId: CompanyType.TAX,
    name: "세무",
    image: allinoneCategoryTax,
    contents: null,
    end: false,
    loading: false,
    checksum: 0,
    needReload: true,
    lastVisitedTime: getGlobal(
      GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.TAX.toString(),
      true
    ),
  },
  // [CompanyType.AUTOMATION]: {
  //   category: 15,
  //   sort: BoardSortType.TIME_DESC,
  //   bizCategoryId: CompanyType.AUTOMATION,
  //   name: "자동조제기기",
  //   image: allinoneCategoryAuto,
  //   contents: null,
  //   end: false,
  //   loading: false,
  //   checksum: 0,
  //   needReload: true,
  //   lastVisitedTime: getGlobal(
  //     GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.AUTOMATION.toString(),
  //     true
  //   ),
  // },
  // [CompanyType.REAL_ASSET]: {
  //   category: 15,
  //   sort: BoardSortType.TIME_DESC,
  //   bizCategoryId: CompanyType.REAL_ASSET,
  //   name: "부동산",
  //   image: allinoneCategoryRealAsset,
  //   contents: null,
  //   end: false,
  //   loading: false,
  //   checksum: 0,
  //   needReload: true,
  //   lastVisitedTime: getGlobal(
  //     GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.REAL_ASSET.toString(),
  //     true
  //   ),
  // },
  [CompanyType.DISTRIBUTOR]: {
    category: 15,
    sort: BoardSortType.TIME_DESC,
    bizCategoryId: CompanyType.DISTRIBUTOR,
    name: "도매/몰",
    image: allinoneCategoryFranchize,
    contents: null,
    end: false,
    loading: false,
    checksum: 0,
    needReload: true,
    lastVisitedTime: getGlobal(
      GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.DISTRIBUTOR.toString(),
      true
    ),
  },
  [CompanyType.EDUCATION]: {
    category: 15,
    sort: BoardSortType.TIME_DESC,
    bizCategoryId: CompanyType.EDUCATION,
    name: "교육/판매방법",
    image: allinoneCategoryEducation,
    contents: null,
    end: false,
    loading: false,
    checksum: 0,
    needReload: true,
    lastVisitedTime: getGlobal(
      GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.EDUCATION.toString(),
      true
    ),
  },
  [CompanyType.LAW]: {
    category: 15,
    sort: BoardSortType.TIME_DESC,
    bizCategoryId: CompanyType.LAW,
    name: "법률",
    image: allinoneCategoryLaw,
    contents: null,
    end: false,
    loading: false,
    checksum: 0,
    needReload: true,
    lastVisitedTime: getGlobal(
      GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.LAW.toString(),
      true
    ),
  },
  // [CompanyType.INSURANCE]: {
  //   category: 15,
  //   sort: BoardSortType.TIME_DESC,
  //   bizCategoryId: CompanyType.INSURANCE,
  //   name: "보험",
  //   image: allinoneCategoryInsurance,
  //   contents: null,
  //   end: false,
  //   loading: false,
  //   checksum: 0,
  //   needReload: true,
  //   lastVisitedTime: getGlobal(
  //     GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.INSURANCE.toString(),
  //     true
  //   ),
  // },
  [CompanyType.SCHOLAR]: {
    category: 15,
    sort: BoardSortType.TIME_DESC,
    bizCategoryId: CompanyType.SCHOLAR,
    name: "학회/기관",
    image: allinoneCategoryScholar,
    contents: null,
    end: false,
    loading: false,
    checksum: 0,
    needReload: true,
    lastVisitedTime: getGlobal(
      GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.SCHOLAR.toString(),
      true
    ),
  },
  [CompanyType.LIFE]: {
    category: 15,
    sort: BoardSortType.TIME_DESC,
    bizCategoryId: CompanyType.LIFE,
    name: "라이프",
    image: allinoneCategoryScholar,
    contents: null,
    end: false,
    loading: false,
    checksum: 0,
    needReload: true,
    lastVisitedTime: getGlobal(
      GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.SCHOLAR.toString(),
      true
    ),
  },
  // [CompanyType.ETC]: {
  //   category: 15,
  //   sort: BoardSortType.TIME_DESC,
  //   bizCategoryId: CompanyType.ETC,
  //   name: "기타",
  //   image: allinoneCategoryEtc,
  //   contents: null,
  //   end: false,
  //   loading: false,
  //   checksum: 0,
  //   needReload: true,
  //   lastVisitedTime: getGlobal(
  //     GlobalKey.BIZ_BOARD_LAST_VISITED + CompanyType.ETC.toString(),
  //     true
  //   ),
  // },
};
const SUMMARY_LIST_DEFAULT = [
  "임산부",
  "안약",
  "화상",
  "항생제",
  "해열제",
  "당뇨",
  "아시클로버",
  "피임약",
  "스테로이드",
  "통풍",
  "칼슘",
  "대상포진",
  "복약지도",
  "항히스타민제",
  "술",
  "마그네슘",
  "수유부",
  "딸꾹질",
  "고혈압",
  "편두통",
  "플루코나졸",
  "철분제",
  "다래끼",
  "소아과",
  "NSAID",
  "금기",
  "영양제",
  "방광염",
  "갑상선",
  "골다공증",
  "간독성",
  "인데놀",
  "무좀",
  "고지혈증",
  "오프라벨",
  "노인",
]; //= this.props.nice.contents.slice(0, 10);

const defaultState: BoardState = {
  boardList: [...defaultPreBoardList, ...defaultPostBoardList],
  selectableBoardList: [],
  boards: {
    ...defaultBoards,
  }, // [id]: BoardList
  boardListLoaded: false,
  contents: {}, // [id]: BoardContent
  links: {},
  filePath: "",
  bizCategoryList,
  bizBoards: bizCartegories,
  summaryList: shuffle(SUMMARY_LIST_DEFAULT),
  summaryGroups: {},
  summaries: {}, // [id]: BoardSummary

  dailyQuizs: {}, // [id]: DailyQuiz
  dailyQuizSolve: {
    date: getDateStringFromToday(),
    result: { results: [], score: 0 },
    page: "COVER",
    index: 0,
    historyStartDays: convertDateToDays(
      getDateStringFromToday({ days: 2 - DAILY_QUIZ_HISTORY_CARD_COUNT })
    ),
  },
};

export default (
  state = defaultState,
  action: ActionType<typeof boards>
): BoardState => {
  let newState: BoardState;
  let newContents, newLinks;
  let content: BoardContent;
  let newBoards;
  switch (action.type) {
    case getType(boards.refreshBoards):
      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.refreshBoards",
        action.payload,
        state
      );
      if (action.payload == BoardType.ALL) {
        let newBoards = { ...state.boards };
        for (let i = 0; i < state.boardList.length; i++) {
          newBoards[state.boardList[i]] = {
            ...state.boards[state.boardList[i]],
            needReload: true,
            end: false,
            loading: false,
            checksum: 0,
          };
        }
        log(
          LogLevel.REDUX_BOARD,
          "Reducer: boards.refreshBoards ALL",
          newBoards
        );
        return {
          ...state,
          boards: newBoards,
        };
      } else {
        let newBoards = { ...state.boards };
        if (newBoards[action.payload]) {
          newBoards[action.payload].end = false;
          newBoards[action.payload].loading = false;
          newBoards[action.payload].checksum = 0;
          newBoards[action.payload].needReload = true;
          return {
            ...state,
            boards: newBoards,
          };
        }
      }
      break;

    case getType(boards.sortBoard):
      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.sortBoard",
        action.payload,
        action.meta,
        state
      );
      newBoards = { ...state.boards };
      if (
        newBoards[action.payload] &&
        newBoards[action.payload].sort != action.meta
      ) {
        newBoards[action.payload].end = false;
        newBoards[action.payload].loading = false;
        newBoards[action.payload].checksum = 0;
        newBoards[action.payload].needReload = true;
        newBoards[action.payload].sort = action.meta;
        return {
          ...state,
          boards: newBoards,
        };
      }
      break;

    case getType(boards.sortBizBoard): {
      let id;
      if (action.payload.bizCategoryId) {
        id = action.payload.bizCategoryId;
      } else if (action.payload.bizUseId) {
        id = action.payload.bizUseId;
      }

      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.sortBizBoard",
        action.payload,
        action.meta,
        state
      );
      newBoards = { ...state.bizBoards };
      if (newBoards[id] && newBoards[id].sort != action.meta) {
        newBoards[id].end = false;
        newBoards[id].loading = false;
        newBoards[id].checksum = 0;
        newBoards[id].needReload = true;
        newBoards[id].sort = action.meta;
        return {
          ...state,
          bizBoards: newBoards,
        };
      }
      break;
    }

    case getType(boards.changeUnionBoard):
      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.filterBoard",
        action.payload,
        state
      );
      newBoards = { ...state.boards };
      if (newBoards[action.meta]) {
        let targets = [...action.payload];
        if (
          ABTestUtil.isTest(ABTestFeature.UI_PARTNERS_UNION_BOARD_DEFAULT) &&
          !targets.includes(15)
        )
          targets.push(15);

        newBoards[action.meta].end = false;
        newBoards[action.meta].loading = false;
        newBoards[action.meta].checksum = 0;
        newBoards[action.meta].needReload = true;
        newBoards[action.meta].categories = targets;
        return {
          ...state,
          boards: newBoards,
        };
      }
      break;

    case getType(boards.filterBoard):
      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.filterBoard",
        action.payload,
        action.meta,
        state
      );
      newBoards = { ...state.boards };
      if (
        newBoards[action.payload] &&
        newBoards[action.payload].filter != action.meta
      ) {
        newBoards[action.payload].end = false;
        newBoards[action.payload].loading = false;
        newBoards[action.payload].checksum = 0;
        newBoards[action.payload].needReload = true;
        newBoards[action.payload].filter = action.meta;
        return {
          ...state,
          boards: newBoards,
        };
      }
      break;

    case getType(boards.fetchBoards.success):
      log(LogLevel.REDUX_BOARD, "Reducer: fetchBoards.success", action, state);
      if (action.payload) {
        let boards: BoardInfo[] = action.payload;
        let rvalue = { ...state };
        let boardList = boards
          .filter((item) => !item.type)
          .map((item, index) => {
            let type: BoardType = item.id;
            return type;
          });

        rvalue.boardList = [
          ...defaultPreBoardList,
          ...boardList,
          ...defaultPostBoardList,
        ];
        rvalue.selectableBoardList = [...boardList];

        for (let i = 0; i < boards.length; i++) {
          rvalue.boards[boards[i].id] = {
            category: boards[i].id,
            end: false,
            loading: false,
            needReload: true,
            ...state.boards[boards[i].id],
            name: boards[i].name,
            fullname: boards[i].fullname,
            attribute: boards[i].attribute,
          };
          // log(LogLevel.REDUX_BOARD, "Reducer:boards.fetchBoards loop ", i, boards[i], rvalue.boards[boards[i].id])
        }

        rvalue.boardListLoaded = true;
        log(
          LogLevel.REDUX_BOARD,
          "Reducer:boards.fetchBoards.success ",
          rvalue,
          state
        );
        return rvalue;
      }
      break;
    case getType(boards.updateContent):
      log(LogLevel.REDUX_BOARD, "Reducer: updateContent", action, state);
      newContents = { ...state.contents };
      content = action.payload;
      newContents[content.id] = { ...content };
      newState = { ...state, contents: newContents };
      return newState;

    case getType(boards.fetchBoard.request):
      newState = { ...state };
      if (!newState.boards[action.payload])
        newState.boards[action.payload] = {
          sort: BoardSortType.TIME_DESC,
          category: action.payload,
          ...state.boards[action.payload],
          id: action.payload,
          contents: [],
          end: false,
          loading: true,
          needReload: false,
          checksum: 0,
          lastUpdatedTime: Date.now(),
        };

      if (action.payload & BoardType.ALLINONE_PARTNER_START) {
        newState.boards[action.payload].category = BoardType.ALLINONE;
        newState.boards[action.payload].user =
          action.payload & ~BoardType.ALLINONE_PARTNER_START;
      } else {
        newState.boards[action.payload].loading = true;
        newState.boards[action.payload].needReload = false;
      }
      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.fetchBoard.request",
        action,
        newState,
        newState.boards[action.payload].lastVisitedTime
      );
      return newState;
      break;
    case getType(boards.fetchBoard.success):
      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.fetchBoard.success start",
        action
      );
      newState = {
        ...state,
        boards: { ...state.boards },
        links: { ...state.links },
      };
      newState.boards[action.payload.id] = {
        ...newState.boards[action.payload.id],
        ...action.payload,
      };
      if (action.payload.contents) {
        for (let i = 0; i < action.payload.contents.length; i++) {
          let aContent: BoardContent = action.payload.contents[i];
          if (aContent) {
            newState.contents[aContent.id] = {
              ...state.contents[aContent.id],
              ...aContent,
            };
            if (aContent.reference) {
              newState.links[aContent.reference.url] = {
                ...aContent.reference,
              };
            }
          }
        }
        let newContents = action.payload.contents.map((item, index) => {
          return { id: item.id };
        });
        newState.boards[action.payload.id].contents = newContents;
      }
      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.fetchBoard.success end",
        action,
        newState,
        action.payload.id,
        newState.boards[action.payload.id].lastVisitedTime
      );
      return newState;
    case getType(boards.fetchBoard.failure):
      newState = { ...state };
      newState.boards[parseInt(action.payload.name)].loading = false;
      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.fetchBoard.failure",
        action,
        state,
        newState,
        parseInt(action.payload.name),
        newState.boards[parseInt(action.payload.name)].lastVisitedTime
      );
      return newState;
    // case getType(boards.fetchContent.request):

    case getType(boards.fetchContent.success):
      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.fetchContent.success",
        action,
        state
      );
      newContents = { ...state.contents };
      content = action.payload;
      newContents[content.id] = content;
      newLinks = { ...state.links };
      if (content.references && content.references.length > 0) {
        for (let i = 0; i < content.references.length; i++) {
          if (
            content.references[i].url &&
            !newLinks[content.references[i].url]
          ) {
            newLinks[content.references[i].url] = content.references[i];
          }
        }
      }
      // console.log(content);
      if (content.replies && content.replies.length > 0) {
        for (let i = 0; i < content.replies.length; i++) {
          if (
            content.replies[i].references &&
            content.replies[i].references.length > 0
          ) {
            for (let j = 0; j < content.replies[i].references.length; j++) {
              if (content.replies[i].references[j].url) {
                newLinks[content.replies[i].references[j].url] =
                  content.replies[i].references[j];
              }
            }
          }
        }
      }
      return { ...state, contents: newContents, links: newLinks };

    case getType(boards.fetchContent.failure):
      log(LogLevel.REDUX_BOARD, "boards.fetchContent.failure", action, state);
      newState = { ...state };
      newState.boards[action.payload.name] = {};
      return newState;

    case getType(boards.fetchSummaryGroup.request):
      newState = { ...state };
      if (!newState.summaryGroups[action.payload])
        newState.summaryGroups[action.payload] = {
          keyword: action.payload,
          summaries: [],
          lastViewed: -1,
          end: false,
          loading: true,
        };
      else {
        newState.summaryGroups[action.payload] = {
          ...newState.summaryGroups[action.payload],
          loading: true,
        };
      }

      log(
        LogLevel.REDUX_BOARD,
        "boards.fetchSummaryGroup.request",
        action,
        newState
      );
      return newState;

    case getType(boards.setSummaryGroup):
    case getType(boards.fetchSummaryGroup.success):
      newState = { ...state, summaryGroups: { ...state.summaryGroups } };
      if (action.payload.summaries) {
        for (let i = 0; i < action.payload.summaries.length; i++) {
          let aSummary: BoardContentSummary = action.payload.summaries[i];
          if (aSummary) {
            const id = parseInt(aSummary.contentId);
            if (id) {
              newState.summaries[id] = {
                id,
                ...(state.summaries[id] ? state.summaries[id] : {}),
                ...aSummary,
                state: "FINISH",
              };

              newState.contents[id] = {
                id,
                ...(state.contents[id] ? state.contents[id] : {}),
                scrapped: !!aSummary.scrapped,
              };
            }
          }
        }

        action.payload.summaries = action.payload.summaries.map(
          (item, index) => {
            return { id: parseInt(item.contentId) };
          }
        );
        action.payload.count = action.payload.summaries.length;
      }

      action.payload.loading = false;
      newState.summaryGroups[action.payload.keyword] = {
        ...(newState.summaryGroups[action.payload.keyword] || {}),
        ...action.payload,
      };
      log(
        LogLevel.REDUX_BOARD,
        "boards.fetchSummaryGroup.success done",
        action,
        newState
      );
      return newState;

    case getType(boards.fetchSummaryGroup.failure): {
      log(
        LogLevel.REDUX_BOARD,
        "boards.fetchSummaryGroup.failure",
        action,
        state
      );
    }

    case getType(boards.fetchSummary.success):
    case getType(boards.setSummary): {
      let newSummaries = { ...state.summaries };
      let summary: BoardContentSummary = action.payload;

      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.fetchContent.success",
        newSummaries[summary.id],
        summary
      );

      newSummaries[summary.id] = {
        ...(newSummaries[summary.id] || {}),
        ...summary,
      };
      return { ...state, summaries: newSummaries };
    }

    case getType(boards.clearSummary): {
      log(LogLevel.REDUX_BOARD, "Reducer: boards.clearSummary", action, state);
      let newSummaries = { ...state.summaries };
      newSummaries[action.payload] = null;
      return { ...state, summaries: newSummaries };
    }

    case getType(boards.fetchContent.failure):
      log(LogLevel.REDUX_BOARD, "boards.fetchContent.failure", action, state);
      newState = { ...state };
      newState.boards[action.payload.name] = {};
      return newState;

    case getType(boards.fetchLink.success):
      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.fetchLink.success",
        action,
        state
      );
      let link: Reference = action.payload;
      newLinks = { ...state.links };
      if (link && link.url) {
        newLinks[link.url] = link;
      }
      return { ...state, links: newLinks };

    case getType(boards.fetchLink.request):
      log(LogLevel.REDUX_BOARD, "boards.fetchLink.request", action, state);
      newLinks = { ...state.links };
      if (action.payload) {
        newLinks[action.payload] = { url: action.payload };
      }
      return { ...state, links: newLinks };
    case getType(boards.fetchFilePath.success):
      log(LogLevel.REDUX_BOARD, "boards.fetchFilePath.success", action, state);
      if (action.payload) return { ...state, filePath: action.payload };
    case getType(boards.fetchFilePath.failure):
      return { ...state, filePath: "" };
    case getType(boards.visitBoard):
      newState = { ...state };
      newState.boards[action.payload] = {
        ...state.boards[action.payload],
        lastVisitedTime: Date.now(),
      };
      log(
        LogLevel.REDUX_BOARD,
        "boards.visitBoard",
        action,
        newState,
        newState.boards[action.payload],
        newState.boards[action.payload].lastVisitedTime
      );

      return newState;
      break;

    case getType(boards.fetchBizBoard.request):
      newState = { ...state };

      let id;
      if (action.payload.bizCategoryId) {
        id = action.payload.bizCategoryId;
      } else if (action.payload.bizUseId) {
        id = action.payload.bizUseId;
      }

      if (!newState.bizBoards[id])
        newState.bizBoards[id] = {
          sort: BoardSortType.TIME_DESC,
          category: BoardType.ALLINONE,
          ...state.bizBoards[id],
          id,
          contents: [],
          end: false,
          loading: true,
          needReload: false,
          checksum: 0,
          lastUpdatedTime: Date.now(),
          bizCategoryId: action.payload.bizCategoryId,
          bizUserId: action.payload.bizUseId,
        };

      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.fetchBizBoard.request",
        action,
        newState,
        newState.bizBoards[id].lastVisitedTime
      );
      return newState;
      break;
    case getType(boards.fetchBizBoard.success):
      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.fetchBizBoard.success start",
        action
      );

      id = null;
      if (action.payload.bizCategoryId) {
        id = action.payload.bizCategoryId;
      } else if (action.payload.bizUserId) {
        id = action.payload.bizUserId;
      }

      newState = {
        ...state,
        bizBoards: { ...state.bizBoards },
        links: { ...state.links },
      };
      newState.bizBoards[id] = {
        ...newState.bizBoards[id],
        ...action.payload,
      };
      if (action.payload.contents) {
        for (let i = 0; i < action.payload.contents.length; i++) {
          let aContent: BoardContent = action.payload.contents[i];
          if (aContent) {
            newState.contents[aContent.id] = {
              ...state.contents[aContent.id],
              ...aContent,
            };
          }
        }
        let newContents = action.payload.contents.map((item, index) => {
          return { id: item.id };
        });
        newState.bizBoards[id].contents = newContents;
      }
      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.fetchBizBoard.success end",
        action,
        newState,
        id,
        newState.bizBoards[id].lastVisitedTime
      );
      return newState;
    case getType(boards.fetchBizBoard.failure):
      newState = { ...state };
      newState.bizBoards[action.payload.id].loading = false;
      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.fetchBizBoard.failure",
        action,
        state,
        newState,
        action.payload.id
      );
      return newState;
    case getType(boards.fetchDailyQuizList.success):
      log(
        LogLevel.REDUX_BOARD,
        "boards.fetchDailyQuizList.success start",
        state.dailyQuizs
      );
      newState = { ...state, dailyQuizs: { ...state.dailyQuizs } };
      if (action.payload) {
        for (let i = 0; i < action.payload.length; i++) {
          newState.dailyQuizs[action.payload[i].id] = {
            ...(newState.dailyQuizs[action.payload[i].id] || {}),
            ...action.payload[i],
          };
        }
      }

      action.payload.loading = false;
      log(
        LogLevel.REDUX_BOARD,
        "boards.fetchDailyQuizList.success done",
        action,
        newState
      );
      return newState;

    case getType(boards.fetchDailyQuizList.failure): {
      log(
        LogLevel.REDUX_BOARD,
        "boards.fetchDailyQuizList.failure",
        action,
        state
      );
    }

    case getType(boards.fetchDailyQuiz.success):
    case getType(boards.setDailyQuiz): {
      let dailyQuizsList = { ...state.dailyQuizs };
      let dailyQuiz: DailyQuiz = action.payload;

      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.fetchDailyQuiz.success",
        dailyQuizsList[dailyQuiz.id],
        dailyQuiz
      );

      dailyQuizsList[dailyQuiz.id] = {
        ...(dailyQuizsList[dailyQuiz.id] || {}),
        ...dailyQuiz,
      };

      return { ...state, dailyQuizs: dailyQuizsList };
    }
    case getType(boards.setDailyQuizQuiz): {
      let dailyQuizsList = { ...state.dailyQuizs };
      let quiz: BoardContentQuiz = action.payload;
      let dailyQuizId: number = action.meta;

      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.setDailyQuizQuiz",
        quiz,
        dailyQuizId
      );

      if (!dailyQuizId || !dailyQuizsList[dailyQuizId] || !quiz || !quiz.id) {
        return state;
      }

      let dailyQuiz: DailyQuiz = { ...dailyQuizsList[dailyQuizId] };

      if (dailyQuiz.quizzes && dailyQuiz.quizzes.length > 0) {
        for (let i = 0; i < dailyQuiz.quizzes.length; i++) {
          if (dailyQuiz.quizzes[i].id == quiz.id) {
            dailyQuiz.quizzes[i] = { ...dailyQuiz.quizzes[i], ...quiz };
            break;
          }
        }
      }

      dailyQuizsList[dailyQuizId] = dailyQuiz;

      return { ...state, dailyQuizs: dailyQuizsList };
    }

    case getType(boards.setDailyQuizSolveClear):
    case getType(boards.setDailyQuizSolvePage):
    case getType(boards.setDailyQuizSolveDate):
    case getType(boards.setDailyQuizSolve): {
      let dailyQuizSolve = { ...state.dailyQuizSolve, ...action.payload };

      if (
        state.dailyQuizSolve.date != dailyQuizSolve.date &&
        !action.payload.page
      ) {
        dailyQuizSolve.result = { results: [], score: 0 };
        dailyQuizSolve.index = 0;
        dailyQuizSolve.page = "TEST";
      } else if (
        state.dailyQuizSolve.page != "TEST" &&
        action.payload.page == "TEST"
      ) {
        dailyQuizSolve.result = { results: [], score: 0 };
        dailyQuizSolve.index = 0;
      }

      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.setDailyQuizSolve",
        state.dailyQuizSolve,
        action.payload,
        dailyQuizSolve
      );

      return { ...state, dailyQuizSolve };
    }

    case getType(boards.addDailyQuizSolveResult): {
      let dailyQuizSolve = { ...state.dailyQuizSolve };

      let result = { ...dailyQuizSolve.result };
      result.results.push(action.payload);

      if (action.payload.score > 0) result.score += action.payload.score;

      dailyQuizSolve.result = result;

      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.addDailyQuizSolveResult",
        state.dailyQuizSolve,
        action.payload,
        dailyQuizSolve
      );

      return { ...state, dailyQuizSolve };
    }

    case getType(boards.progressDailyQuizSolve): {
      let dailyQuizSolve = { ...state.dailyQuizSolve };

      dailyQuizSolve.index = dailyQuizSolve.index + 1;

      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.progressDailyQuizSolve",
        state.dailyQuizSolve,
        dailyQuizSolve
      );

      return { ...state, dailyQuizSolve };
    }

    case getType(boards.setDailyQuizHistoryDays): {
      let dailyQuizSolve = { ...state.dailyQuizSolve };

      dailyQuizSolve.historyStartDays = action.payload;

      log(
        LogLevel.REDUX_BOARD,
        "Reducer: boards.setDailyQuizHistoryDays",
        state.dailyQuizSolve,
        dailyQuizSolve
      );

      return { ...state, dailyQuizSolve };
    }
    // case getType(boards.fetchContent.request):
    default:
      return state;
  }

  return state;
};
