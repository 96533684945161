import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Icon from "../../../component/atom/Icon";
import { UserInfo, UserWorkType, UserWorkTypeName } from "../../../models/Model.User";
import GeocodeUtil from "../../../utils/GeocodeUtil";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import BottomSheet from "../../molecules/BottomSheet/BottomSheet";
import Dropdown from "../../molecules/Dropdown/Dropdown";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import { AddressItem, Footer, Header, Main, NaverMap, PharmacyList, Section, WorkTypeBottomSheet } from "./style";
import pinIcon from "./../../../assets/icon/pin.png";
import Switch from "../../atoms/Switch/Switch";
import { JobOfferName, JobOfferType } from "../../../models/Model.JobPost";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
import { useDispatch, useSelector } from "react-redux";
import { fetchAPI } from "../../../utils/APIUtil";
import * as API from "../../../API.json";
import { actions, RootState } from "../../../store";
import TickMarkSlider from "../../molecules/TickMarkSlider";
import { log, LogLevel } from "../../../utils/LogUtil";
import { UIPopupType, UIServiceType } from "../../../store/ui/types";
import WorkTypeSelectBottomSheet from "../../organisms/JobPostBottomSheet/WorkTypeSelectBottomSheet";
import BeforeLeaveSettingPopup from "../../organisms/JobPostPopup/BeforeLeaveSettingPopup";
import SettingEmptyErrorPopup from "../../organisms/JobPostPopup/SettingEmptyErrorPopup";
import { differenceInDays } from "date-fns";
import ChannelTalkService from "../../../utils/ChannelTalkService";
import useChangeWorkTypeConfirm from "../../../hooks/useWorkTypeConfirm";

type Mode = "home" | "addressSearch" | "pharmacySearch" | "SelfRegister";

interface SettingForm {
  workType: number;
  workPlaceAddress: string;
  workPlaceName: string;
  workPlaceRegion: string;
  workPlacePhone: string;
  workPlacePosition: any;
  homeAddress: string;
  homeRegion: string;
  homePosition: any;
  jobpostStatus: any;
  jobpostDistance: number;
}

interface JobPostSettingProps {
  me?: UserInfo;
  initMode?: Mode;
  searchedAddress?: any[];
  searchedPharmacy?: any[];
  onSearchAddress?: (address: string) => void;
  onSearchPharmacy?: (pharmacy: string) => void;
  onUpdateOfferSetting?: (user: UserInfo, jobPostAlarm: boolean) => Promise<void>;
  onGoBack?: () => void;
  onClose?: () => void;
}

const JobPostSetting: React.FC<JobPostSettingProps> = ({
  me,
  initMode,
  searchedAddress,
  searchedPharmacy,
  onSearchAddress,
  onSearchPharmacy,
  onUpdateOfferSetting,
  onGoBack,
  onClose,
}) => {
  const marker = useRef(null);
  const map = useRef(null);
  const windowAny: any = window;
  const naver: any = windowAny.naver;
  const distances = [5, 10, 15, 20, 25, 30];
  const originJobPostAlarm = (me.jobpostStatus !== JobOfferType.INVALID && me.jobpostStatus !== JobOfferType.NONE && !!me.homeAddress);

  const [mode, setMode] = useState<Mode>(initMode || "home");
  const [form, setForm] = useState<SettingForm>({
    workType: me.workType,
    workPlaceAddress: me.workPlaceAddress || "",
    workPlaceName: me.workPlaceName || "",
    workPlaceRegion: me.workPlaceRegion || "",
    workPlacePhone: me.workPlacePhone || "",
    workPlacePosition: me.workPlacePosition,
    homeAddress: me.homeAddress || "",
    homeRegion: me.homeRegion || "",
    homePosition: me.homePosition,
    jobpostStatus:
      me.jobpostStatus !== JobOfferType.INVALID && me.jobpostStatus !== JobOfferType.NONE && !!me.homeAddress
        ? me.jobpostStatus
        : JobOfferType.ALL,
    jobpostDistance: me.jobpostDistance || 30,
  });

  const [jobPostAlarm, setJobPostAlarm] = useState(() =>
    (me.jobpostStatus !== JobOfferType.INVALID && me.jobpostStatus !== JobOfferType.NONE && !!me.homeAddress)
  );
  const [workPlaceAddress, setWorkPlaceAddress] = useState(me.workPlaceAddress || "");
  const [homeAddress, setHomeAddress] = useState(me.homeAddress || "");
  const [pharmacy, setPharmacy] = useState(form.workPlaceName || "");
  const [selectedDistance, setSelectedDistance] = useState(
    distances.indexOf(me.jobpostDistance / 1000) === -1
      ? distances.length - 1
      : distances.indexOf(me.jobpostDistance / 1000)
  );
  const [workTypeSheet, setWorkTypeSheet] = useState(false);
  const [jobPostStatusSheet, setJobPostStatusSheet] = useState(false);
  const [beforeLeavePopup, setBeforeLeavePopup] = useState(false);
  const [emptyHomeAddressError, setEmptyHomeAddressError] = useState(false);
  const [emptyWorkPlaceNameError, setEmptyWorkPlaceNameError] = useState(false);

  const backKeyControl = useSelector((state: RootState) => state.ui.services[UIServiceType.BACK_CONTROLLER]);
  const toastPopup = useSelector((state: RootState) => state.ui.popups[UIPopupType.TOAST_POPUP]);
  const mainEl = useRef<HTMLElement | null>(null);
  const homeAddressInputEl = useRef<HTMLElement | null>(null);

  const dispatch = useDispatch();
  const { showCanNotChangeWorkTypeConfirm, showChangeWorkTypeWarningConfirm } = useChangeWorkTypeConfirm();

  const renderMain = () => {
    switch (mode) {
      case "home":
        return (
          <>
            <Section className="setting__worktype">
              <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[700]}>
                나의 근무 유형
              </Text>
              <Button
                color="Secondary"
                size="Medium"
                type="IconWithText"
                icon={"PolygonDown"}
                right
                variant="Ghost"
                onClick={async () => {
                  if (!(await canChangeWorkType())) return;
                  setWorkTypeSheet(true);
                }}
              >
                {UserWorkTypeName[form.workType]}
              </Button>
            </Section>

            {form.workType === UserWorkType.PHARMACY_OWNER ? (
              form.workPlaceName ? (
                <>
                  <Section className="setting__workplace">
                    <div className="workplace__name">
                      <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[900]}>
                        {form.workPlaceName}
                      </Text>
                      <Button
                        color="Secondary"
                        size="Small"
                        type="Text"
                        variant="Tinted"
                        onClick={() => setMode("pharmacySearch")}
                      >
                        약국 정보 수정
                      </Button>
                    </div>
                    <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[500]}>
                      {form.workPlaceAddress}
                    </Text>
                    <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[500]}>
                      {form.workPlacePhone}
                    </Text>
                  </Section>
                  <Section>
                    <NaverMap>
                      <Text textType="InputMedium" color={COLOR_SYSTEM.get("Gray")[800]}>
                        약국의 정확한 위치를 터치해서{" "}
                        <img className="jobpost-pharmacy-offer-editor-pin" src={pinIcon} alt="지도" />을 옮겨주세요
                      </Text>
                      <div id="map" />
                    </NaverMap>
                    <Text textType="InputSmall" color={COLOR_SYSTEM.get("Gray")[400]}>
                      두 손가락으로 지도 확대/축소
                    </Text>
                  </Section>
                </>
              ) : (
                <Section>
                  <Input
                    label="약국(회사)명"
                    value={form.workPlaceName}
                    placeholder="약국명 또는 회사명 검색"
                    onClick={() => setMode("pharmacySearch")}
                  />
                </Section>
              )
            ) : (
              <>
                <Section className="setting__manual">
                  <div className="manual__icon">
                    <Icon width={18} height={21} name={"MapPin"} fill={COLOR_SYSTEM.get("Skyblue")[400]} />
                  </div>
                  <Text textType="Body2Bold" color={COLOR_SYSTEM.get("Gray")[800]}>
                    집 주소를 입력하시면 집에서 약국까지의 거리와 교통편을 알려드릴게요!
                  </Text>
                </Section>
                <Section ref={homeAddressInputEl}>
                  <Input
                    label="집 주소"
                    value={form.homeAddress}
                    placeholder="지번, 도로명, 건물명 검색"
                    onClick={() => {
                      setMode("addressSearch");
                    }}
                  />
                </Section>

                {form.homePosition && (
                  <Section>
                    <NaverMap>
                      <Text textType="InputMedium" color={COLOR_SYSTEM.get("Gray")[800]}>
                        정확한 위치를 터치해서{" "}
                        <img className="jobpost-pharmacy-offer-editor-pin" src={pinIcon} alt="지도" />을 옮겨주세요
                      </Text>
                      <div id="map" />
                    </NaverMap>
                    <Text textType="InputSmall" color={COLOR_SYSTEM.get("Gray")[400]}>
                      두 손가락으로 지도 확대/축소
                    </Text>
                  </Section>
                )}

                {form.workType !== UserWorkType.PHARMACY_OWNER && (
                  <>
                    <Section className="setting__alim">
                      <div className="alim__switch">
                        <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[700]}>
                          구인 알림 설정
                        </Text>
                        <Switch
                          size="Small"
                          isActive={form.workType !== UserWorkType.PHARMACY_OWNER && jobPostAlarm}
                          onClick={() => {
                            if (!form.homeAddress) {
                              setEmptyHomeAddressError(true);
                              return;
                            }
                            if (form.workType !== UserWorkType.PHARMACY_OWNER) {
                              setJobPostAlarm(!jobPostAlarm);
                            }
                          }}
                        />
                      </div>
                      <Text textType="Subhead2" color={COLOR_SYSTEM.get("Gray")[400]}>
                        원하는 조건의 구인공고가 등록되면 알려드릴게요
                      </Text>
                    </Section>
                    <Section style={{ opacity: jobPostAlarm ? 1 : 0.3 }}>
                      <Dropdown
                        label="희망 근무 유형"
                        placeholder="근무 유형을 선택하세요"
                        value={JobOfferName[form.jobpostStatus]}
                        onClick={() => {
                          if (!jobPostAlarm) return;
                          setJobPostStatusSheet(true);
                        }}
                      />
                    </Section>
                    <Section className="setting__distance" style={{ opacity: jobPostAlarm ? 1 : 0.3 }}>
                      <Text textType="InputSmall" color={COLOR_SYSTEM.get("Gray")[600]}>
                        희망 근무 위치
                      </Text>
                      <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[900]}>
                        집 주변{" "}
                        <Text textType="InputLarge" color={COLOR_SYSTEM.get("Skyblue")[400]}>
                          {distances[selectedDistance]}km
                        </Text>{" "}
                        이내
                      </Text>
                      <TickMarkSlider
                        width="100%"
                        rangeLabels={["5", "", "", "", "", "30"]}
                        selectedIndex={selectedDistance}
                        active={jobPostAlarm}
                        onChange={(e) => {
                          if (!jobPostAlarm) return;
                          setSelectedDistance(Number(e.target.value) - 1);
                        }}
                      />
                    </Section>
                  </>
                )}
              </>
            )}
          </>
        );
      case "addressSearch":
        return (
          <>
            <Section>
              <Input
                label={form.workType === UserWorkType.PHARMACY_OWNER ? "약국(회사) 직접 등록" : "집 주소"}
                value={form.workType === UserWorkType.PHARMACY_OWNER ? workPlaceAddress : homeAddress}
                placeholder="지번, 도로명, 건물명 검색"
                onChange={(e) =>
                  form.workType === UserWorkType.PHARMACY_OWNER
                    ? setWorkPlaceAddress(e.target.value)
                    : setHomeAddress(e.target.value)
                }
                onClear={() =>
                  form.workType === UserWorkType.PHARMACY_OWNER ? setWorkPlaceAddress("") : setHomeAddress("")
                }
              />
            </Section>
            <Section className="setting__address-list">
              {searchedAddress.map((address, index) => (
                <React.Fragment key={index}>
                  <AddressItem
                    onClick={() => {
                      if (form.workType === UserWorkType.PHARMACY_OWNER) {
                        GeocodeUtil.getGPSByAddress(address.address).then((position: { x: string; y: string }) => {
                          setForm({
                            ...form,
                            workPlaceAddress: address.address,
                            workPlaceRegion: address.region,
                            workPlacePosition: { x: Number(position.x), y: Number(position.y) },
                          });
                          setMode("SelfRegister");
                        });
                      } else {
                        GeocodeUtil.getGPSByAddress(address.address).then((position: { x: string; y: string }) => {
                          setForm({
                            ...form,
                            homeAddress: address.address,
                            homeRegion: address.region,
                            homePosition: { x: Number(position.x), y: Number(position.y) },
                          });
                          setMode("home");
                        });
                      }
                    }}
                  >
                    <Text textType="InputMedium" color={COLOR_SYSTEM.get("Gray")[900]}>
                      {address.address}
                    </Text>
                    <Text textType="InputSmall" color={COLOR_SYSTEM.get("Gray")[400]}>
                      우) {address.postalCode}
                    </Text>
                  </AddressItem>
                  {index < searchedAddress.length - 1 && <hr />}
                </React.Fragment>
              ))}
            </Section>
          </>
        );
      case "pharmacySearch":
        return (
          <>
            <Section>
              <Input
                value={pharmacy}
                hint="약국(회사)가 없을 경우 직접 등록해주세요"
                label="약국(회사) 정보"
                placeholder="약국명 또는 회사명 검색"
                onChange={(e) => setPharmacy(e.target.value)}
                onClear={() => setPharmacy("")}
              />
              <Button
                color="Secondary"
                icon="Plus"
                right
                size="Small"
                type="IconWithText"
                variant="Contained"
                style={{ marginTop: "12px" }}
                onClick={() => setMode("SelfRegister")}
              >
                약국(회사) 직접 등록하기
              </Button>
            </Section>
            <Section>
              <PharmacyList>
                {searchedPharmacy.map((pharmacy, index) => (
                  <React.Fragment key={pharmacy.id}>
                    <li
                      style={{ display: "flex", flexDirection: "column" }}
                      onClick={async () => {
                        if (initMode === "pharmacySearch") {
                          GeocodeUtil.getGPSByAddress(pharmacy.address).then(
                            async (position: { x: string; y: string }) => {
                              await onUpdateOfferSetting(
                                {
                                  ...form,
                                  workPlaceAddress: pharmacy.address,
                                  workPlaceName: pharmacy.name,
                                  workPlacePhone: pharmacy.phone,
                                  workPlaceRegion: pharmacy.dosigugun,
                                  workPlacePosition: { x: Number(position.x), y: Number(position.y) },
                                },
                                jobPostAlarm
                              );

                              onClose();
                            }
                          );
                        } else {
                          GeocodeUtil.getGPSByAddress(pharmacy.address).then((position: { x: string; y: string }) => {
                            setForm({
                              ...form,
                              workPlaceAddress: pharmacy.address,
                              workPlaceName: pharmacy.name,
                              workPlacePhone: pharmacy.phone,
                              workPlaceRegion: pharmacy.dosigugun,
                              workPlacePosition: { x: Number(position.x), y: Number(position.y) },
                            });
                            setMode("home");
                          });
                        }
                      }}
                    >
                      <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[900]}>
                        {pharmacy.name}
                      </Text>
                      <Text textType="InputMedium" color={COLOR_SYSTEM.get("Gray")[600]}>
                        {pharmacy.address}
                      </Text>
                      <Text textType="InputSmall" color={COLOR_SYSTEM.get("Gray")[400]}>
                        전화번호 {pharmacy.phone}
                      </Text>
                    </li>
                    {index < searchedPharmacy.length - 1 && <hr />}
                  </React.Fragment>
                ))}
              </PharmacyList>
            </Section>
          </>
        );
      case "SelfRegister":
        return (
          <>
            <Section className="setting__self-register">
              <Input
                label="약국(회사) 주소"
                value={form.workPlaceAddress}
                onClick={() => {
                  setMode("addressSearch");
                }}
                onChange={() => {}}
              />
              {form.workPlaceAddress && (
                <>
                  <Input
                    label="약국(회사)명"
                    value={form.workPlaceName}
                    name="workPlaceName"
                    placeholder="약국(회사)명을 입력해주세요"
                    onChange={(e) => setForm({ ...form, workPlaceName: e.target.value })}
                    onClear={() => setForm({ ...form, workPlaceName: "" })}
                  />
                  <Input
                    label="전화번호"
                    value={form.workPlacePhone}
                    name="workPlacePhone"
                    placeholder="전화번호를 입력해주세요"
                    onChange={(e) => setForm({ ...form, workPlacePhone: e.target.value })}
                    onClear={() => setForm({ ...form, workPlacePhone: "" })}
                  />
                </>
              )}
            </Section>
          </>
        );
      default:
        return null;
    }
  };

  const getTopAppBarTitle = () => {
    switch (mode) {
      case "home":
        return "구인구직 설정";
      case "pharmacySearch":
        return "약국(회사) 정보";
      case "addressSearch":
        return form.workType === UserWorkType.PHARMACY_OWNER ? "약국(회사) 직접 등록" : "집 주소 검색";
      case "SelfRegister":
        return "약국(회사) 직접 등록";
      default:
        return "";
    }
  };

  const handleMapClick = (e) => {
    const { x, y } = e.coord;
    marker.current.setPosition(e.coord);
    form.workType === UserWorkType.PHARMACY_OWNER
      ? setForm({ ...form, workPlacePosition: { x, y } })
      : setForm({ ...form, homePosition: { x, y } });
  };

  const clearJobPostAlarm = async () => {
    if (me.workType !== UserWorkType.PHARMACY_OWNER && !me.jobpostStatus) {
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "JOBPOST_PHARMACIST_SETTING_DONE", "구인구직_설정_취소");

      const newMe: UserInfo = {
        jobpostStatus: JobOfferType.NONE,
      };

      let result = await fetchAPI(API.USER_ME_UPDATE, "", null, newMe, getGlobal(GlobalKey.TOKEN));
      if (result && !result.error) {
        dispatch(actions.user.setMe(newMe));
      }
    }
  };

  const initMap = (position) => {
    log(LogLevel.UI_ACTION, "JobPostSetting.initMap", position);

    if (position && naver.maps) {
      let { y, x } = position;

      const latLng = new naver.maps.LatLng(y, x);

      if (map.current) {
        naver.maps.Event.clearListeners(map.current, "click");
        map.current.destroy();
      }

      const mapOptions = {
        center: latLng,
        zoom: 17,
      };
      map.current = new naver.maps.Map("map", mapOptions);

      marker.current = new naver.maps.Marker({
        position: map.current.getCenter(),
        map: map.current,
      });
      naver.maps.Event.addListener(map.current, "click", handleMapClick);
    }
  };

  const canChangeWorkType = useCallback(async () => {
    const elapsedTime = differenceInDays(new Date(), new Date(me.workTypeEditedAt));
    // const elapsedTime = 43;

    if (elapsedTime < 30) {
      const dday = 30 - elapsedTime;
      if (!(await showCanNotChangeWorkTypeConfirm(dday))) {
        ChannelTalkService.show();
      }

      return false;
    }

    return true;
  }, [me, form]);

  const canChange = useMemo(
    () => {
      const canChange =  (
        form.workType !== me.workType ||
        form.homeAddress !== me.homeAddress ||
        form.homePosition.x !== me.homePosition.x ||
        form.homePosition.y !== me.homePosition.y ||
        originJobPostAlarm !== jobPostAlarm ||
        (form.jobpostStatus !== me.jobpostStatus) ||
        (distances[selectedDistance] * 1000 !== me.jobpostDistance) ||
        form.workPlaceAddress !== me.workPlaceAddress ||
        form.workPlaceName !== me.workPlaceName ||
        form.workPlacePhone !== me.workPlacePhone ||
        form.workPlacePosition.x !== me.workPlacePosition.x ||
        form.workPlacePosition.y !== me.workPlacePosition.y
      )
      log(LogLevel.UI_ACTION, "JobPostSetting:useMemo", me, form, originJobPostAlarm, jobPostAlarm, canChange);

      return canChange;
    }
    ,
    [form, me, jobPostAlarm, selectedDistance]
  );

  const handleClickBack = useCallback(async () => {
    log(LogLevel.UI_ACTION, "JobPostSetting:handleClickBack", mode, canChange);
    switch (mode) {
      case "addressSearch":
        setMode(form.workType === UserWorkType.PHARMACY_OWNER ? "SelfRegister" : "home");
        return;
      case "pharmacySearch":
        initMode === "pharmacySearch" ? onClose() : setMode("home");
        return;
      case "home":
        if (form.workType === UserWorkType.PHARMACY_OWNER && !form.workPlaceName) {
          setEmptyWorkPlaceNameError(true);
          return;
        }

        canChange ? setBeforeLeavePopup(true) : onGoBack();
        return;
      case "SelfRegister":
        setMode("pharmacySearch");
        return;
      default:
        onGoBack();
    }
  }, [mode, form, canChange]);

  const handleSelectJobpostStatus = (jobpostStatus: JobOfferType) => () => {
    setForm({ ...form, jobpostStatus });
    setJobPostStatusSheet(false);
  };

  const updateUser = useCallback(async () => {
    const jobpostStatus = jobPostAlarm ? form.jobpostStatus : JobOfferType.NONE;
    const jobpostDistance = jobPostAlarm ? distances[selectedDistance] * 1000 : 0;

    await onUpdateOfferSetting({ ...form, jobpostStatus, jobpostDistance }, jobPostAlarm);
    onGoBack();
  }, [jobPostAlarm, form, selectedDistance]);

  const handleClickSave = useCallback(async () => {
    if (beforeLeavePopup) setBeforeLeavePopup(false);
    updateUser();
  }, [beforeLeavePopup]);

  const updateWorkTypeField = useCallback((workType: UserWorkType) => {
    setForm({ ...form, workType });
  }, []);

  const handleSelectWorkType = useCallback(
    (workType: UserWorkType) => async () => {
      setWorkTypeSheet(false);
      if (workType === form.workType || !(await canChangeWorkType())) return;

      if (workType !== me.workType) {
        const response = await showChangeWorkTypeWarningConfirm(workType);

        if (response) {
          updateWorkTypeField(workType);
        }
      } else {
        updateWorkTypeField(workType);
      }
    },
    [me, form]
  );

  useEffect(() => {
    log(LogLevel.UI_ACTION, "JobPostSetting.useEffect [mode, form.workType]", mode, form);

    if (
      mode === "home" &&
      ((form.workType === UserWorkType.PHARMACY_OWNER && form.workPlaceName) ||
        form.workType !== UserWorkType.PHARMACY_OWNER)
    )
      initMap(form.workType === UserWorkType.PHARMACY_OWNER ? form.workPlacePosition : form.homePosition);
  }, [mode, form.workType]);

  useEffect(() => {
    backKeyControl.pushListener(() => {
      handleClickBack();
      return true;
    }, "jobpost-setting");

    return () => {
      clearJobPostAlarm();
    };
  }, [form, mode, jobPostAlarm, selectedDistance]);

  useEffect(() => {
    onSearchPharmacy(pharmacy);
  }, [pharmacy]);

  useEffect(() => {
    onSearchAddress(form.workType === UserWorkType.PHARMACY_OWNER ? workPlaceAddress : homeAddress);
  }, [homeAddress, workPlaceAddress, form.workType]);

  useEffect(() => {
    if (
      mode === "home" &&
      ((form.workType === UserWorkType.PHARMACY_OWNER && form.workPlaceName) ||
        form.workType !== UserWorkType.PHARMACY_OWNER)
    )
      initMap(form.workType === UserWorkType.PHARMACY_OWNER ? form.workPlacePosition : form.homePosition);

      return () => {
        if (backKeyControl) {
          backKeyControl.popListener("jobpost-setting");
        }
      };
  }, []);

  return (
    <>
      <Header>
        <TopAppBar
          leftButtons={[
            <Button
              color="Secondary"
              icon="CaretLeft"
              onClick={handleClickBack}
              size="Medium"
              type="Icon"
              variant="Ghost"
            />,
          ]}
          title={getTopAppBarTitle()}
          titleType="H4"
        />
      </Header>

      <Main
        ref={mainEl}
        onClick={() => {
          (document.querySelector(".ion-page") as HTMLElement).blur();
        }}
      >
        {renderMain()}
      </Main>

      {mode === "SelfRegister" && (
        <Footer>
          <Button
            color="Primary"
            size="Large"
            type="Text"
            variant="Contained"
            disabled={!form.workPlaceAddress || !form.workPlaceName || !form.workPlacePhone}
            disabledColor={!form.workPlaceAddress || !form.workPlaceName || !form.workPlacePhone}
            onClick={async () => {
              if (mode === "SelfRegister") {
                if (initMode === "pharmacySearch") {
                  await onUpdateOfferSetting(form, jobPostAlarm);
                  onClose();
                } else {
                  setMode("home");
                }
              } else {
                const jobpostStatus = jobPostAlarm ? form.jobpostStatus : JobOfferType.NONE;
                const jobpostDistance = jobPostAlarm ? distances[selectedDistance] * 1000 : 0;

                await onUpdateOfferSetting({ ...form, jobpostStatus, jobpostDistance }, jobPostAlarm);
                onGoBack();
              }
            }}
          >
            등록
          </Button>
        </Footer>
      )}

      <BottomSheet
        active={workTypeSheet}
        height={"65%"}
        onClose={() => {
          setWorkTypeSheet(false);
        }}
      >
        <WorkTypeBottomSheet>
          <ul>
            <li onClick={handleSelectWorkType(UserWorkType.PHARMACY_OWNER)}>
              <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
                개국약사
              </Text>
              <Icon
                width={24}
                height={24}
                fill={
                  form.workType === UserWorkType.PHARMACY_OWNER
                    ? COLOR_SYSTEM.get("Skyblue")[400]
                    : COLOR_SYSTEM.get("Gray")[100]
                }
                name={"Check"}
              />
            </li>
            <li onClick={handleSelectWorkType(UserWorkType.PHARMACY_EMPLOYED)}>
              <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
                근무약사
              </Text>
              <Icon
                width={24}
                height={24}
                fill={
                  form.workType === UserWorkType.PHARMACY_EMPLOYED
                    ? COLOR_SYSTEM.get("Skyblue")[400]
                    : COLOR_SYSTEM.get("Gray")[100]
                }
                name={"Check"}
              />
            </li>
            <li onClick={handleSelectWorkType(UserWorkType.HOSPITAL)}>
              <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
                병원근무
              </Text>
              <Icon
                width={24}
                height={24}
                fill={
                  form.workType === UserWorkType.HOSPITAL
                    ? COLOR_SYSTEM.get("Skyblue")[400]
                    : COLOR_SYSTEM.get("Gray")[100]
                }
                name={"Check"}
              />
            </li>
            <li onClick={handleSelectWorkType(UserWorkType.PHARMACEUTICAL_COMPANY)}>
              <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
                제약회사
              </Text>
              <Icon
                width={24}
                height={24}
                fill={
                  form.workType === UserWorkType.PHARMACEUTICAL_COMPANY
                    ? COLOR_SYSTEM.get("Skyblue")[400]
                    : COLOR_SYSTEM.get("Gray")[100]
                }
                name={"Check"}
              />
            </li>
            <li onClick={handleSelectWorkType(UserWorkType.GOVERMENT)}>
              <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
                정부기관
              </Text>
              <Icon
                width={24}
                height={24}
                fill={
                  form.workType === UserWorkType.GOVERMENT
                    ? COLOR_SYSTEM.get("Skyblue")[400]
                    : COLOR_SYSTEM.get("Gray")[100]
                }
                name={"Check"}
              />
            </li>
            <li onClick={handleSelectWorkType(UserWorkType.DISTRIBUTOR)}>
              <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
                도매상
              </Text>
              <Icon
                width={24}
                height={24}
                fill={
                  form.workType === UserWorkType.DISTRIBUTOR
                    ? COLOR_SYSTEM.get("Skyblue")[400]
                    : COLOR_SYSTEM.get("Gray")[100]
                }
                name={"Check"}
              />
            </li>
            <li onClick={handleSelectWorkType(UserWorkType.LEAVE_OF_ABSENCE)}>
              <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
                휴직
              </Text>
              <Icon
                width={24}
                height={24}
                fill={
                  form.workType === UserWorkType.LEAVE_OF_ABSENCE
                    ? COLOR_SYSTEM.get("Skyblue")[400]
                    : COLOR_SYSTEM.get("Gray")[100]
                }
                name={"Check"}
              />
            </li>
            <li onClick={handleSelectWorkType(UserWorkType.UNDERGRADUATE)}>
              <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
                대학원생
              </Text>
              <Icon
                width={24}
                height={24}
                fill={
                  form.workType === UserWorkType.UNDERGRADUATE
                    ? COLOR_SYSTEM.get("Skyblue")[400]
                    : COLOR_SYSTEM.get("Gray")[100]
                }
                name={"Check"}
              />
            </li>
            <li onClick={handleSelectWorkType(UserWorkType.ETC)}>
              <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
                기타
              </Text>
              <Icon
                width={24}
                height={24}
                fill={
                  form.workType === UserWorkType.ETC ? COLOR_SYSTEM.get("Skyblue")[400] : COLOR_SYSTEM.get("Gray")[100]
                }
                name={"Check"}
              />
            </li>
          </ul>
        </WorkTypeBottomSheet>
      </BottomSheet>

      <WorkTypeSelectBottomSheet
        settingPage
        active={jobPostStatusSheet}
        selectedWorkType={form.jobpostStatus}
        onClose={() => {
          setJobPostStatusSheet(false);
        }}
        onSelect={handleSelectJobpostStatus}
      />

      {beforeLeavePopup && (
        <BeforeLeaveSettingPopup
          onClose={() => {
            setBeforeLeavePopup(false);
          }}
          onNotSave={() => {
            onGoBack();
            setBeforeLeavePopup(false);
          }}
          onSave={handleClickSave}
        />
      )}

      {emptyHomeAddressError && (
        <SettingEmptyErrorPopup
          description={
            <>
              구인 알림을 설정하기 위해
              <br />집 주소 정보가 필요해요
            </>
          }
          leftButtonChildren="취소"
          rightButtonChildren="입력하러 가기"
          onClose={() => {
            setEmptyHomeAddressError(false);
          }}
          onClickLeft={() => {
            setEmptyHomeAddressError(false);
          }}
          onClickRight={() => {
            mainEl.current.scrollTo({ top: homeAddressInputEl.current.offsetTop - 60, behavior: "smooth" });
            setEmptyHomeAddressError(false);
          }}
        />
      )}

      {emptyWorkPlaceNameError && (
        <SettingEmptyErrorPopup
          icon={<Icon width={50} height={50} name={"Exclamation Mark Filled"} fill={COLOR_SYSTEM.get("Red")[300]} />}
          description={
            <>
              근무유형을{" "}
              <Text textType="H4" color={COLOR_SYSTEM.get("Skyblue")[400]}>
                개국약사
              </Text>
              로 변경하시려면
              <br />
              약국 정보를 입력해주세요
            </>
          }
          leftButtonChildren="변경 안함"
          rightButtonChildren="정보 입력하기"
          onClose={() => {
            setEmptyWorkPlaceNameError(false);
          }}
          onClickLeft={() => {
            onGoBack();
            setEmptyWorkPlaceNameError(false);
          }}
          onClickRight={() => {
            setEmptyWorkPlaceNameError(false);
          }}
        />
      )}
    </>
  );
};

export default JobPostSetting;
